import { Card as AntCard, Badge, Tag, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import ILoan from '~Api/Loan/ILoan';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import { IDictionary } from '~utilities/IDictionary';
import AssigneeSelector from './AssigneeSelector';
import { PhoneOutlined } from '@ant-design/icons';
import { BoardColumnEnum } from '~Loans/Dashboard';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

interface ITag {
    colour: string;
    label: string;
}

const tagDetails: IDictionary<ITag> = {
    [WorkflowStatusEnum.Discharge]: {
        colour: 'green',
        label: 'Discharge',
    },
    [WorkflowStatusEnum.Extension]: {
        colour: 'blue',
        label: 'Extension',
    },
    [WorkflowStatusEnum.GracePeriod]: {
        colour: 'orange',
        label: 'Grace Period',
    },
    [WorkflowStatusEnum.Recovery]: {
        colour: 'red',
        label: 'Recovery',
    },
};

export default function Card(props: {
    loan: ILoan
}): ReactElement {
    const { loan } = props;

    const todayDayjs: Dayjs = dayjs();
    const endDateDayjs: Dayjs = dayjs(loan.endDate);

    const today: string = todayDayjs.format('YYYY-MM-DD');
    const daysUntilOrSinceExpiry: number = Math.abs(dayjs(today).diff(endDateDayjs, 'days'));

    const monthDisplay: string = loan.termMonths === 1 ? 'month' : 'months';

    let timeInStatusClass: string = 'default';
    if (today > loan.endDate) {
        timeInStatusClass = 'danger';
    } else if (loan.endDate <= todayDayjs.add(7, 'days').format('YYYY-MM-DD') && loan.endDate > today) {
        timeInStatusClass = 'warning';
    }

    const workflowStatusTagBlock: JSX.Element = [WorkflowStatusEnum.Discharge, WorkflowStatusEnum.Extension, WorkflowStatusEnum.GracePeriod, WorkflowStatusEnum.Recovery].includes(loan.workflowStatus) && (
        <Tag color={tagDetails[loan.workflowStatus].colour}>{tagDetails[loan.workflowStatus].label}</Tag>
    );

    return (
        <AntCard key={loan.uuid}>
            <Badge className={`time-in-status ${timeInStatusClass}`} count={daysUntilOrSinceExpiry} showZero={true} />
            <div className='code'>{loan.code || loan.salesforceCode}</div>
            <Link className='name' to={`/loans/${loan.uuid}`}>
                {loan.name || '-'}
            </Link>
            <div className='amount'>{currencyFormatter.format(loan.amount)}</div>
            <div className='interest-rate'>{percentageFormatter.format(loan.interestRate / 100)}</div>
            <Tooltip className='assignee-selector' title='Loan Manager' placement='left'>
                <PhoneOutlined />
                <AssigneeSelector loan={loan} />
            </Tooltip>
            <div className='term'>
                {loan.termMonths} {monthDisplay}
            </div>
            {workflowStatusTagBlock}
        </AntCard>
    );
}
