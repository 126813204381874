import { Descriptions, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import IProperty from '~Api/Deal/IProperty';
import LoanPurposeEnum from '~Api/Deal/LoanPurposeEnum';
import sourceLabels from '~Leads/sourceLabels';
import PostcodeCategory from '~Deals/PostcodeCategory';
import { leadGetAction } from '~Leads/actions';
import { leadSelector } from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging Loan',
    [LoanPurposeEnum.BusinessLoan]: 'Business Loan',
    [LoanPurposeEnum.DebtConsolidation]: 'Debt Consolidation',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development Loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal Loan',
    [LoanPurposeEnum.PurchaseNew]: 'Purchase',
    [LoanPurposeEnum.Refinance]: 'Refinance',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or Build',
};

interface IMatch {
    dealUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    deal: IDeal;
}

interface IPropsDispatch {
    leadGet: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Overview extends React.Component<Props> {
    public componentDidMount(): void {
        const { deal } = this.props;

        if (!deal) {
            this.props.leadGet();
        }
    }

    public render(): JSX.Element {
        const { deal, match } = this.props;

        if (!deal) {
            return (
                <Layout dealUuid={match.params.dealUuid} section='overview'>
                    <Spin/>
                </Layout>
            );
        }

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const lvrFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            style: 'percent',
        });

        const propertiesBlock: React.ReactFragment[] = deal.properties.map((property: IProperty) => {
            return (
                <Typography.Text key={property.uuid}>
                    <Space>
                        {property.formattedAddress}
                        <PostcodeCategory property={property} />
                    </Space>
                </Typography.Text>
            );
        });

        const totalValue: number = _.sumBy(deal.properties, (leadProperty: IProperty) => leadProperty.estimatedValue);
        const totalCurrentDebt: number = _.sumBy(deal.properties, (leadProperty: IProperty) => leadProperty.currentDebt);

        return (
            <Layout dealUuid={match.params.dealUuid} section='overview'>
                <Typography.Title level={2}>Overview</Typography.Title>
                <Descriptions bordered={true} layout='vertical'>
                    <Descriptions.Item label='Created'>{dayjs(deal.createdTime).format('Do MMMM YYYY HH:mm:ss')}</Descriptions.Item>
                    <Descriptions.Item label='Estimated Value'>{currencyFormatter.format(totalValue)}</Descriptions.Item>
                    <Descriptions.Item label='Current Debt'>{totalCurrentDebt > 0 ? currencyFormatter.format(totalCurrentDebt) : '-'}</Descriptions.Item>
                    <Descriptions.Item label='LVR'>{lvrFormatter.format((deal.loanAmount + totalCurrentDebt) / totalValue)}</Descriptions.Item>
                    <Descriptions.Item label='Loan Term'>{deal.termMonths} months</Descriptions.Item>
                    <Descriptions.Item label='Loan Purpose'>{loanPurposeLabels[deal.loanPurpose]}</Descriptions.Item>
                    <Descriptions.Item label='Properties' span={3}>
                        {deal.properties.length === 0 && 'None'}
                        {deal.properties.length > 0 && <Space direction='vertical'>{propertiesBlock}</Space>}
                    </Descriptions.Item>
                    <Descriptions.Item label='Is Broker'>{deal.isBroker ? 'Yes' : 'No'}</Descriptions.Item>
                    <Descriptions.Item label='First Name'>{deal.firstName || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Last Name'>{deal.lastName || '-'}</Descriptions.Item>
                    <Descriptions.Item className='comments' label='Comments' span={3}>{deal.comments || '-'}</Descriptions.Item>
                    <Descriptions.Item label='Source'>{deal.source ? sourceLabels[deal.source] : '-'}</Descriptions.Item>
                    <Descriptions.Item className='landing-page' label='Landing Page' span={2}>{deal.landingPage || '-'}</Descriptions.Item>
                </Descriptions>
            </Layout>
        );
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        deal: leadSelector(state, ownProps.match.params.dealUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        leadGet: () => dispatch(leadGetAction(ownProps.match.params.dealUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Overview);
