enum PermissionsEnum {
    Accounts = 'ACCOUNTS',
    Administrators = 'ADMINISTRATORS',
    Advisers = 'ADVISERS',
    Applications = 'APPLICATIONS',
    Brokers = 'BROKERS',
    Investments = 'INVESTMENTS',
    Investors = 'INVESTORS',
    Leads = 'LEADS',
    Loans = 'LOANS',
    ReferralPartners = 'REFERRAL_PARTNERS',
    Reports = 'REPORTS',
    Users = 'USERS',
    Warehouses = 'WAREHOUSES',
    WithdrawalAbas = 'WITHDRAWAL_ABAS',

    AdministratorMagicLink = 'ADMINISTRATOR/MAGIC_LINK',

    ApplicationSettle = 'APPLICATION/SETTLE',

    ApplicationConditionDocumentApprove = 'APPLICATION/CONDITION_DOCUMENT/APPROVE',

    ApplicationConditionalApprove = 'APPLICATION/CONDITIONAL/APPROVE',

    ApplicationFormalApprovalAdd = 'APPLICATION/FORMAL_APPROVAL/ADD',

    ApplicationWarehouses = 'APPLICATION/WAREHOUSES',

    ApplicationsSettlementReport = 'APPLICATIONS/SETTLEMENT_REPORT',

    BrokerAssignBdm = 'BROKER/ASSIGN_BDM',
    /** @deprecated */
    BrokerReport = 'BROKER/REPORT',

    BrokersFilterAll = 'BROKERS/FILTER_ALL',

    DealLimitsOverride = 'DEAL/LIMITS_OVERRIDE',
    DealQuoteApproval = 'DEAL/QUOTE/APPROVAL',

    IncomeTrustProcessInterest = 'INCOME_TRUST/PROCESS_INTEREST',

    InvestmentPendingDistributions = 'INVESTMENT/PENDING_DISTRIBUTIONS',

    InvestorAssignAdviser = 'INVESTOR/ASSIGN_ADVISER',
    InvestorAssignIm = 'INVESTOR/ASSIGN_IM',
    InvestorBankAccountConfirm = 'INVESTOR/BANK_ACCOUNT/CONFIRM',
    InvestorIncomeTrustInvestmentRequests = 'INVESTOR/INCOME_TRUST/INVESTMENT_REQUESTS',
    InvestorPendingAccounts = 'INVESTOR/PENDING_ACCOUNTS',
    InvestorPendingDeposits = 'INVESTOR/PENDING_DEPOSITS',
    InvestorPendingWithdrawals = 'INVESTOR/PENDING_WITHDRAWALS',
    InvestorReferrals = 'INVESTOR/REFERRALS',

    LeadsMovementReport = 'LEADS/MOVEMENT_REPORT',
    LeadsOriginationReport = 'LEADS/ORIGINATION_REPORT',
    LeadsReject = 'LEADS/REJECT',

    LoanAssign = 'LOAN/ASSIGN',
    LoanExtend = 'LOAN/EXTEND',
    LoanGracePeriodsAdd = 'LOAN/GRACE_PERIODS/ADD',
    /** @deprecated */
    LoanPoolCutReport = 'LOAN/POOL_CUT_REPORT',
    LoanPayoutFigureApprove = 'LOAN/PAYOUT_FIGURE/APPROVE',
    LoanPayoutFigureForceProcess = 'LOAN/PAYOUT_FIGURE/FORCE_PROCESS',
    LoanPayoutFigureProcess = 'LOAN/PAYOUT_FIGURE/PROCESS',

    ReportsApplicationsTimeInStatus = 'REPORTS/APPLICATIONS_TIME_IN_STATUS',
    ReportsBrokersExport = 'REPORTS/BROKERS_EXPORT',
    ReportsInvestmentsExport = 'REPORTS/INVESTMENTS_EXPORT',
    ReportsInvestorsFinRegister = 'REPORTS/INVESTORS_FIN_REGISTER',
    ReportsInvestorsFitCashBalances = 'REPORTS/INVESTORS_FIT_CASH_BALANCES',
    ReportsInvestorsFitCashTransactions = 'REPORTS/INVESTORS_FIT_CASH_TRANSACTIONS',
    ReportsInvestorsFitInvestmentBalances = 'REPORTS/INVESTORS_FIT_INVESTMENT_BALANCES',
    ReportsInvestorsTfn = 'REPORTS/INVESTORS_TFN',
    ReportsLeadsExport = 'REPORTS/LEADS_EXPORT',
    ReportsLeadsTimeInStatus = 'REPORTS/LEADS_TIME_IN_STATUS',
    ReportsLoansExport = 'REPORTS/LOANS_EXPORT',
    ReportsLoansBorrowers = 'REPORTS/LOANS_BORROWERS',
    ReportsLoansPayments = 'REPORTS/LOANS_PAYMENTS',
    ReportsLoansPoolCut = 'REPORTS/LOANS_POOL_CUT',
    ReportsLoansProperties = 'REPORTS/LOANS_PROPERTIES',
    ReportsWarehouseFwt2PoolCut = 'REPORTS/WAREHOUSE_FWT2_POOL_CUT',
    ReportsWarehouseLoanBalances = 'REPORTS/WAREHOUSE_LOAN_BALANCES',

    UserBlock = 'USER/BLOCK',

    WarehouseLoanSell = 'WAREHOUSE/LOAN/SELL',
    WarehouseActiveReport = 'WAREHOUSE/ACTIVE_REPORT',
    WarehousesPortfolioDashboard = 'WAREHOUSES/PORTFOLIO_DASHBOARD',
}

export default PermissionsEnum;
