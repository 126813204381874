import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Tabs, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IBorrower from '~Api/Application/IBorrower';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import ICondition from '~Api/Application/ICondition';
import IConditionDocument from '~Api/Application/IConditionDocument';
import IRfiItem from '~Api/Application/IRfiItem';
import {
    applicationBorrowersListAction,
    applicationConditionDocumentsListAction,
    applicationConditionsListAction,
    applicationGetAction,
    applicationRfiItemsListAction,
} from '~Applications/actions';
import {
    applicationBorrowersSelector,
    applicationConditionDocumentsSelector,
    applicationConditionsSelector,
    applicationPropertiesSelector,
    applicationRfiItemsSelector,
    applicationSelector,
} from '~Applications/selectors';
import { authTokenSelector } from '~Auth/selectors';
import ConditionDocumentsList from '~Applications/Application/ConditionDocumentsList';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import {
    approvedApplicationConditionDocumentExists,
    approvedBorrowerConditionDocumentExists,
    approvedCustomConditionDocumentExists,
    approvedPropertyConditionDocumentExists,
    getApplicationConditions,
    getBorrowerConditions,
    getPropertyConditions,
    getRequiredConditionTypes,
} from '~Applications/utilities';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import { aggregatorsSelector } from '~Aggregators/selectors';
import { aggregatorsListAction } from '~Aggregators/actions';
import IAggregator from '~Api/Aggregator/IAggregator';

const nextWorkflowStatuses: IDictionary<WorkflowStatusEnum> = {
    [WorkflowStatusEnum.Draft]: WorkflowStatusEnum.New,
    [WorkflowStatusEnum.New]: WorkflowStatusEnum.ConditionalApproval,
    [WorkflowStatusEnum.ConditionalApproval]: WorkflowStatusEnum.Underwriting,
    [WorkflowStatusEnum.Underwriting]: WorkflowStatusEnum.FormalApproval,
    [WorkflowStatusEnum.FormalApproval]: WorkflowStatusEnum.LegalDocuments,
    [WorkflowStatusEnum.LegalDocuments]: WorkflowStatusEnum.Settlement,
    [WorkflowStatusEnum.Settlement]: WorkflowStatusEnum.Warehoused,
};

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    aggregators: IDictionary<IAggregator>;
    application: IApplication;
    applicationProperties: IDictionary<IApplicationProperty>;
    borrowers: IBorrower[];
    conditionDocuments: IDictionary<IConditionDocument>;
    conditions: IDictionary<ICondition>;
    rfiItems: IDictionary<IRfiItem>;
    token: string;
}

interface IPropsDispatch {
    aggregatorsList: () => void;
    applicationGet: () => void;
    borrowersList: () => void;
    conditionDocumentsList: () => void;
    conditionsList: () => void;
    rfiItemsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Underwriting extends React.Component<Props> {
    private refreshInterval: NodeJS.Timeout;

    public componentDidMount(): void {
        const { application, borrowers, conditionDocuments, conditions, rfiItems } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!borrowers) {
            this.props.borrowersList();
        }

        if (!conditionDocuments) {
            this.props.conditionDocumentsList();
        }

        if (!conditions) {
            this.props.conditionsList();
        }

        if (!rfiItems) {
            this.props.rfiItemsList();
        }

        this.refreshInterval = setInterval(() => {
            this.props.conditionDocumentsList();
            this.props.conditionsList();
            this.props.rfiItemsList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { application, applicationProperties, borrowers, conditionDocuments, conditions, match, rfiItems } = this.props;

        if (!application || !applicationProperties || !borrowers || !conditionDocuments || !conditions || !rfiItems) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='underwriting'>
                    <Typography.Title level={2}>Underwriting Checklist</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        const borrowerTabsBlock: JSX.Element[] = borrowers.map((borrower: IBorrower) => (
            <Tabs.TabPane
                tab={<Typography>{this.borrowerConditionDocumentsComplete(borrower) ? <CheckOutlined/> : <WarningOutlined/>} {borrower.dealBorrower.formattedName}</Typography>}
                key={`borrower-${borrower.uuid}`}
            >
                <ConditionDocumentsList
                    key={`condition-document-borrower-${borrower.uuid}`}
                    application={application}
                    applicationBorrower={borrower}
                />
            </Tabs.TabPane>),
        );

        const propertyTabsBlock: JSX.Element[] = _.map(applicationProperties, (applicationProperty: IApplicationProperty) => (
            <Tabs.TabPane
                tab={<Typography>{this.propertyConditionDocumentsComplete(applicationProperty) ? <CheckOutlined/> : <WarningOutlined/>} {applicationProperty.dealProperty.streetAddress}</Typography>}
                key={`property-${applicationProperty.uuid}`}
            >
                <ConditionDocumentsList
                    key={`condition-document-property${applicationProperty.uuid}`}
                    application={application}
                    applicationProperty={applicationProperty}
                />
            </Tabs.TabPane>),
        );
        const solictorDownloadsButton: JSX.Element = !application.isExtension && (
            <Link to={`/applications/${application.uuid}/solicitor-downloads`}>
                <Button>Download Documents</Button>
            </Link>
        );

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='underwriting'>
                <Space className='actions'>
                    {solictorDownloadsButton}
                </Space>
                <Typography.Title level={2}>Underwriting Checklist</Typography.Title>
                <Tabs defaultActiveKey={application.uuid} type='card'>
                    <Tabs.TabPane
                        tab={<Typography>{this.applicationConditionDocumentsComplete() ? <CheckOutlined/> : <WarningOutlined/>} Application</Typography>}
                        key={`application-${application.uuid}`}
                    >
                        <ConditionDocumentsList
                            key={`condition-document-application-${application.uuid}`}
                            application={application}
                        />
                    </Tabs.TabPane>
                    {borrowerTabsBlock}
                    {propertyTabsBlock}
                </Tabs>
            </Layout>
        );
    }

    private applicationConditionDocumentsComplete(): boolean {
        const { aggregators, application, conditionDocuments, conditions } = this.props;

        const requiredConditionTypes: ConditionTypeEnum[] = getRequiredConditionTypes(application, nextWorkflowStatuses[application.workflowStatus], aggregators);
        const applicationConditionTypes: ConditionTypeEnum[] = getApplicationConditions(application);

        if (_.some(applicationConditionTypes, (conditionType: ConditionTypeEnum) => requiredConditionTypes.includes(conditionType) && !approvedApplicationConditionDocumentExists(_.values(conditionDocuments), application, conditionType))) {
            return false;
        }

        if (_.some(conditions, (condition: ICondition) => !approvedCustomConditionDocumentExists(_.values(conditionDocuments), condition))) {
            return false;
        }

        return true;
    }

    private borrowerConditionDocumentsComplete(applicationBorrower: IBorrower): boolean {
        const { aggregators, application, conditionDocuments } = this.props;

        const requiredConditionTypes: ConditionTypeEnum[] = getRequiredConditionTypes(application, nextWorkflowStatuses[application.workflowStatus], aggregators);
        const borrowerConditionTypes: ConditionTypeEnum[] = getBorrowerConditions(application, applicationBorrower);

        if (_.some(borrowerConditionTypes, (conditionType: ConditionTypeEnum) => requiredConditionTypes.includes(conditionType) && !approvedBorrowerConditionDocumentExists(_.values(conditionDocuments), applicationBorrower, conditionType))) {
            return false;
        }

        return true;
    }

    private propertyConditionDocumentsComplete(applicationProperty: IApplicationProperty): boolean {
        const { aggregators, application, conditionDocuments } = this.props;

        const requiredConditionTypes: ConditionTypeEnum[] = getRequiredConditionTypes(application, nextWorkflowStatuses[application.workflowStatus], aggregators);
        const propertyConditionTypes: ConditionTypeEnum[] = getPropertyConditions(application, applicationProperty);

        if (_.some(propertyConditionTypes, (conditionType: ConditionTypeEnum) => requiredConditionTypes.includes(conditionType) && !approvedPropertyConditionDocumentExists(_.values(conditionDocuments), applicationProperty, conditionType))) {
            return false;
        }

        return true;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        aggregators: aggregatorsSelector(state),
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        applicationProperties: applicationPropertiesSelector(state, ownProps.match.params.applicationUuid),
        borrowers: applicationBorrowersSelector(state, ownProps.match.params.applicationUuid),
        conditionDocuments: applicationConditionDocumentsSelector(state, ownProps.match.params.applicationUuid),
        conditions: applicationConditionsSelector(state, ownProps.match.params.applicationUuid),
        rfiItems: applicationRfiItemsSelector(state, ownProps.match.params.applicationUuid),
        token: authTokenSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        aggregatorsList: () => dispatch(aggregatorsListAction()),
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        borrowersList: () => dispatch(applicationBorrowersListAction(ownProps.match.params.applicationUuid)),
        conditionDocumentsList: () => dispatch(applicationConditionDocumentsListAction(ownProps.match.params.applicationUuid)),
        conditionsList: () => dispatch(applicationConditionsListAction(ownProps.match.params.applicationUuid)),
        rfiItemsList: () => dispatch(applicationRfiItemsListAction(ownProps.match.params.applicationUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Underwriting);
