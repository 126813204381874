import { Form, Modal, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { loanWarehouseLoansListAction } from '~Loans/actions';
import { loanWarehouseLoansSelector } from '~Loans/selectors';
import {
    warehouseProposedPurchasesAddAction,
    warehousesListAction,
} from '~Warehouses/actions';
import { warehousesSelector } from '~Warehouses/selectors';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import { currencyFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import { IGlobalState } from '~reducer';

export default function ProposePurchaseModal(props: {
    isOpen: boolean;
    onCancel: () => void;
    loan: ILoan;
    warehouseUuid: string;
}): ReactElement {
    const {
        isOpen,
        onCancel,
        loan,
        warehouseUuid,
    } = props;

    const [ transactionDateTime, setTransactionDateTime ] = useState<string>(null);

    const warehouses: IDictionary<IWarehouse> = useSelector(warehousesSelector);
    const warehouseLoans: IDictionary<IWarehouseLoan> = useSelector((state: IGlobalState) => loanWarehouseLoansSelector(state, loan.uuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!warehouses) {
            dispatch(warehousesListAction());
        }
    }, [
        dispatch,
        warehouses,
    ]);

    useEffect(() => {
        if (!warehouseLoans) {
            dispatch(loanWarehouseLoansListAction(loan.uuid));
        }
    }, [
        dispatch,
        loan.uuid,
        warehouseLoans,
    ]);

    const disabledTime: (time: Dayjs) => boolean = useCallback((time: Dayjs) => {
        return time && time.isBefore(dayjs(), 'minute');
    }, []);

    const onChangeDate: (date: Dayjs) => void = useCallback((date: Dayjs) => {
        setTransactionDateTime(dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(transactionDateTime || undefined).format('HH:mm')}`).format());
    }, [transactionDateTime]);

    const onChangeTime: (time: Dayjs) => void = useCallback((time: Dayjs) => {
        setTransactionDateTime(dayjs(`${dayjs(transactionDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format());
    }, [transactionDateTime]);

    const transactionDateTimeDayjs: Dayjs = dayjs(transactionDateTime || undefined);

    const onClickOk: () => void = useCallback(() => {
        dispatch(warehouseProposedPurchasesAddAction(
            warehouseUuid,
            loan.uuid,
            dayjs(transactionDateTimeDayjs).format('YYYY-MM-DDTHH:mm:ssZ')
        ));

        onCancel();
    }, [
        dispatch,
        loan,
        onCancel,
        transactionDateTimeDayjs,
        warehouseUuid,
    ]);

    const warehouseLoanAmounts: JSX.Element[] = _.map(
        _.filter(warehouseLoans, (warehouseLoan: IWarehouseLoan) => warehouseLoan.balancePrincipal !== 0),
        (warehouseLoan: IWarehouseLoan) => {
            return (
                <Form.Item
                    label={warehouses[warehouseLoan.warehouseUuid].name}
                >
                    {currencyFormatter.format(warehouseLoan.balancePrincipal * -1)} 
                </Form.Item>
            );
        }
    );

    return (
        <Modal
            okText='Propose Purchase'
            onCancel={onCancel}
            onOk={onClickOk}
            open={isOpen}
            title={`Propose Purchase for ${loan.code}`}
            wrapClassName='warehouse-propose-purchase-modal'
            okButtonProps={{ disabled: !warehouseLoans }}
        >
            <Form.Item
                label='Transaction Date'
                className='transaction-date'
            >
                <Space>
                    <DatePicker
                        disabledDate={disabledTime}
                        className='transaction-pick-date'
                        onChange={onChangeDate}
                        value={transactionDateTimeDayjs || dayjs()}
                        format='DD/MM/YYYY'
                    />
                    <TimePicker
                        disabledDate={disabledTime}
                        className='transaction-pick-time'
                        onChange={onChangeTime}
                        value={transactionDateTimeDayjs || dayjs()}
                        format='H:mm'
                    />
                </Space>
            </Form.Item>
            {warehouseLoans && warehouseLoanAmounts}
            {!warehouseLoans && <div className='loading-spinner'><Spin/></div>}
            <Form.Item label="Total Loan Amount:">
                {currencyFormatter.format(loan.amountRemaining)}
            </Form.Item>
        </Modal>
    );
}
