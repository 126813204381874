enum ZoneTypeEnum {
    CommercialIndustrial = 'COMMERCIAL_INDUSTRIAL',
    CommercialLand = 'COMMERCIAL_LAND',
    CommercialOffice = 'COMMERCIAL_OFFICE',
    CommercialRetail = 'COMMERCIAL_RETAIL',
    ResidentialHouse = 'RESIDENTIAL_HOUSE',
    ResidentialLand = 'RESIDENTIAL_LAND',
    ResidentialTownhouse = 'RESIDENTIAL_TOWNHOUSE',
    ResidentialUnit = 'RESIDENTIAL_UNIT',
    RuralLand = 'RURAL_LAND',
    RuralResidential = 'RURAL_RESIDENTIAL',
}

export default ZoneTypeEnum;
