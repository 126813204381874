import PropertyStreetTypeEnum from '~Api/Deal/PropertyStreetTypeEnum';
import { IDictionary } from '~utilities/IDictionary';

const propertyStreetTypeLabels: IDictionary<string> = {
    [PropertyStreetTypeEnum.Access]: 'Access',
    [PropertyStreetTypeEnum.Alley]: 'Alley',
    [PropertyStreetTypeEnum.Alleyway]: 'Alleyway',
    [PropertyStreetTypeEnum.Amble]: 'Amble',
    [PropertyStreetTypeEnum.Anchorage]: 'Anchorage',
    [PropertyStreetTypeEnum.Approach]: 'Approach',
    [PropertyStreetTypeEnum.Arcade]: 'Arcade',
    [PropertyStreetTypeEnum.Artery]: 'Artery',
    [PropertyStreetTypeEnum.Avenue]: 'Avenue',
    [PropertyStreetTypeEnum.Basin]: 'Basin',
    [PropertyStreetTypeEnum.Beach]: 'Beach',
    [PropertyStreetTypeEnum.Bend]: 'Bend',
    [PropertyStreetTypeEnum.Block]: 'Block',
    [PropertyStreetTypeEnum.Boulevard]: 'Boulevard',
    [PropertyStreetTypeEnum.Brace]: 'Brace',
    [PropertyStreetTypeEnum.Brae]: 'Brae',
    [PropertyStreetTypeEnum.Break]: 'Break',
    [PropertyStreetTypeEnum.Bridge]: 'Bridge',
    [PropertyStreetTypeEnum.Broadway]: 'Broadway',
    [PropertyStreetTypeEnum.Brow]: 'Brow',
    [PropertyStreetTypeEnum.Bypass]: 'Bypass',
    [PropertyStreetTypeEnum.Byway]: 'Byway',
    [PropertyStreetTypeEnum.Causeway]: 'Causeway',
    [PropertyStreetTypeEnum.Centre]: 'Centre',
    [PropertyStreetTypeEnum.Centreway]: 'Centreway',
    [PropertyStreetTypeEnum.Chase]: 'Chase',
    [PropertyStreetTypeEnum.Circle]: 'Circle',
    [PropertyStreetTypeEnum.Circlet]: 'Circlet',
    [PropertyStreetTypeEnum.Circuit]: 'Circuit',
    [PropertyStreetTypeEnum.Circus]: 'Circus',
    [PropertyStreetTypeEnum.Close]: 'Close',
    [PropertyStreetTypeEnum.Colonnade]: 'Colonnade',
    [PropertyStreetTypeEnum.Common]: 'Common',
    [PropertyStreetTypeEnum.Concourse]: 'Concourse',
    [PropertyStreetTypeEnum.Copse]: 'Copse',
    [PropertyStreetTypeEnum.Corner]: 'Corner',
    [PropertyStreetTypeEnum.Corso]: 'Corso',
    [PropertyStreetTypeEnum.Court]: 'Court',
    [PropertyStreetTypeEnum.Courtyard]: 'Courtyard',
    [PropertyStreetTypeEnum.Cove]: 'Cove',
    [PropertyStreetTypeEnum.Crescent]: 'Crescent',
    [PropertyStreetTypeEnum.Crest]: 'Crest',
    [PropertyStreetTypeEnum.Cross]: 'Cross',
    [PropertyStreetTypeEnum.Crossing]: 'Crossing',
    [PropertyStreetTypeEnum.Crossroad]: 'Crossroad',
    [PropertyStreetTypeEnum.Crossway]: 'Crossway',
    [PropertyStreetTypeEnum.Cruiseway]: 'Cruiseway',
    [PropertyStreetTypeEnum.Culdesac]: 'Cul-de-sac',
    [PropertyStreetTypeEnum.Cutting]: 'Cutting',
    [PropertyStreetTypeEnum.Dale]: 'Dale',
    [PropertyStreetTypeEnum.Dell]: 'Dell',
    [PropertyStreetTypeEnum.Deviation]: 'Deviation',
    [PropertyStreetTypeEnum.Dip]: 'Dip',
    [PropertyStreetTypeEnum.Distributor]: 'Distributor',
    [PropertyStreetTypeEnum.Drive]: 'Drive',
    [PropertyStreetTypeEnum.Driveway]: 'Driveway',
    [PropertyStreetTypeEnum.Edge]: 'Edge',
    [PropertyStreetTypeEnum.Elbow]: 'Elbow',
    [PropertyStreetTypeEnum.End]: 'End',
    [PropertyStreetTypeEnum.Entrance]: 'Entrance',
    [PropertyStreetTypeEnum.Esplanade]: 'Esplanade',
    [PropertyStreetTypeEnum.Estate]: 'Estate',
    [PropertyStreetTypeEnum.Expressway]: 'Expressway',
    [PropertyStreetTypeEnum.Extension]: 'Extension',
    [PropertyStreetTypeEnum.Fairway]: 'Fairway',
    [PropertyStreetTypeEnum.FireTrack]: 'Fire Track',
    [PropertyStreetTypeEnum.FireTrail]: 'Fire Trail',
    [PropertyStreetTypeEnum.Flat]: 'Flat',
    [PropertyStreetTypeEnum.Follow]: 'Follow',
    [PropertyStreetTypeEnum.Footway]: 'Footway',
    [PropertyStreetTypeEnum.Foreshore]: 'Foreshore',
    [PropertyStreetTypeEnum.Formation]: 'Formation',
    [PropertyStreetTypeEnum.Freeway]: 'Freeway',
    [PropertyStreetTypeEnum.Front]: 'Front',
    [PropertyStreetTypeEnum.Frontage]: 'Frontage',
    [PropertyStreetTypeEnum.Gap]: 'Gap',
    [PropertyStreetTypeEnum.Garden]: 'Garden',
    [PropertyStreetTypeEnum.Gardens]: 'Gardens',
    [PropertyStreetTypeEnum.Gate]: 'Gate',
    [PropertyStreetTypeEnum.Gates]: 'Gates',
    [PropertyStreetTypeEnum.Glade]: 'Glade',
    [PropertyStreetTypeEnum.Glen]: 'Glen',
    [PropertyStreetTypeEnum.Grange]: 'Grange',
    [PropertyStreetTypeEnum.Green]: 'Green',
    [PropertyStreetTypeEnum.Ground]: 'Ground',
    [PropertyStreetTypeEnum.Grove]: 'Grove',
    [PropertyStreetTypeEnum.Gully]: 'Gully',
    [PropertyStreetTypeEnum.Heights]: 'Heights',
    [PropertyStreetTypeEnum.Highroad]: 'Highroad',
    [PropertyStreetTypeEnum.Highway]: 'Highway',
    [PropertyStreetTypeEnum.Hill]: 'Hill',
    [PropertyStreetTypeEnum.Interchange]: 'Interchange',
    [PropertyStreetTypeEnum.Intersection]: 'Intersection',
    [PropertyStreetTypeEnum.Junction]: 'Junction',
    [PropertyStreetTypeEnum.Key]: 'Key',
    [PropertyStreetTypeEnum.Landing]: 'Landing',
    [PropertyStreetTypeEnum.Lane]: 'Lane',
    [PropertyStreetTypeEnum.Laneway]: 'Laneway',
    [PropertyStreetTypeEnum.Lees]: 'Lees',
    [PropertyStreetTypeEnum.Line]: 'Line',
    [PropertyStreetTypeEnum.Link]: 'Link',
    [PropertyStreetTypeEnum.Little]: 'Little',
    [PropertyStreetTypeEnum.Lookout]: 'Lookout',
    [PropertyStreetTypeEnum.Loop]: 'Loop',
    [PropertyStreetTypeEnum.Lower]: 'Lower',
    [PropertyStreetTypeEnum.Mall]: 'Mall',
    [PropertyStreetTypeEnum.Meander]: 'Meander',
    [PropertyStreetTypeEnum.Mew]: 'Mew',
    [PropertyStreetTypeEnum.Mews]: 'Mews',
    [PropertyStreetTypeEnum.Motorway]: 'Motorway',
    [PropertyStreetTypeEnum.Mount]: 'Mount',
    [PropertyStreetTypeEnum.Nook]: 'Nook',
    [PropertyStreetTypeEnum.Outlook]: 'Outlook',
    [PropertyStreetTypeEnum.Parade]: 'Parade',
    [PropertyStreetTypeEnum.Park]: 'Park',
    [PropertyStreetTypeEnum.Parklands]: 'Parklands',
    [PropertyStreetTypeEnum.Parkway]: 'Parkway',
    [PropertyStreetTypeEnum.Part]: 'Part',
    [PropertyStreetTypeEnum.Pass]: 'Pass',
    [PropertyStreetTypeEnum.Path]: 'Path',
    [PropertyStreetTypeEnum.Pathway]: 'Pathway',
    [PropertyStreetTypeEnum.Piazza]: 'Piazza',
    [PropertyStreetTypeEnum.Place]: 'Place',
    [PropertyStreetTypeEnum.Plateau]: 'Plateau',
    [PropertyStreetTypeEnum.Plaza]: 'Plaza',
    [PropertyStreetTypeEnum.Pocket]: 'Pocket',
    [PropertyStreetTypeEnum.Point]: 'Point',
    [PropertyStreetTypeEnum.Port]: 'Port',
    [PropertyStreetTypeEnum.Promenade]: 'Promenade',
    [PropertyStreetTypeEnum.Quad]: 'Quad',
    [PropertyStreetTypeEnum.Quadrangle]: 'Quadrangle',
    [PropertyStreetTypeEnum.Quadrant]: 'Quadrant',
    [PropertyStreetTypeEnum.Quay]: 'Quay',
    [PropertyStreetTypeEnum.Quays]: 'Quays',
    [PropertyStreetTypeEnum.Ramble]: 'Ramble',
    [PropertyStreetTypeEnum.Ramp]: 'Ramp',
    [PropertyStreetTypeEnum.Range]: 'Range',
    [PropertyStreetTypeEnum.Reach]: 'Reach',
    [PropertyStreetTypeEnum.Reserve]: 'Reserve',
    [PropertyStreetTypeEnum.Rest]: 'Rest',
    [PropertyStreetTypeEnum.Retreat]: 'Retreat',
    [PropertyStreetTypeEnum.Ride]: 'Ride',
    [PropertyStreetTypeEnum.Ridge]: 'Ridge',
    [PropertyStreetTypeEnum.Ridgeway]: 'Ridgeway',
    [PropertyStreetTypeEnum.RightOfWay]: 'Right of Way',
    [PropertyStreetTypeEnum.Ring]: 'Ring',
    [PropertyStreetTypeEnum.Rise]: 'Rise',
    [PropertyStreetTypeEnum.River]: 'River',
    [PropertyStreetTypeEnum.Riverway]: 'Riverway',
    [PropertyStreetTypeEnum.Riviera]: 'Riviera',
    [PropertyStreetTypeEnum.Road]: 'Road',
    [PropertyStreetTypeEnum.Roads]: 'Roads',
    [PropertyStreetTypeEnum.Roadside]: 'Roadside',
    [PropertyStreetTypeEnum.Roadway]: 'Roadway',
    [PropertyStreetTypeEnum.Rosebowl]: 'Rosebowl',
    [PropertyStreetTypeEnum.Rotary]: 'Rotary',
    [PropertyStreetTypeEnum.Round]: 'Round',
    [PropertyStreetTypeEnum.Route]: 'Route',
    [PropertyStreetTypeEnum.Row]: 'Row',
    [PropertyStreetTypeEnum.Rue]: 'Rue',
    [PropertyStreetTypeEnum.Run]: 'Run',
    [PropertyStreetTypeEnum.ServiceWay]: 'Service Way',
    [PropertyStreetTypeEnum.Siding]: 'Siding',
    [PropertyStreetTypeEnum.Upper]: 'Upper',
    [PropertyStreetTypeEnum.Slope]: 'Slope',
    [PropertyStreetTypeEnum.Sound]: 'Sound',
    [PropertyStreetTypeEnum.Spur]: 'Spur',
    [PropertyStreetTypeEnum.Square]: 'Square',
    [PropertyStreetTypeEnum.Stairs]: 'Stairs',
    [PropertyStreetTypeEnum.StateHighway]: 'State Highway',
    [PropertyStreetTypeEnum.Steps]: 'Steps',
    [PropertyStreetTypeEnum.Strait]: 'Strait',
    [PropertyStreetTypeEnum.Strand]: 'Strand',
    [PropertyStreetTypeEnum.Street]: 'Street',
    [PropertyStreetTypeEnum.Strip]: 'Strip',
    [PropertyStreetTypeEnum.Subway]: 'Subway',
    [PropertyStreetTypeEnum.Tarn]: 'Tarn',
    [PropertyStreetTypeEnum.Terrace]: 'Terrace',
    [PropertyStreetTypeEnum.Thoroughfare]: 'Thoroughfare',
    [PropertyStreetTypeEnum.Tollway]: 'Tollway',
    [PropertyStreetTypeEnum.Top]: 'Top',
    [PropertyStreetTypeEnum.Tor]: 'Tor',
    [PropertyStreetTypeEnum.Towers]: 'Towers',
    [PropertyStreetTypeEnum.Track]: 'Track',
    [PropertyStreetTypeEnum.Trail]: 'Trail',
    [PropertyStreetTypeEnum.Trailer]: 'Trailer',
    [PropertyStreetTypeEnum.Triangle]: 'Triangle',
    [PropertyStreetTypeEnum.Trunkway]: 'Trunkway',
    [PropertyStreetTypeEnum.Turn]: 'TURN',
    [PropertyStreetTypeEnum.Underpass]: 'Underpass',
    [PropertyStreetTypeEnum.Vale]: 'Vale',
    [PropertyStreetTypeEnum.Viaduct]: 'Viaduct',
    [PropertyStreetTypeEnum.View]: 'View',
    [PropertyStreetTypeEnum.Villas]: 'Villas',
    [PropertyStreetTypeEnum.Vista]: 'Vista',
    [PropertyStreetTypeEnum.Wade]: 'Wade',
    [PropertyStreetTypeEnum.Walk]: 'Walk',
    [PropertyStreetTypeEnum.Walkway]: 'Walkway',
    [PropertyStreetTypeEnum.Way]: 'Way',
    [PropertyStreetTypeEnum.Wharf]: 'Wharf',
    [PropertyStreetTypeEnum.Wynd]: 'Wynd',
    [PropertyStreetTypeEnum.Yard]: 'Yard',
};

export default propertyStreetTypeLabels;
