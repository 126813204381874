import { Datum } from '@ant-design/charts';
import { G2, Line } from '@ant-design/plots';
import { Breadcrumb, Layout, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoanBookForecastDay from '~Api/Warehouse/ILoanBookForecastDay';
import { currencyFormatter } from '~utilities/formatters';
import { warehousesLoanBookForecastListAction } from './actions';
import { warehousesLoanBookForecastDaysSelector } from './selectors';

interface IReformattedDataPoint {
    date: string;
    loanBook: number;
    series: string;
}

export default function LoanBookForecast(): ReactElement {
    const forecastDays: ILoanBookForecastDay[] = useSelector(warehousesLoanBookForecastDaysSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        dispatch(warehousesLoanBookForecastListAction());
    }, [
        dispatch,
    ]);

    if (!forecastDays) {
        return (
            <Layout className='warehouses loan-book-forecast'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/warehouses'>Warehouses</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Loan Book Forecast</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Loan Book Forecast</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const reformattedDays: IReformattedDataPoint[] = [];
    _.each(forecastDays, (forecastDay: ILoanBookForecastDay) => {
        reformattedDays.push({
            date: forecastDay.date,
            loanBook: forecastDay.loanBook,
            series: 'Possible Discharges',
        });
        reformattedDays.push({
            date: forecastDay.date,
            loanBook: forecastDay.loanBookDischargesBooked,
            series: 'Booked Discharges',
        });
    });

    let min: number = null;
    let max: number = null;

    _.each(reformattedDays, (item: IReformattedDataPoint) => {
        if (null === min || item['loanBook'] < min) {
            min = item['loanBook'];
        }
        if (null === max || item['loanBook'] > max) {
            max = item['loanBook'];
        }
    });

    G2.registerShape('point', 'custom-point', {
        draw(cfg: G2.Types.ShapeInfo, container: G2.IGroup) {
            const group: G2.IGroup = container.addGroup();

            const dataPoint: IReformattedDataPoint = cfg.data as IReformattedDataPoint;

            if (dataPoint.date === dayjs().format('YYYY-MM-DD')) {
                group.addShape('circle', {
                    attrs: {
                        fill: 'red',
                        opacity: 0.5,
                        r: 6,
                        x: cfg.x as number,
                        y: cfg.y as number,
                    },
                    name: 'outer-point',
                });
                group.addShape('circle', {
                    attrs: {
                        fill: 'red',
                        opacity: 1,
                        r: 2,
                        x: cfg.x as number,
                        y: cfg.y as number,
                    },
                    name: 'inner-point',
                });
            }

            return group;
        },
    });

    const xFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
        currency: 'AUD',
        maximumFractionDigits: 0,
        style: 'currency',
    });

    return (
        <Layout className='warehouses loan-book-forecast'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/warehouses'>Warehouses</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Loan Book Forecast</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Loan Book Forecast</Typography.Title>
                    <Line
                        data={reformattedDays}
                        padding='auto'
                        point={{ shape: 'custom-point' }}
                        tooltip={{
                            formatter: (datum: Datum) => {
                                const dataPoint: IReformattedDataPoint = datum as IReformattedDataPoint;

                                return {
                                    name: dataPoint.series,
                                    value: currencyFormatter.format(dataPoint.loanBook),
                                };
                            },
                            showTitle: false,
                        }}
                        seriesField='series'
                        xField='date'
                        yField='loanBook'
                        xAxis={{ label: { formatter: (text: string) => dayjs(text).format('D MMM') }, tickCount: 5 }}
                        yAxis={{ label: { formatter: (text: string) => `${xFormatter.format(text as unknown as number / 1_000_000)}M` }, max, min }}
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
