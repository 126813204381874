import { SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Layout, Space, Spin, Table, Typography } from 'antd';
import { ColumnsType, FilterDropdownProps, FilterValue, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import SearchDropdown from '~UI/SearchDropdown';
import { IDictionary } from '~utilities/IDictionary';
import {
    loanListSettingsSetAction,
    loansPaginatedListAction,
} from './actions';
import './loans.less';
import Search from './Search';
import {
    loanListSettingsSelector,
    loansPaginatedCountSelector,
    loansPaginatedSelector,
} from './selectors';
import { administratorsActiveSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import AssigneeSelector from './Loan/AssigneeSelector';
import { administratorsListAction } from '~Administrators/actions';
import ListSortFieldEnum from '~Api/Loan/ListSortFieldEnum';
import ListSortOrderEnum from '~Api/Loan/ListSortOrderEnum';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import statusLabels from './statusLabels';

const tableSortFieldMappings: IDictionary<ListSortFieldEnum> = {
    amount: ListSortFieldEnum.Amount,
    code: ListSortFieldEnum.Code,
    name: ListSortFieldEnum.Name,
    rate: ListSortFieldEnum.Rate,
    startDate: ListSortFieldEnum.StartDate,
};

const tableSortOrderMappings: IDictionary<ListSortOrderEnum> = {
    ascend: ListSortOrderEnum.Ascending,
    descend: ListSortOrderEnum.Descending,
};

export interface ILoanListSettings {
    administratorUuidFilter: string[];
    codeSearch: string[];
    nameSearch: string[];
    currentPage: number;
    pageSize: number;
    sortField: string;
    sortOrder: SortOrder;
    statusFilter: LoanStatusEnum[];
}

export default function List(): ReactElement {
    const activeAdministrators: IAdministrator[] = useSelector(administratorsActiveSelector);
    const loanListSettings: ILoanListSettings = useSelector(loanListSettingsSelector);
    const loans: IDictionary<ILoan> = useSelector(loansPaginatedSelector);
    const loansPaginatedCount: number = useSelector(loansPaginatedCountSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!activeAdministrators) {
            dispatch(administratorsListAction());
        }
    }, [
        activeAdministrators,
        dispatch,
    ]);

    useEffect(() => {
        if (!loans) {
            dispatch(loansPaginatedListAction(
                loanListSettings.currentPage,
                loanListSettings.pageSize,
                ListSortFieldEnum.StartDate,
                ListSortOrderEnum.Descending,
                loanListSettings.statusFilter,
                loanListSettings.administratorUuidFilter,
                loanListSettings.codeSearch ? loanListSettings.codeSearch[0] : null,
                loanListSettings.nameSearch ? loanListSettings.nameSearch[0] : null,
            ));
        }
    }, [
        dispatch,
        loans,
        loanListSettings,
    ]);

    const onChangeTable: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => void = useCallback((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
        dispatch(loansPaginatedListAction(
            pagination.current,
            pagination.pageSize,
            tableSortFieldMappings[sorter.columnKey] || ListSortFieldEnum.StartDate,
            tableSortOrderMappings[sorter.order] || ListSortOrderEnum.Descending,
            (filters.status as LoanStatusEnum[]) || [],
            (filters.administratorUuid as string[]) || [],
            filters.code ? (filters.code[0] as string) : null,
            filters.name ? (filters.name[0] as string) : null,
        ));

        dispatch(loanListSettingsSetAction({
            administratorUuidFilter: (filters.administratorUuid as string[]),
            codeSearch: (filters.code as string[]),
            currentPage: pagination.current,
            nameSearch: (filters.name as string[]),
            pageSize: pagination.pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
            statusFilter: (filters.status as LoanStatusEnum[]),
        }));
    }, [
        dispatch,
    ]);

    if (!activeAdministrators) {
        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Loans</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Loans</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const columns: ColumnsType<ILoan> = [
        {
            dataIndex: 'code',
            filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
            filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
            filteredValue: loanListSettings.codeSearch,
            onFilter: (value: string | number | boolean, loan: ILoan) => `${loan.salesforceCode} ${loan.code}`.toLocaleLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
            render: (code: string, loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{code || loan.salesforceCode}</Link>,
            sortOrder: loanListSettings.sortField === 'code' ? loanListSettings.sortOrder : null,
            sorter: (a: ILoan, b: ILoan) => (a.code || a.salesforceCode).localeCompare(b.code || b.salesforceCode),
            title: 'Code',
            width: '10%',
        },
        {
            dataIndex: 'name',
            filterDropdown: (params: FilterDropdownProps) => <SearchDropdown params={params} />,
            filterIcon: (filtered: boolean) => <SearchOutlined className={filtered && 'filtered'} />,
            filteredValue: loanListSettings.nameSearch,
            onFilter: (value: string | number | boolean, loan: ILoan) => (loan.name || '').toLowerCase().includes(value.toLocaleString().toLocaleLowerCase()),
            render: (name: string) => name || '-',
            sortOrder: loanListSettings.sortField === 'name' ? loanListSettings.sortOrder : null,
            sorter: (a: ILoan, b: ILoan) => (a.name || '').localeCompare(b.name || ''),
            title: 'Name',
        },
        {
            dataIndex: 'amount',
            render: (amount: number) => currencyFormatter.format(amount),
            sortOrder: loanListSettings.sortField === 'amount' ? loanListSettings.sortOrder : null,
            sorter: (a: ILoan, b: ILoan) => a.amount > b.amount  ? 1 : -1,
            title: 'Amount',
            width: '15%',
        },
        {
            dataIndex: 'administratorUuid',
            filteredValue: loanListSettings.administratorUuidFilter,
            filters: activeAdministrators.map((administrator: IAdministrator) => ({
                text: administrator.name,
                value: administrator.uuid,
            })),
            onFilter: (value: boolean|number|string, loan: ILoan) => loan.administratorUuid === value,
            render: (administratorUuid: string, loan: ILoan) => <AssigneeSelector loan={loan} />,
            title: 'Loan Manager',
            width: '15%',
        },
        {
            dataIndex: 'interestRate',
            render: (interestRate: number) => interestRate ? percentageFormatter.format(interestRate / 100) : '-',
            sortOrder: loanListSettings.sortField === 'interestRate' ? loanListSettings.sortOrder : null,
            sorter: (a: ILoan, b: ILoan) => a.interestRate > b.interestRate  ? 1 : -1,
            title: 'Rate',
            width: '10%',
        },
        {
            dataIndex: 'status',
            filteredValue: loanListSettings.statusFilter,
            filters: _.keys(statusLabels).map((status: string) => ({
                text: statusLabels[status],
                value: status,
            })),
            onFilter: (value: string | number | boolean, loan: ILoan) => loan.status === value,
            render: (status: LoanStatusEnum) => statusLabels[status],
            title: 'Status',
            width: '20%',
        },
        {
            dataIndex: 'startDate',
            defaultSortOrder: 'descend',
            render: (startDate: string) => startDate ? dayjs(startDate).format('Do MMMM YYYY') : '-',
            sortOrder: loanListSettings.sortField === 'startDate' ? loanListSettings.sortOrder : null,
            sorter: (a: ILoan, b: ILoan) => dayjs(a.startDate) > dayjs(b.startDate)  ? 1 : -1,
            title: 'Start Date',
            width: '15%',
        },
    ];

    return (
        <Layout className='loans'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Loans</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Space className='actions'>
                        <Search/>
                        <Link to='/loans'><Button>Board View</Button></Link>
                    </Space>
                    <Typography.Title level={2}>Loans</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(loans)}
                        loading={!loans}
                        onChange={onChangeTable}
                        pagination={{ current: loanListSettings.currentPage, pageSize: loanListSettings.pageSize, total: loansPaginatedCount }}
                        rowKey='uuid'
                        size='middle'
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
