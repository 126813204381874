import _ from 'lodash';
import IProperty from '~Api/Deal/IProperty';
import IDocument from '~Api/Loan/IDocument';
import IHistory from '~Api/Loan/IHistory';
import ILoan from '~Api/Loan/ILoan';
import ILoanFee from '~Api/Loan/ILoanFee';
import ILoanGracePeriod from '~Api/Loan/ILoanGracePeriod';
import ILoanNote from '~Api/Loan/ILoanNote';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import ILoanPayoutFigureItem from '~Api/Loan/ILoanPayoutFigureItem';
import ILoanPayoutFigureSection from '~Api/Loan/ILoanPayoutFigureSection';
import ILoanTransaction from '~Api/Loan/ILoanTransaction';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import { dealPropertiesSelector } from '~Deals/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { warehouseLoanSelector } from '~Warehouses/selectors';
import { ILoanListSettings } from './List';
import { applicationSelector } from '~Applications/selectors';
import { keyMap } from '~utilities/utils';

function hydrateLoan(state: IGlobalState, loan: ILoan): ILoan {
    const cloanLoan: ILoan = { ...loan };

    if (loan && loan.extensionApplicationUuid) {
        cloanLoan.extensionApplication = applicationSelector(state, loan.extensionApplicationUuid);
    }

    if (loan && loan.applicationUuid) {
        cloanLoan.application = applicationSelector(state, loan.applicationUuid);
    }

    if (state.loans.loanWarehouseLoanUuids[loan.uuid]) {
        cloanLoan.warehouseLoans = _.map(state.loans.loanWarehouseLoanUuids[loan.uuid], (warehouseLoanUuid: string) => warehouseLoanSelector(state, warehouseLoanUuid, true));
    }

    return cloanLoan;
}

function hydrateLoanPayoutFigure(state: IGlobalState, loanPayoutFigure: ILoanPayoutFigure): ILoanPayoutFigure {
    const cloneLoanPayoutFigure: ILoanPayoutFigure = { ...loanPayoutFigure };
    cloneLoanPayoutFigure.sections = _.values(loanPayoutFigureSectionsSelector(state, loanPayoutFigure.uuid));
    return cloneLoanPayoutFigure;
}

function hydrateLoanPayoutFigureSection(state: IGlobalState, loanPayoutFigureSection: ILoanPayoutFigureSection): ILoanPayoutFigureSection {
    const cloneLoanPayoutFigureSection: ILoanPayoutFigureSection = { ...loanPayoutFigureSection };
    cloneLoanPayoutFigureSection.items = _.values(loanPayoutFigureSectionItemsSelector(state, loanPayoutFigureSection.uuid));
    return cloneLoanPayoutFigureSection;
}

export function loanSelector(state: IGlobalState, uuid: string): ILoan {
    if (!state.loans.loans || !state.loans.loans[uuid]) {
        return null;
    }

    return hydrateLoan(state, state.loans.loans[uuid]);
}

export function loanDealPropertiesSelector(state: IGlobalState, loanUuid: string): IDictionary<IProperty> {
    const loan: ILoan = loanSelector(state, loanUuid);

    if (!loan) {
        return null;
    }

    return dealPropertiesSelector(state, loan.dealUuid);
}

export function loanDocumentsSelector(state: IGlobalState, loanUuid: string): IDictionary<IDocument> {
    if (!state.loans.loanDocumentUuids[loanUuid]) {
        return null;
    }

    const documents: IDictionary<IDocument> = {};
    state.loans.loanDocumentUuids[loanUuid].forEach((loanDocumentUuid: string) => {
        documents[loanDocumentUuid] = state.loans.documents[loanDocumentUuid];
    });

    return documents;
}

export function loanFeeSelector(state: IGlobalState, loanUuid: string, feeUuid: string): ILoanFee {
    return state.loans.fees[loanUuid] && state.loans.fees[loanUuid][feeUuid];
}

export function loanFeesSelector(state: IGlobalState, uuid: string): ILoanFee[] {
    return state.loans.fees[uuid] && _.values(state.loans.fees[uuid]);
}

export function loanGracePeriodsSelector(state: IGlobalState, loanUuid: string): IDictionary<ILoanGracePeriod> {
    if (!state.loans.loanGracePeriodUuids[loanUuid]) {
        return null;
    }

    const gracePeriods: IDictionary<ILoanGracePeriod> = {};
    _.forEach(state.loans.loanGracePeriodUuids[loanUuid], (loanGracePeriodUuid: string) => {
        gracePeriods[loanGracePeriodUuid] = state.loans.gracePeriods[loanGracePeriodUuid];
    });

    return gracePeriods;
}

export function loanHistoriesSelector(state: IGlobalState, uuid: string): IHistory[] {
    return state.loans.histories[uuid] && _.values(state.loans.histories[uuid]);
}

export function loanListSettingsSelector(state: IGlobalState): ILoanListSettings {
    return state.loans.loanListSettings;
}

export function loanNotesSelector(state: IGlobalState, uuid: string): ILoanNote[] {
    return state.loans.notes[uuid] && _.values(state.loans.notes[uuid]);
}

export function loanPayoutFiguresSelector(state: IGlobalState, loanUuid: string): IDictionary<ILoanPayoutFigure> {
    if (!state.loans.loanPayoutFigureUuids[loanUuid]) {
        return null;
    }

    const loanPayoutFigures: IDictionary<ILoanPayoutFigure> = {};
    _.forEach(state.loans.loanPayoutFigureUuids[loanUuid], (payoutFigureUuid: string) => {
        loanPayoutFigures[payoutFigureUuid] = state.loans.payoutFigures[payoutFigureUuid];
    });

    return loanPayoutFigures;
}

export function loanPayoutFigureDealPropertiesSelector(state: IGlobalState, loanPayoutFigureUuid: string): IDictionary<IProperty> {
    const loanPayoutFigure: ILoanPayoutFigure = loanPayoutFigureSelector(state, loanPayoutFigureUuid);
    return loanPayoutFigure && loanDealPropertiesSelector(state, loanPayoutFigure.loanUuid);
}

export function loanPayoutFigureLoanSelector(state: IGlobalState, loanPayoutFigureUuid: string): ILoan {
    const loanPayoutFigure: ILoanPayoutFigure = loanPayoutFigureSelector(state, loanPayoutFigureUuid);
    return loanPayoutFigure && loanSelector(state, loanPayoutFigure.loanUuid);
}

export function loanPayoutFigureSelector(state: IGlobalState, payoutFigureUuid: string): ILoanPayoutFigure {
    return state.loans.payoutFigures[payoutFigureUuid] && hydrateLoanPayoutFigure(state, state.loans.payoutFigures[payoutFigureUuid]);
}

export function loanPayoutFigureItemSelector(state: IGlobalState, payoutFigureItemUuid: string): ILoanPayoutFigureItem {
    return state.loans.payoutFigureItems[payoutFigureItemUuid];
}

export function loanPayoutFigureSectionItemsSelector(state: IGlobalState, payoutFigureSectionUuid: string): IDictionary<ILoanPayoutFigureItem> {
    if (!state.loans.payoutFigureSectionItemUuids[payoutFigureSectionUuid]) {
        return null;
    }

    const payoutFigureItems: IDictionary<ILoanPayoutFigureItem> = {};
    _.forEach(state.loans.payoutFigureSectionItemUuids[payoutFigureSectionUuid], (itemUuid: string) => {
        payoutFigureItems[itemUuid] = state.loans.payoutFigureItems[itemUuid];
    });

    return payoutFigureItems;
}

export function loanPayoutFigureSectionsSelector(state: IGlobalState, payoutFigureUuid: string): IDictionary<ILoanPayoutFigureSection> {
    if (!state.loans.payoutFigureSectionUuids[payoutFigureUuid]) {
        return null;
    }

    const payoutFigureSections: IDictionary<ILoanPayoutFigureSection> = {};
    _.forEach(state.loans.payoutFigureSectionUuids[payoutFigureUuid], (sectionUuid: string) => {
        payoutFigureSections[sectionUuid] = hydrateLoanPayoutFigureSection(state, state.loans.payoutFigureSections[sectionUuid]);
    });

    return payoutFigureSections;
}

export function loanTransactionsSelector(state: IGlobalState, loanUuid: string): IDictionary<ILoanTransaction> {
    return state.loans.loanTransactions[loanUuid] && _.keyBy(state.loans.loanTransactions[loanUuid], 'uuid');
}

export function loanWarehouseLoansSelector(state: IGlobalState, loanUuid: string): IDictionary<IWarehouseLoan> {
    if (!state.loans.loanWarehouseLoanUuids || !state.loans.loanWarehouseLoanUuids[loanUuid]) {
        return null;
    }

    const warehouseLoans: IDictionary<IWarehouseLoan> = {};

    _.each(state.loans.loanWarehouseLoanUuids[loanUuid], (warehouseLoanUuid: string) => {
        warehouseLoans[warehouseLoanUuid] = warehouseLoanSelector(state, warehouseLoanUuid, true);
    });

    return warehouseLoans;
}

export function loansDashboardSelector(state: IGlobalState): IDictionary<ILoan> {
    if (!state.loans.loansDashboardUuids) {
        return null;
    }

    return keyMap(state.loans.loansDashboardUuids, 'uuid', (uuid: string) => loanSelector(state, uuid));
}

export function loansDischargeForecastSelector(state: IGlobalState): IDictionary<ILoan> {
    if (!state.loans.loansDischargeForecastUuids) {
        return null;
    }

    const loans: IDictionary<ILoan> = {};
    _.each(state.loans.loansDischargeForecastUuids, (uuid: string) => {
        loans[uuid] = loanSelector(state, uuid);
    });

    return loans;
}

export function loansDrawdownsSelector(state: IGlobalState): IDictionary<ILoan> {
    if (!state.loans.drawdownUuids) {
        return null;
    }

    return keyMap(state.loans.drawdownUuids, 'uuid', (uuid: string) => loanSelector(state, uuid));
}

export function loansPaginatedCountSelector(state: IGlobalState): number {
    return state.loans.loansPaginatedCount;
}

export function loansPaginatedSelector(state: IGlobalState): IDictionary<ILoan> {
    if (!state.loans.loansPaginatedUuids) {
        return null;
    }

    const loans: IDictionary<ILoan> = {};

    state.loans.loansPaginatedUuids.forEach((loanUuid: string) => {
        loans[loanUuid] = loanSelector(state, loanUuid);
    });

    return loans;
}

export function loansSearchResultsSelector(state: IGlobalState): IDictionary<ILoan> {
    if (!state.loans.loansSearchResultUuids) {
        return null;
    }

    const loansSearchResults: IDictionary<ILoan> = {};
    _.each(state.loans.loansSearchResultUuids, (uuid: string) => {
        loansSearchResults[uuid] = loanSelector(state, uuid);
    });

    return loansSearchResults;
}

export function loansSelector(state: IGlobalState): IDictionary<ILoan> {
    if (!state.loans.loansListed) {
        return null;
    }

    const loans: IDictionary<ILoan> = {};

    _.each(state.loans.loans, (loan: ILoan) => {
        loans[loan.uuid] = hydrateLoan(state, loan);
    });

    return loans;
}
