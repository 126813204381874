enum RegistrationSourceEnum {
    Advisor = 'ADVISOR',
    AggregatorBdm = 'AGGREGATOR_BDM',
    AustralianBroker = 'AUSTRALIAN_BROKER',
    FinanceAndCoffee = 'FINANCE_AND_COFFEE',
    FundingBdm = 'FUNDING_BDM',
    Google = 'GOOGLE',
    IndustryEvent = 'INDUSTRY_EVENT',
    LunchAndLearn = 'LUNCH_AND_LEARN',
    MortgageBusiness = 'MORTGAGE_BUSINESS',
    Mpa = 'MPA',
    PdDay = 'PD_DAY',
    Webinar = 'WEBINAR',
}

export default RegistrationSourceEnum;
