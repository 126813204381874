import { Layout as AntLayout, Breadcrumb, Descriptions, Menu, PageHeader, Spin, Tag } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import { currencyFormatter } from '~utilities/formatters';
import { warehouseGetAction } from '~Warehouses/actions';
import { warehouseSelector } from '~Warehouses/selectors';
import './warehouse.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuDivider from '~UI/menuDivider';
import { getUnusedFunds } from '~Warehouses/utilities';

export default function Layout(props: {
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    uuid: string;
    section: string;
    sectionClass?: string;
}): ReactElement {
    const { breadcrumb, children, section, sectionClass, uuid } = props;

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const warehouse: IWarehouse = useSelector((state: IGlobalState) => warehouseSelector(state, uuid));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!warehouse) {
            dispatch(warehouseGetAction(uuid));
        }
    }, [
        dispatch,
        uuid,
        warehouse,
    ]);

    if (!warehouse) {
        return (
            <AntLayout className='warehouse'>
                <Spin/>
            </AntLayout>
        );
    }

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <React.Fragment>
            <Breadcrumb.Item><Link to={`/warehouses/${warehouse.uuid}`}>{warehouse.name}</Link></Breadcrumb.Item>
            {breadcrumb}
        </React.Fragment>
    ) : (
        <Breadcrumb.Item>{warehouse.name}</Breadcrumb.Item>
    );

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/warehouses/${warehouse.uuid}`}>Overview</NavLink>,
        },
        {
            key: 'loans',
            label: <NavLink to={`/warehouses/${warehouse.uuid}/loans`}>Loans</NavLink>,
        },
        ...([WarehouseTypeEnum.Fwt1, WarehouseTypeEnum.Fwt2].includes(warehouse.type) ? [
            {
                key: 'transactions',
                label: <NavLink to={`/warehouses/${warehouse.uuid}/transactions`}>Transactions</NavLink>,
            },
        ] : []),
        {
            key: 'eligible-loans',
            label: <NavLink to={`/warehouses/${warehouse.uuid}/eligible-loans`}>Eligible Loans</NavLink>,
        },
        {
            key: 'pending-applications',
            label: <NavLink to={`/warehouses/${warehouse.uuid}/pending-applications`}>Pending Applications</NavLink>,
        },
        ...(currentUser.permissions.includes(PermissionsEnum.WarehouseLoanSell) ? [
            {
                key: 'proposed-sales',
                label: <NavLink to={`/warehouses/${warehouse.uuid}/proposed-sales`}>Proposed Sales</NavLink>,
            },
            {
                key: 'proposed-purchases',
                label: <NavLink to={`/warehouses/${warehouse.uuid}/proposed-purchases`}>Proposed Purchases</NavLink>,
            },
        ] : []),
        ...(currentUser.permissions.includes(PermissionsEnum.WarehouseActiveReport) ? [
            menuDivider,
            {
                key: 'active-report',
                label: <NavLink to={`/warehouses/${warehouse.uuid}/active-report`}>Active Report</NavLink>,
            },
        ] : []),
    ];

    return (
        <AntLayout className='warehouse'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/warehouses'>Warehouses</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                ghost={false}
                title={warehouse.name}
            >
                <Descriptions size='small' column={4}>
                    <Descriptions.Item label='Active Loans'>
                        <Link to={`/warehouses/${warehouse.uuid}/loans`}>{warehouse.activeLoanCount}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label='Usable Funds'>
                        {currencyFormatter.format(warehouse.totalCapital)}
                    </Descriptions.Item>
                    <Descriptions.Item label='Used Funds'>
                        <Link to={`/warehouses/${warehouse.uuid}/loans`}>{currencyFormatter.format(warehouse.totalBalancePrincipal)}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label='Pending Applications'>
                        <Link to={`/warehouses/${warehouse.uuid}/pending-applications`}>{warehouse.reservedApplications ? currencyFormatter.format(warehouse.reservedApplications) : '-'}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label='Pending Purchases'>
                        <Link to={`/warehouses/${warehouse.uuid}/proposed-purchases`}>{warehouse.reservedPurchases ? currencyFormatter.format(warehouse.reservedPurchases) : '-'}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label='Pending Sales'>
                        <Link to={`/warehouses/${warehouse.uuid}/proposed-sales`}>{warehouse.reservedSales ? currencyFormatter.format(warehouse.reservedSales) : '-'}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label='Total Retained'>
                        {warehouse.retainedFunds ? currencyFormatter.format(warehouse.retainedFunds + warehouse.retainedContingency) : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label='Unused Funds'>
                        {currencyFormatter.format(getUnusedFunds(warehouse))}
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <AntLayout className='content-wrapper'>
                <AntLayout.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </AntLayout.Sider>
                <AntLayout.Content className={`content ${section} ${sectionClass}`}>
                    {children}
                </AntLayout.Content>
            </AntLayout>
        </AntLayout>
    );
}
