import { Form, Input, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import { currencyFormatter } from '~utilities/formatters';
import { IDictionary } from '~utilities/IDictionary';
import { warehouseProposedSalesAddAction, warehousesListAction } from '~Warehouses/actions';
import { warehouseLoanSelector, warehousesSelector } from '~Warehouses/selectors';

interface IState {
    destinationWarehouseAmountErrors: IDictionary<string>;
    destinationWarehouseAmounts: IDictionary<number>;
    dirtyFields: IDictionary<boolean>;
    errors: {
        totalSaleAmount?: string,
        transactionDateTime?: string,
    };
    transactionDateTime: string;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
    warehouseLoanUuid: string;
}

interface IPropsSelector {
    warehouseLoan: IWarehouseLoan;
    warehouses: IDictionary<IWarehouse>;
}

interface IPropsDispatch {
    proposeSale: (destinationWarehouseAmounts: IDictionary<number>, transactionTime: string) => void;
    warehousesList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class ProposeSaleModal extends React.Component<Props, IState> {
    public state: IState = {
        destinationWarehouseAmountErrors: {},
        destinationWarehouseAmounts: {},
        dirtyFields: {},
        errors: {
            totalSaleAmount: null,
            transactionDateTime: null,
        },
        transactionDateTime: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeAmountPrincipal = this.onChangeAmountPrincipal.bind(this);
        this.onChangeTransactionDate = this.onChangeTransactionDate.bind(this);
        this.onChangeTransactionTime = this.onChangeTransactionTime.bind(this);
        this.getDefaultedAmounts = this.getDefaultedAmounts.bind(this);

        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickOk = this.onClickOk.bind(this);
    }

    public componentDidMount(): void {
        const { warehouses } = this.props;

        if (!warehouses) {
            this.props.warehousesList();
        }
    }

    public render(): JSX.Element {
        const { isOpen, warehouses, warehouseLoan } = this.props;
        const { errors, destinationWarehouseAmountErrors, transactionDateTime } = this.state;

        if (!warehouseLoan) {
            return null;
        }

        const destinationWarehouseAmounts: IDictionary<number> = this.getDefaultedAmounts();

        const totalSaleAmount: number = _.reduce(
            _.values(destinationWarehouseAmounts),
            (sum: number, amountPrincipal: number) => sum + amountPrincipal,
            0,
        );
        const loanAmountRemaining: number = warehouseLoan.balancePrincipal + totalSaleAmount;
        const transactionDateTimeDayJs: Dayjs = transactionDateTime ? dayjs(transactionDateTime) : null;

        const warehouseAmounts: JSX.Element[] = _.map(_.keysIn(destinationWarehouseAmounts), (warehouseUuid: string) => {
            const warehouse: IWarehouse = warehouses[warehouseUuid];
            const onChangeAmountPrincipal: (event: React.ChangeEvent<HTMLInputElement>) => void = (event: React.ChangeEvent<HTMLInputElement>) => this.onChangeAmountPrincipal(event, warehouse.uuid);
            const validateAmountPrincipal: () => void = () => this.validateAmountPrincipal(warehouse.uuid);

            return (
                <Form.Item
                    key={warehouse.type}
                    label={warehouse.name}
                    className='amount-principal'
                    help={destinationWarehouseAmountErrors[warehouseUuid]}
                    validateStatus={destinationWarehouseAmountErrors[warehouseUuid] && 'error'}
                >
                    <Input
                        addonBefore='$'
                        onBlur={validateAmountPrincipal}
                        onChange={onChangeAmountPrincipal}
                        type='number'
                        value={destinationWarehouseAmounts[warehouseUuid] || null}
                        max={warehouseLoan.balancePrincipal * -1}
                    />
                </Form.Item>
            );
        });

        return (
            <Modal
                okText='Propose Sale'
                onCancel={this.onClickCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Propose Sale'
                wrapClassName='warehouse-propose-sale-modal'
            >
                <Form.Item
                    label='Transaction Date'
                    className='transaction-date'
                    help={errors.transactionDateTime}
                    validateStatus={errors.transactionDateTime && 'error'}
                >
                    <Space>
                        <DatePicker
                            className='transaction-pick-date'
                            onChange={this.onChangeTransactionDate}
                            value={transactionDateTimeDayJs}
                            format='DD/MM/YYYY'
                        />
                        <TimePicker
                            className='transaction-pick-time'
                            onChange={this.onChangeTransactionTime}
                            value={transactionDateTimeDayJs}
                            format='H:mm'
                        />
                    </Space>
                </Form.Item>
                <h3>Warehouse Sale Amounts</h3>
                {warehouseAmounts}
                <Form.Item
                    label='Propose Sale'
                    className='total-sale-amount'
                    help={errors.totalSaleAmount}
                    validateStatus={errors.totalSaleAmount && 'error'}
                >
                    {currencyFormatter.format(totalSaleAmount)}
                </Form.Item>
                <Form.Item label='Balance Remaining' className='balance-remaining'>
                    {currencyFormatter.format(loanAmountRemaining)}
                </Form.Item>
            </Modal>
        );
    }

    private getDefaultedAmounts(): IDictionary<number> {
        const { warehouses, warehouseLoan } = this.props;
        const { dirtyFields, destinationWarehouseAmounts } = this.state;

        const sellWarehouseOptions: IWarehouse[] = _.filter(warehouses, (warehouse: IWarehouse) => {
            return warehouseLoan.warehouseUuid !== warehouse.uuid && WarehouseTypeEnum.Private !== warehouse.type;
        });
        const defaultedDestinationWarehouseAmounts: IDictionary<number> = {};

        _.forEach(sellWarehouseOptions, (warehouse: IWarehouse) => {
            defaultedDestinationWarehouseAmounts[warehouse.uuid] = !!dirtyFields[warehouse.uuid] ? destinationWarehouseAmounts[warehouse.uuid] : null;
        });

        return defaultedDestinationWarehouseAmounts;
    }

    private onChangeAmountPrincipal(event: React.ChangeEvent<HTMLInputElement>, warehouseUuid: string): void {
        const amountPrincipal: number = event.target.value === '' ? null : event.target.valueAsNumber;
        this.setState((prevState: IState) => ({
            destinationWarehouseAmounts: {
                ...prevState.destinationWarehouseAmounts,
                [warehouseUuid]: amountPrincipal,
            },
            dirtyFields: {
                ...prevState.dirtyFields,
                [warehouseUuid]: true,
            },
        }));
    }

    private onChangeTransactionDate(date: Dayjs): void {
        this.setState((prevState: IState) => ({
            ...prevState,
            transactionDateTime: date ? date.format('YYYY-MM-DD') : null,
        }));
    }

    private onChangeTransactionTime(time: Dayjs): void {
        this.setState((prevState: IState) => ({
            ...prevState,
            transactionDateTime: dayjs(`${dayjs(prevState.transactionDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format(),
        }));
    }

    private onClickCancel(): void {
        this.setState({
            destinationWarehouseAmountErrors: {},
            destinationWarehouseAmounts: {},
            dirtyFields: {},
            errors: {
                totalSaleAmount: null,
                transactionDateTime: null,
            },
            transactionDateTime: dayjs().format(),
        });
        this.props.onCancel();
    }

    private onClickOk(): void {
        const { transactionDateTime } = this.state;
        const destinationWarehouseAmounts: IDictionary<number> = this.getDefaultedAmounts();

        let valid: boolean = true;

        valid = this.validateTransactionDateTime() && valid;
        valid = this.validateTotalSaleAmount() && valid;

        _.forEach(_.keysIn(destinationWarehouseAmounts), (warehouseUuid: string) => {
            valid = this.validateAmountPrincipal(warehouseUuid) && valid;
        });

        if (!valid) {
            return;
        }

        this.props.proposeSale(_.omitBy(destinationWarehouseAmounts, (amountPrincipal: number) => amountPrincipal === null), dayjs(transactionDateTime).format('YYYY-MM-DDTHH:mm:ssZ'));
        this.onClickCancel();
    }

    private validateAmountPrincipal(warehouseUuid: string): boolean {
        const { warehouses, warehouseLoan } = this.props;

        const warehouse: IWarehouse = warehouses[warehouseUuid];

        const destinationWarehouseAmounts: IDictionary<number> = this.getDefaultedAmounts();

        const amountPrincipal: number = destinationWarehouseAmounts[warehouseUuid];

        let error: string;

        if (amountPrincipal && amountPrincipal <= 0) {
            error = 'Amount must be greater than $0';
        } else if (amountPrincipal > 0) {
            switch (warehouse.type) {
                case WarehouseTypeEnum.Fit:
                    if (amountPrincipal !== warehouseLoan.loan.amount) {
                        error = 'Any loans sold to FIT must be for the entire loan amount';
                    }
                    break;
                case WarehouseTypeEnum.Fwt1:
                    if (amountPrincipal !== warehouseLoan.loan.amount) {
                        error = 'Any loans sold to FWT1 must be for the entire loan amount';
                    }
                    break;
                case WarehouseTypeEnum.Fwt2:
                    if (amountPrincipal !== warehouseLoan.loan.amount) {
                        error = 'Any loans sold to FWT2 must be for the entire loan amount';
                    }
                    break;
            }
        }

        this.setState((prevState: IState) => ({
            ...prevState,
            destinationWarehouseAmountErrors: {
                ...prevState.destinationWarehouseAmountErrors,
                [warehouseUuid]: error,
            },
        }));

        return !error;
    }

    private validateTotalSaleAmount(): boolean {
        const { warehouses, warehouseLoan } = this.props;
        const destinationWarehouseAmounts: IDictionary<number> = this.getDefaultedAmounts();

        const destinationWarehouseTypes: WarehouseTypeEnum[] = [];
        let totalSaleAmount: number = 0;

        _.forEach(destinationWarehouseAmounts, (amountPrincipal: number, destinationWarehouseUuid: string) => {
            if (amountPrincipal > 0) {
                destinationWarehouseTypes.push(warehouses[destinationWarehouseUuid].type);
            }
            totalSaleAmount += amountPrincipal;
        });

        let error: string;

        if (!totalSaleAmount || totalSaleAmount <= 0) {
            error = 'Total sale amount must be greater than $0.00';
        } else if (totalSaleAmount > (warehouseLoan.balancePrincipal * -1)) {
            error = `Total sale amount cannot exceed current warehouse loan balance of ${currencyFormatter.format(warehouseLoan.balancePrincipal * -1)}`;
        } else if ((warehouses[warehouseLoan.warehouseUuid].type === WarehouseTypeEnum.Fwt1 || warehouses[warehouseLoan.warehouseUuid].type === WarehouseTypeEnum.Fwt2) && totalSaleAmount !== warehouseLoan.loan.amount) {
            error = 'Any loans sold from FWT1 or FWT2 must be for the entire loan amount';
        } else if (warehouses[warehouseLoan.warehouseUuid].type === WarehouseTypeEnum.Fit && totalSaleAmount !== (warehouseLoan.balancePrincipal * -1)) {
            error = 'Any loans sold from FIT must be for the entire holding amount';
        }

        this.setState((prevState: IState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                totalSaleAmount: error,
            },
        }));

        return !error;
    }

    private validateTransactionDateTime(): boolean {
        const { transactionDateTime } = this.state;

        let error: string;

        if (!transactionDateTime) {
            error = 'Please enter a date and time';
        } else if (dayjs(transactionDateTime).isSameOrBefore(dayjs())) {
            error = 'Transaction date and time cannot be dated in the past';
        }

        this.setState((prevState: IState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                transactionDateTime: error,
            },
        }));

        return !error;
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        warehouseLoan: warehouseLoanSelector(state, ownProps.warehouseLoanUuid),
        warehouses: warehousesSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        proposeSale: (destinationWarehouseAmounts: IDictionary<number>, transactionTime: string) => dispatch(warehouseProposedSalesAddAction(ownProps.warehouseLoanUuid, destinationWarehouseAmounts, transactionTime)),
        warehousesList: () => dispatch(warehousesListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProposeSaleModal);
