enum PropertyStreetTypeEnum {
    Access = 'ACCS',
    Alley = 'ALLY',
    Alleyway = 'ALWY',
    Amble = 'AMBL',
    Anchorage = 'ANCG',
    Approach = 'APP',
    Arcade = 'ARC',
    Artery = 'ART',
    Avenue = 'AVE',
    Basin = 'BASN',
    Beach = 'BCH',
    Bend = 'BEND',
    Block = 'BLK',
    Boulevard = 'BVD',
    Brace = 'BRCE',
    Brae = 'BRAE',
    Break = 'BRK',
    Bridge = 'BDGE',
    Broadway = 'BDWY',
    Brow = 'BROW',
    Bypass = 'BYPA',
    Byway = 'BYWY',
    Causeway = 'CAUS',
    Centre = 'CTR',
    Centreway = 'CNWY',
    Chase = 'CH',
    Circle = 'CIR',
    Circlet = 'CLT',
    Circuit = 'CCT',
    Circus = 'CRCS',
    Close = 'CL',
    Colonnade = 'CLDE',
    Common = 'CMMN',
    Concourse = 'CON',
    Copse = 'CPS',
    Corner = 'CNR',
    Corso = 'CSO',
    Court = 'CT',
    Courtyard = 'CTYD',
    Cove = 'COVE',
    Crescent = 'CRES',
    Crest = 'CRST',
    Cross = 'CRSS',
    Crossing = 'CRSG',
    Crossroad = 'CRD',
    Crossway = 'COWY',
    Cruiseway = 'CUWY',
    Culdesac = 'CDS',
    Cutting = 'CTTG',
    Dale = 'DALE',
    Dell = 'DELL',
    Deviation = 'DEVN',
    Dip = 'DIP',
    Distributor = 'DSTR',
    Drive = 'DR',
    Driveway = 'DRWY',
    Edge = 'EDGE',
    Elbow = 'ELB',
    End = 'END',
    Entrance = 'ENT',
    Esplanade = 'ESP',
    Estate = 'EST',
    Expressway = 'EXP',
    Extension = 'EXTN',
    Fairway = 'FAWY',
    FireTrack = 'FTRK',
    FireTrail = 'FITR',
    Flat = 'FLAT',
    Follow = 'FOLW',
    Footway = 'FTWY',
    Foreshore = 'FSHR',
    Formation = 'FORM',
    Freeway = 'FWY',
    Front = 'FRNT',
    Frontage = 'FRTG',
    Gap = 'GAP',
    Garden = 'GDN',
    Gardens = 'GDNS',
    Gate = 'GTE',
    Gates = 'GTES',
    Glade = 'GLD',
    Glen = 'GLEN',
    Grange = 'GRA',
    Green = 'GRN',
    Ground = 'GRND',
    Grove = 'GR',
    Gully = 'GLY',
    Heights = 'HTS',
    Highroad = 'HRD',
    Highway = 'HWY',
    Hill = 'HILL',
    Interchange = 'INTG',
    Intersection = 'INTN',
    Junction = 'JNC',
    Key = 'KEY',
    Landing = 'LDG',
    Lane = 'LANE',
    Laneway = 'LNWY',
    Lees = 'LEES',
    Line = 'LNE',
    Link = 'LINK',
    Little = 'LT',
    Lookout = 'LKT',
    Loop = 'LOOP',
    Lower = 'LWR',
    Mall = 'MALL',
    Meander = 'MNDR',
    Mew = 'MEW',
    Mews = 'MEWS',
    Motorway = 'MWY',
    Mount = 'MT',
    Nook = 'NOOK',
    Outlook = 'OTLK',
    Parade = 'PDE',
    Park = 'PARK',
    Parklands = 'PKLD',
    Parkway = 'PKWY',
    Part = 'PART',
    Pass = 'PASS',
    Path = 'PATH',
    Pathway = 'PHWY',
    Piazza = 'PIAZ',
    Place = 'PL',
    Plateau = 'PLAT',
    Plaza = 'PLZA',
    Pocket = 'PKT',
    Point = 'PNT',
    Port = 'PORT',
    Promenade = 'PROM',
    Quad = 'QUAD',
    Quadrangle = 'QDGL',
    Quadrant = 'QDRT',
    Quay = 'QY',
    Quays = 'QYS',
    Ramble = 'RMBL',
    Ramp = 'RAMP',
    Range = 'RNGE',
    Reach = 'RCH',
    Reserve = 'RES',
    Rest = 'REST',
    Retreat = 'RTT',
    Ride = 'RIDE',
    Ridge = 'RDGE',
    Ridgeway = 'RGWY',
    RightOfWay = 'ROWY',
    Ring = 'RING',
    Rise = 'RISE',
    River = 'RVR',
    Riverway = 'RVWY',
    Riviera = 'RVRA',
    Road = 'RD',
    Roads = 'RDS',
    Roadside = 'RDSD',
    Roadway = 'RDWY',
    Rosebowl = 'RSBL',
    Rotary = 'RTY',
    Round = 'RNDE',
    Route = 'RTE',
    Row = 'ROW',
    Rue = 'RUE',
    Run = 'RUN',
    Slope = 'SLPE',
    Sound = 'SND',
    Spur = 'SPUR',
    Square = 'SQ',
    Stairs = 'STRS',
    StateHighway = 'SHWY',
    Steps = 'STPS',
    Strait = 'STRT',
    Strand = 'STRA',
    Street = 'ST',
    Strip = 'STRP',
    Subway = 'SBWY',
    ServiceWay = 'SWY',
    Siding = 'SDNG',
    Tarn = 'TARN',
    Terrace = 'TCE',
    Thoroughfare = 'THOR',
    Tollway = 'TLWY',
    Top = 'TOP',
    Tor = 'TOR',
    Towers = 'TWRS',
    Track = 'TRK',
    Trail = 'TRL',
    Trailer = 'TRLR',
    Triangle = 'TRI',
    Trunkway = 'TKY',
    Turn = 'TURN',
    Underpass = 'UPAS',
    Upper = 'UPR',
    Vale = 'VALE',
    Viaduct = 'VDCT',
    View = 'VIEW',
    Villas = 'VLLS',
    Vista = 'VSTA',
    Wade = 'WADE',
    Walk = 'WALK',
    Walkway = 'WKWY',
    Way = 'WAY',
    Wharf = 'WHRF',
    Wynd = 'WYND',
    Yard = 'YARD',
}

export default PropertyStreetTypeEnum;
