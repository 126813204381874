import { Button, Space, Spin, Table, Tabs, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import IWarehouseTransaction from '~Api/Warehouse/IWarehouseTransaction';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import {
    warehouseGetAction,
    warehouseTransactionsListAction,
} from '~Warehouses/actions';
import {
    warehouseSelector,
    warehouseTransactionsSelector,
} from '~Warehouses/selectors';
import Layout from './Layout';
import PrincipalModal from './PrincipalModal';
import InterestRateModal from './InterestRateModal';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { currencyFormatter } from '~utilities/formatters';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';

interface IPrincipalTransaction extends IWarehouseTransaction {
    displayAmount: number;
    balance: number;
}

interface IState {
    isInterestRateModalOpen: boolean;
    isPrincipalModalOpen: boolean;
}

interface IMatch {
    warehouseUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    warehouse: IWarehouse;
    warehouseTransactions: IDictionary<IWarehouseTransaction>;
}

interface IPropsDispatch {
    warehouseGet: () => void;
    warehouseTransactionsList: () => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Transactions extends React.Component<Props, IState> {
    public state: IState = {
        isInterestRateModalOpen: false,
        isPrincipalModalOpen: false,
    };

    constructor(props: Props) {
        super(props);

        this.onClickAdjustInterestRate = this.onClickAdjustInterestRate.bind(this);
        this.onCloseAdjustInterestRate = this.onCloseAdjustInterestRate.bind(this);
        this.onClickAdjustPrincipal = this.onClickAdjustPrincipal.bind(this);
        this.onCloseAdjustPrincipal = this.onCloseAdjustPrincipal.bind(this);
    }

    public componentDidMount(): void {
        const { warehouseTransactions } = this.props;

        if (!warehouseTransactions) {
            this.props.warehouseTransactionsList();
        }
    }

    public render(): JSX.Element {
        const { match, warehouse, warehouseTransactions } = this.props;
        const { isInterestRateModalOpen, isPrincipalModalOpen } = this.state;

        if (!warehouse || !warehouseTransactions) {
            return (
                <Layout uuid={match.params.warehouseUuid} section='transactions'>
                    <Typography.Title level={2}>Transactions</Typography.Title>
                    <Spin/>
                </Layout>
            );
        }

        let classAnoteBalance: number = 0;
        let classBnoteBalance: number = 0;
        let classXnoteBalance: number = 0;
        let sellerNoteBalance: number = 0;
        let principalDrawBalance: number = 0;
        let capacityLimitBalance: number = 0;
        let prefundBalance: number = 0;
        let usableFundsBalance: number = 0;
        const classAnoteTransactions: IPrincipalTransaction[] = [];
        const classBnoteTransactions: IPrincipalTransaction[] = [];
        const classXnoteTransactions: IPrincipalTransaction[] = [];
        const sellerNoteTransactions: IPrincipalTransaction[] = [];
        const principalDrawTransactions: IPrincipalTransaction[] = [];
        const capacityLimitTransactions: IPrincipalTransaction[] = [];
        const prefundTransactions: IPrincipalTransaction[] = [];
        const usableFundsTransactions: IPrincipalTransaction[] = [];

        _.each(_.sortBy(warehouseTransactions, ['transactionTime']), (warehouseTransaction: IWarehouseTransaction) => {
            let updateUsableFunds: boolean = false;

            if (warehouseTransaction.aNotes !== null) {
                classAnoteBalance += warehouseTransaction.aNotes;

                classAnoteTransactions.push({
                    ...warehouseTransaction,
                    balance: classAnoteBalance,
                    displayAmount: warehouseTransaction.aNotes,
                });

                updateUsableFunds = true;
            }

            if (warehouseTransaction.amountPrincipal !== null) {
                classBnoteBalance += warehouseTransaction.amountPrincipal;

                classBnoteTransactions.push({
                    ...warehouseTransaction,
                    balance: classBnoteBalance,
                    displayAmount: warehouseTransaction.amountPrincipal,
                });

                updateUsableFunds = true;
            }

            if (warehouseTransaction.sellerNotes !== null) {
                sellerNoteBalance += warehouseTransaction.sellerNotes;

                sellerNoteTransactions.push({
                    ...warehouseTransaction,
                    balance: sellerNoteBalance,
                    displayAmount: warehouseTransaction.sellerNotes,
                });

                updateUsableFunds = true;
            }

            if (warehouseTransaction.principalDraw !== null) {
                principalDrawBalance += warehouseTransaction.principalDraw;

                principalDrawTransactions.push({
                    ...warehouseTransaction,
                    balance: principalDrawBalance,
                    displayAmount: warehouseTransaction.principalDraw,
                });

                updateUsableFunds = true;
            }

            if (warehouseTransaction.capacityLimit !== null) {
                capacityLimitBalance += warehouseTransaction.capacityLimit;

                capacityLimitTransactions.push({
                    ...warehouseTransaction,
                    balance: capacityLimitBalance,
                    displayAmount: warehouseTransaction.capacityLimit,
                });
            }

            if (warehouseTransaction.prefund !== null) {
                prefundBalance += warehouseTransaction.prefund;

                prefundTransactions.push({
                    ...warehouseTransaction,
                    balance: prefundBalance,
                    displayAmount: warehouseTransaction.prefund,
                });
            }

            if (warehouseTransaction.xNotes !== null) {
                classXnoteBalance += warehouseTransaction.xNotes;

                classXnoteTransactions.push({
                    ...warehouseTransaction,
                    balance: classXnoteBalance,
                    displayAmount: warehouseTransaction.xNotes,
                });

                updateUsableFunds = true;
            }

            if (updateUsableFunds) {
                let newUsableFundsBalance: number = classAnoteBalance + classBnoteBalance + sellerNoteBalance;

                if (WarehouseTypeEnum.Fwt1 === warehouse.type) {
                    newUsableFundsBalance -= (classBnoteBalance * 0.01) - principalDrawBalance;
                }

                usableFundsTransactions.push({
                    ...warehouseTransaction,
                    balance: newUsableFundsBalance,
                    displayAmount: newUsableFundsBalance - usableFundsBalance,
                });

                usableFundsBalance = newUsableFundsBalance;
            }
        });

        const principalColumns: ColumnType<IPrincipalTransaction>[] = [
            {
                dataIndex: 'transactionTime',
                defaultSortOrder: 'descend',
                render: (transactionTime: string) => dayjs(transactionTime).format('D/M/YYYY HH:mm'),
                sorter: (a: IWarehouseTransaction, b: IWarehouseTransaction) => {
                    return a.transactionTime > b.transactionTime ? 1 : -1;
                },
                title: 'Date',
                width: '15%',
            },
            {
                dataIndex: 'description',
                title: 'Description',
            },
            {
                dataIndex: 'displayAmount',
                render: (displayAmount: number) => currencyFormatter.format(displayAmount),
                title: 'Amount',
                width: '15%',
            },
            {
                dataIndex: 'balance',
                render: (balance: number) => currencyFormatter.format(balance),
                title: 'Balance',
                width: '15%',
            },
        ];

        const classAnoteBlock: JSX.Element = WarehouseTypeEnum.Fwt2 === warehouse.type && (
            <Tabs.TabPane key='class-a-notes' tab='Class A Notes'>
                <Table
                    columns={principalColumns}
                    dataSource={classAnoteTransactions}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Tabs.TabPane>
        );

        const classXnoteBlock: JSX.Element = WarehouseTypeEnum.Fwt2 === warehouse.type && (
            <Tabs.TabPane key='class-x-notes' tab='Class X Notes'>
                <Table
                    columns={principalColumns}
                    dataSource={classXnoteTransactions}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Tabs.TabPane>
        );

        const prefundBlock: JSX.Element = WarehouseTypeEnum.Fwt1 === warehouse.type && (
            <Tabs.TabPane key='prefund' tab='Prefund'>
                <Table
                    columns={principalColumns}
                    dataSource={prefundTransactions}
                    pagination={false}
                    rowKey='uuid'
                    size='middle'
                />
            </Tabs.TabPane>
        );

        return (
            <Layout uuid={match.params.warehouseUuid} section='transactions'>
                <Space className='actions'>
                    <Button onClick={this.onClickAdjustPrincipal}>Adjust Principal</Button>
                    <Button onClick={this.onClickAdjustInterestRate}>Adjust Interest Rate</Button>
                </Space>
                <Typography.Title level={2}>Transactions</Typography.Title>
                <Tabs defaultActiveKey='usable-funds'>
                    <Tabs.TabPane key='usable-funds' tab='Usable Funds'>
                        <Table
                            columns={principalColumns}
                            dataSource={usableFundsTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    {classAnoteBlock}
                    <Tabs.TabPane key='class-b-notes' tab='Class B Notes'>
                        <Table
                            columns={principalColumns}
                            dataSource={classBnoteTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    {classXnoteBlock}
                    <Tabs.TabPane key='seller-notes' tab='Seller Notes'>
                        <Table
                            columns={principalColumns}
                            dataSource={sellerNoteTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='principal-draw' tab='Principal Draw'>
                        <Table
                            columns={principalColumns}
                            dataSource={principalDrawTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key='capacity-limit' tab='Capacity Limit'>
                        <Table
                            columns={principalColumns}
                            dataSource={capacityLimitTransactions}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </Tabs.TabPane>
                    {prefundBlock}
                </Tabs>
                <InterestRateModal
                    isOpen={isInterestRateModalOpen}
                    onCancel={this.onCloseAdjustInterestRate}
                    warehouseUuid={match.params.warehouseUuid}
                />
                <PrincipalModal
                    isOpen={isPrincipalModalOpen}
                    onCancel={this.onCloseAdjustPrincipal}
                    warehouseUuid={match.params.warehouseUuid}
                />
            </Layout>
        );
    }

    private onClickAdjustInterestRate(): void {
        this.setState({
            isInterestRateModalOpen: true,
        });
    }

    private onCloseAdjustInterestRate(): void {
        this.setState({
            isInterestRateModalOpen: false,
        });
    }

    private onClickAdjustPrincipal(): void {
        this.setState({
            isPrincipalModalOpen: true,
        });
    }

    private onCloseAdjustPrincipal(): void {
        this.setState({
            isPrincipalModalOpen: false,
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        warehouse: warehouseSelector(state, ownProps.match.params.warehouseUuid),
        warehouseTransactions: warehouseTransactionsSelector(state, ownProps.match.params.warehouseUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        warehouseGet: () => dispatch(warehouseGetAction(ownProps.match.params.warehouseUuid)),
        warehouseTransactionsList: () => dispatch(warehouseTransactionsListAction(ownProps.match.params.warehouseUuid)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Transactions);
