import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Form, Input, MenuProps, Select, Space, Spin, Tabs, Typography } from 'antd';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import BrokerCommissionFormatEnum from '~Api/Application/BrokerCommissionFormatEnum';
import CodeTypeEnum from '~Api/Application/CodeTypeEnum';
import DisbursalTypeEnum from '~Api/Application/DisbursalTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import InterestPaymentMethodEnum from '~Api/Application/InterestPaymentMethodEnum';
import LoanPurposeEnum from '~Api/Application/LoanPurposeEnum';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import PartnerCommissionFormatEnum from '~Api/Application/PartnerCommissionFormatEnum';
import RepaymentStrategyEnum from '~Api/Application/RepaymentStrategyEnum';
import BankBranchStateEnum from '~Api/Deal/BankBranchStateEnum';
import {
    applicationBorrowersListAction,
    applicationDealValueSetAction,
    applicationGetAction,
    applicationValueSetAction,
} from '~Applications/actions';
import {
    applicationBorrowersSelector,
    applicationSelector,
} from '~Applications/selectors';
import { IGlobalState } from '~reducer';
import { solicitorsListAction } from '~Solicitors/actions';
import ISolicitor from '~Solicitors/ISolicitor';
import { solicitorsSelector } from '~Solicitors/selectors';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import DischargeInterestTypeEnum from '~Api/Application/DischargeInterestTypeEnum';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';

const disbursalTypeLabels: IDictionary<string> = {
    [DisbursalTypeEnum.Drawdown]: 'Drawdown',
    [DisbursalTypeEnum.Full]: 'Full',
};

const loanPurposeLabels: IDictionary<string> = {
    [LoanPurposeEnum.BridgingLoan]: 'Bridging loan',
    [LoanPurposeEnum.Refinance]: 'Refinance an existing property',
    [LoanPurposeEnum.BusinessLoan]: 'Business loan',
    [LoanPurposeEnum.PersonalLoan]: 'Personal loan',
    [LoanPurposeEnum.RenovateOrBuild]: 'Renovate or build',
    [LoanPurposeEnum.PurchaseNew]: 'Purchase a new property',
    [LoanPurposeEnum.DebtConsolidation]: 'Debt consolidation',
    [LoanPurposeEnum.DevelopmentLoan]: 'Development loan',
};

export default function Edit(): ReactElement {
    const { applicationUuid } = useParams<{ applicationUuid: string }>();

    const application: IApplication = useSelector((state: IGlobalState) => applicationSelector(state, applicationUuid));
    const borrowers: IBorrower[] = useSelector((state: IGlobalState) => applicationBorrowersSelector(state, applicationUuid));
    const solicitors: IDictionary<ISolicitor> = useSelector((state: IGlobalState) => solicitorsSelector(state));

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!application) {
            dispatch(applicationGetAction(applicationUuid));
        }
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    useEffect(() => {
        if (!borrowers) {
            dispatch(applicationBorrowersListAction(applicationUuid));
        }
    }, [
        applicationUuid,
        borrowers,
        dispatch,
    ]);

    useEffect(() => {
        if (!solicitors) {
            dispatch(solicitorsListAction());
        }
    }, [
        solicitors,
        dispatch,
    ]);

    const onChangeBrokerCommissionFormat: () => void = useCallback(() => {
        const brokerCommissionFormat: BrokerCommissionFormatEnum = application.brokerCommissionFormat === BrokerCommissionFormatEnum.Dollars ? BrokerCommissionFormatEnum.Percentage : BrokerCommissionFormatEnum.Dollars;

        dispatch(applicationValueSetAction(applicationUuid, 'brokerCommissionFormat', brokerCommissionFormat));
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    const onChangePartnerCommissionFormat: () => void = useCallback(() => {
        const partnerCommissionFormat: PartnerCommissionFormatEnum = application.partnerCommissionFormat === PartnerCommissionFormatEnum.Dollars ? PartnerCommissionFormatEnum.Percentage : PartnerCommissionFormatEnum.Dollars;

        dispatch(applicationValueSetAction(applicationUuid, 'partnerCommissionFormat', partnerCommissionFormat));
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    const onChangeCodeType: (value: CodeTypeEnum) => void = useCallback((value: CodeTypeEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'codeType', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeDischargeInterestType: (value: DischargeInterestTypeEnum) => void = useCallback((value: DischargeInterestTypeEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'dischargeInterestType', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeMortgageType: (value: MortgageTypeEnum) => void = useCallback((value: MortgageTypeEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'mortgageType', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeLoanPurpose: (value: LoanPurposeEnum) => void = useCallback((value: LoanPurposeEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'loanPurpose', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeRepaymentStrategy: (value: RepaymentStrategyEnum) => void = useCallback((value: RepaymentStrategyEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'repaymentStrategy', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeSecondaryRepaymentStrategy: (value: RepaymentStrategyEnum) => void = useCallback((value: RepaymentStrategyEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'secondaryRepaymentStrategy', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeInterestPaymentMethod: (value: InterestPaymentMethodEnum) => void = useCallback((value: InterestPaymentMethodEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'interestPaymentMethod', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangePrimaryBorrowerUuid: (value: string) => void = useCallback((value: string) => {
        dispatch(applicationValueSetAction(applicationUuid, 'primaryBorrowerUuid', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeTermMonthsMinimum: (value: number) => void = useCallback((value: number) => {
        dispatch(applicationValueSetAction(applicationUuid, 'termMonthsMinimum', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeDisbursalType: (value: DisbursalTypeEnum) => void = useCallback((value: DisbursalTypeEnum) => {
        dispatch(applicationValueSetAction(applicationUuid, 'disbursalType', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeBrokerCommissionDollars: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const brokerCommissionDollars: number = event.target.value ? event.target.valueAsNumber : null;

        dispatch(applicationValueSetAction(applicationUuid, 'brokerCommissionDollars', brokerCommissionDollars));
        dispatch(applicationValueSetAction(applicationUuid, 'brokerCommissionPercentage', ((brokerCommissionDollars / application.loanAmount) * 100).toFixed(2)));
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    const onChangeBrokerCommissionPercentage: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const brokerCommissionPercentage: number = event.target.value ? event.target.valueAsNumber : null;

        dispatch(applicationValueSetAction(applicationUuid, 'brokerCommissionPercentage', brokerCommissionPercentage));
        dispatch(applicationValueSetAction(applicationUuid, 'brokerCommissionDollars', Math.ceil((brokerCommissionPercentage / 100) * application.loanAmount)));
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    const onChangePartnerCommissionDollars: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const partnerCommissionDollars: number = event.target.value ? event.target.valueAsNumber : null;

        dispatch(applicationValueSetAction(applicationUuid, 'partnerCommissionDollars', partnerCommissionDollars));
        dispatch(applicationValueSetAction(applicationUuid, 'partnerCommissionPercentage', ((partnerCommissionDollars / application.loanAmount) * 100).toFixed(2)));
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    const onChangePartnerCommissionPercentage: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const partnerCommissionPercentage: number = event.target.value ? event.target.valueAsNumber : null;

        dispatch(applicationValueSetAction(applicationUuid, 'partnerCommissionPercentage', partnerCommissionPercentage));
        dispatch(applicationValueSetAction(applicationUuid, 'partnerCommissionDollars', Math.ceil((partnerCommissionPercentage / 100) * application.loanAmount)));
    }, [
        application,
        applicationUuid,
        dispatch,
    ]);

    const onChangeBankName: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'bankName', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeBankBsb: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'bankBsb', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeBankAccountName: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'bankAccountName', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeBankAccountNumber: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'bankAccountNumber', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeBankBranchAddress: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'bankBranchAddress', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeBankBranchState: (value: BankBranchStateEnum) => void = useCallback((value: BankBranchStateEnum) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'bankBranchState', value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeBankBranchPostcode: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'bankBranchPostcode', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeSolicitorFirmName: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'solicitorFirmName', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeSolicitorName: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'solicitorName', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeSolicitorMobile: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'solicitorMobile', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeSolicitorPhone: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'solicitorPhone', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeSolicitorEmail: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'solicitorEmail', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeAccountantFirmName: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'accountantFirmName', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeAccountantName: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'accountantName', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeAccountantPhone: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'accountantPhone', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeAccountantEmail: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(applicationDealValueSetAction(applicationUuid, 'accountantEmail', event.target.value));
    }, [
        applicationUuid,
        dispatch,
    ]);

    const onChangeApplicationSolicitor: (value: string) => void = useCallback((value: string) => {
        dispatch(applicationValueSetAction(applicationUuid, 'solicitorUuid', value || null));
    }, [
        applicationUuid,
        dispatch,
    ]);

    if (!application || !application.deal || !borrowers || !solicitors) {
        return (
            <Layout applicationUuid={applicationUuid} section='edit'>
                <Spin />
            </Layout>
        );
    }

    const brokerCommissionFormat: BrokerCommissionFormatEnum = application.brokerCommissionFormat || BrokerCommissionFormatEnum.Percentage;

    const brokerCommissionMenu: MenuProps = {
        items: [
            {
                key: 'broker-commission-format',
                label: brokerCommissionFormat === BrokerCommissionFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                onClick: onChangeBrokerCommissionFormat,
            },
        ],
    };

    const brokerCommissionLabel: JSX.Element = (
        <Dropdown menu={brokerCommissionMenu}>
            <a>Broker Commission<DownOutlined /></a>
        </Dropdown>
    );

    const partnerCommissionFormat: PartnerCommissionFormatEnum = application.partnerCommissionFormat || PartnerCommissionFormatEnum.Percentage;

    const partnerCommissionMenu: MenuProps = {
        items: [
            {
                key: 'partner-commission-format',
                label: partnerCommissionFormat === PartnerCommissionFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                onClick: onChangePartnerCommissionFormat,
            },
        ],
    };

    const partnerCommissionLabel: JSX.Element = (
        <Dropdown menu={partnerCommissionMenu}>
            <a>Partner Commission<DownOutlined /></a>
        </Dropdown>
    );

    const termMonthsMinimumBlock: JSX.Element = application.dischargeInterestType === DischargeInterestTypeEnum.MinimumTerm && (
        <Form.Item label='Minimum Term' className='term-months-minimum'>
            <Select onChange={onChangeTermMonthsMinimum} value={application.termMonthsMinimum}>
                <Select.Option key='none' value={null}>Loan Term</Select.Option>
                {_.times(37, (months: number) => (<Select.Option key={months} value={months}>{months} months</Select.Option>))}
            </Select>
        </Form.Item>
    );

    const dischargeInterestTypeBlock: JSX.Element = application.codeType === CodeTypeEnum.Code && (
        <Form.Item label='Discharge Interest Type' className='discharge-interest-type'>
            <Select onChange={onChangeDischargeInterestType} value={application.dischargeInterestType}>
                <Select.Option value={DischargeInterestTypeEnum.BreakCost}>Break Cost</Select.Option>
                <Select.Option value={DischargeInterestTypeEnum.MinimumTerm}>Minimum Term</Select.Option>
            </Select>
        </Form.Item>
    );

    return (
        <Layout applicationUuid={applicationUuid} section='edit'>
            <Typography.Title level={2}>Edit</Typography.Title>
            <Tabs defaultActiveKey='general'>
                <Tabs.TabPane key='general' tab='General'>
                    <Form.Item label='Loan Type' className='code-type'>
                        <Select onChange={onChangeCodeType} value={application.codeType}>
                            <Select.Option value={CodeTypeEnum.Code}>NCCP Regulated</Select.Option>
                            <Select.Option value={CodeTypeEnum.NonCode}>Non NCCP Regulated</Select.Option>
                        </Select>
                    </Form.Item>
                    {dischargeInterestTypeBlock}
                    <Form.Item label='Mortgage Type' className='mortgage-type'>
                        <Select onChange={onChangeMortgageType} value={application.mortgageType}>
                            <Select.Option value={MortgageTypeEnum.FirstMortgage}>First Mortgage</Select.Option>
                            <Select.Option value={MortgageTypeEnum.SecondMortgage}>Second Mortgage</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Loan Purpose' className='loan-purpose'>
                        <Select onChange={onChangeLoanPurpose} value={application.loanPurpose}>
                            {_.map(loanPurposeLabels, (loanPurposeLabel: string, key: LoanPurposeEnum) => <Select.Option value={key}>{loanPurposeLabel}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Repayment Strategy' className='repayment-strategy'>
                        <Select onChange={onChangeRepaymentStrategy} value={application.repaymentStrategy}>
                            <Select.Option value={RepaymentStrategyEnum.PropertySaleSold}>Sale of Property - Already Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.PropertySaleNotSold}>Sale of Property - Not Yet Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.RefinanceApproved}>Refinance - Already Approved</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.RefinanceNotApproved}>Refinance - Not Yet Approved</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.BusinessSaleSold}>Sale of Business - Already Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.BusinessSaleNotSold}>Sale of Business - Not Yet Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.BusinessCashFlow}>Business Cash Flow</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.Inheritance}>Inheritance</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.CourtSettlement}>Court Settlement / Compensation</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.Other}>Other</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Secondary Repayment Strategy' className='secondary-repayment-strategy'>
                        <Select onChange={onChangeSecondaryRepaymentStrategy} value={application.secondaryRepaymentStrategy}>
                            <Select.Option value={RepaymentStrategyEnum.PropertySaleSold}>Sale of Property - Already Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.PropertySaleNotSold}>Sale of Property - Not Yet Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.RefinanceApproved}>Refinance - Already Approved</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.RefinanceNotApproved}>Refinance - Not Yet Approved</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.BusinessSaleSold}>Sale of Business - Already Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.BusinessSaleNotSold}>Sale of Business - Not Yet Sold</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.BusinessCashFlow}>Business Cash Flow</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.Inheritance}>Inheritance</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.CourtSettlement}>Court Settlement / Compensation</Select.Option>
                            <Select.Option value={RepaymentStrategyEnum.Other}>Other</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Interest Payment Method' className='interest-payment-method'>
                        <Select onChange={onChangeInterestPaymentMethod} value={application.interestPaymentMethod}>
                            <Select.Option value={InterestPaymentMethodEnum.Monthly}>Monthly</Select.Option>
                            <Select.Option value={InterestPaymentMethodEnum.Prepaid}>Prepaid</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item className='primary-borrower' label='Primary Borrower'>
                        <Select onChange={onChangePrimaryBorrowerUuid} value={application.primaryBorrowerUuid}>
                            {borrowers.map((borrower: IBorrower) => (<Select.Option key={borrower.uuid} value={borrower.uuid}>{borrower.dealBorrower.formattedName}</Select.Option>))}
                        </Select>
                    </Form.Item>
                    {termMonthsMinimumBlock}
                    <Form.Item label='Disbursal Type' className='disbursal-type'>
                        <Select onChange={onChangeDisbursalType} value={application.disbursalType}>
                            {_.keys(disbursalTypeLabels).map((disbursalType: string) => <Select.Option key={disbursalType} value={disbursalType}>{disbursalTypeLabels[disbursalType]}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item className='broker-commission' label={brokerCommissionLabel}>
                        {brokerCommissionFormat === BrokerCommissionFormatEnum.Dollars && <Space><Input addonBefore='$' onChange={onChangeBrokerCommissionDollars} type='number' max={application.loanAmount} value={application.brokerCommissionDollars} /><span>({percentageFormatter.format(application.brokerCommissionDollars / application.loanAmount)})</span></Space>}
                        {brokerCommissionFormat === BrokerCommissionFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={onChangeBrokerCommissionPercentage} step={0.1} type='number' max={100} value={application.brokerCommissionPercentage} /><span>({currencyFormatter.format(Math.ceil((application.brokerCommissionPercentage / 100) * application.loanAmount))})</span></Space>}
                    </Form.Item>
                    <Form.Item className='partner-commission' label={partnerCommissionLabel}>
                        {partnerCommissionFormat === PartnerCommissionFormatEnum.Dollars && <Space><Input addonBefore='$' onChange={onChangePartnerCommissionDollars} type='number' max={application.loanAmount} value={application.partnerCommissionDollars} /><span>({percentageFormatter.format(application.partnerCommissionDollars / application.loanAmount)})</span></Space>}
                        {partnerCommissionFormat === PartnerCommissionFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={onChangePartnerCommissionPercentage} step={0.1} type='number' max={100} value={application.partnerCommissionPercentage} /><span>({currencyFormatter.format(Math.ceil((application.partnerCommissionPercentage / 100) * application.loanAmount))})</span></Space>}
                    </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane key='bank-account' tab='Bank Account'>
                    <Form.Item label='Bank Name' className='bank-name'>
                        <Input maxLength={50} onChange={onChangeBankName} value={application.deal.bankName} />
                    </Form.Item>
                    <Form.Item label='BSB' className='bank-bsb'>
                        <Input maxLength={6} onChange={onChangeBankBsb} value={application.deal.bankBsb} />
                    </Form.Item>
                    <Form.Item label='Account Name' className='bank-account-name'>
                        <Input maxLength={255} onChange={onChangeBankAccountName} value={application.deal.bankAccountName} />
                    </Form.Item>
                    <Form.Item label='Account Number' className='bank-account-number'>
                        <Input maxLength={20} onChange={onChangeBankAccountNumber} value={application.deal.bankAccountNumber} />
                    </Form.Item>
                    <Form.Item label='Branch Address' className='bank-branch-address'>
                        <Input maxLength={255} onChange={onChangeBankBranchAddress} value={application.deal.bankBranchAddress} />
                    </Form.Item>
                    <Form.Item label='Branch State' className='bank-branch-state'>
                        <Select onChange={onChangeBankBranchState} value={application.deal.bankBranchState}>
                            <Select.Option value={BankBranchStateEnum.AustralianCapitalTerritory}>ACT</Select.Option>
                            <Select.Option value={BankBranchStateEnum.NewSouthWales}>NSW</Select.Option>
                            <Select.Option value={BankBranchStateEnum.NorthernTerritory}>NT</Select.Option>
                            <Select.Option value={BankBranchStateEnum.Queensland}>QLD</Select.Option>
                            <Select.Option value={BankBranchStateEnum.SouthAustralia}>SA</Select.Option>
                            <Select.Option value={BankBranchStateEnum.Tasmania}>TAS</Select.Option>
                            <Select.Option value={BankBranchStateEnum.Victoria}>VIC</Select.Option>
                            <Select.Option value={BankBranchStateEnum.WesternAustralia}>WA</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Branch Postcode' className='bank-branch-postcode'>
                        <Input maxLength={4} onChange={onChangeBankBranchPostcode} value={application.deal.bankBranchPostcode} />
                    </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane key='solicitor' tab='Borrower Solicitor'>
                    <Form.Item label='Firm Name' className='solicitor-firm-name'>
                        <Input maxLength={255} onChange={onChangeSolicitorFirmName} value={application.deal.solicitorFirmName} />
                    </Form.Item>
                    <Form.Item label='Solicitor' className='solicitor-name'>
                        <Input maxLength={255} onChange={onChangeSolicitorName} value={application.deal.solicitorName} />
                    </Form.Item>
                    <Form.Item label='Mobile' className='solicitor-mobile'>
                        <Input maxLength={20} onChange={onChangeSolicitorMobile} value={application.deal.solicitorMobile} />
                    </Form.Item>
                    <Form.Item label='Phone' className='solicitor-phone'>
                        <Input maxLength={20} onChange={onChangeSolicitorPhone} value={application.deal.solicitorPhone} />
                    </Form.Item>
                    <Form.Item label='Email' className='solicitor-email'>
                        <Input maxLength={255} onChange={onChangeSolicitorEmail} value={application.deal.solicitorEmail} />
                    </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane key='accountant' tab='Accountant'>
                    <Form.Item label='Firm Name' className='accountant-firm-name'>
                        <Input maxLength={255} onChange={onChangeAccountantFirmName} value={application.deal.accountantFirmName} />
                    </Form.Item>
                    <Form.Item label='Accountant' className='accountant-name'>
                        <Input maxLength={255} onChange={onChangeAccountantName} value={application.deal.accountantName} />
                    </Form.Item>
                    <Form.Item label='Phone' className='accountant-phone'>
                        <Input maxLength={20} onChange={onChangeAccountantPhone} value={application.deal.accountantPhone} />
                    </Form.Item>
                    <Form.Item label='Email' className='accountant-email'>
                        <Input maxLength={255} onChange={onChangeAccountantEmail} value={application.deal.accountantEmail} />
                    </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane key='application-solicitor' tab='Application Solicitor'>
                    <Form.Item label='Application Solicitor' className='application-solicitor'>
                        <Select onChange={onChangeApplicationSolicitor} value={application.solicitorUuid} allowClear={true}>
                            {_.map(solicitors, (loopSolicitor: ISolicitor) => <Select.Option value={loopSolicitor.uuid}>{loopSolicitor.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Tabs.TabPane>
            </Tabs>
        </Layout>
    );
}