enum LicenceStateEnum {
    AustralianCapitalTerritory = 'ACT',
    NewSouthWales = 'NSW',
    NorthernTerritory = 'NT',
    Queensland = 'QLD',
    SouthAustralia = 'SA',
    Tasmania = 'TAS',
    Victoria = 'VIC',
    WesternAustralia = 'WA',
}

export default LicenceStateEnum;
