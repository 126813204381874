import _ from 'lodash';
import ActiveStatusEnum from './ActiveStatusEnum';
import IAdministrator from './IAdministrator';
import IHistory from './IHistory';
import PermissionsEnum from './PermissionsEnum';
import IPasskey from './IPasskey';
import PasskeyStatusEnum from './PasskeyStatusEnum';

const legacyPermissionsMap: { [legacyPermission: string]: PermissionsEnum[] } = {
    accounts: [PermissionsEnum.Accounts],
    admin: [PermissionsEnum.Administrators, PermissionsEnum.Users],
    applications: [PermissionsEnum.Applications],
    brokers: [PermissionsEnum.Brokers],
    [PermissionsEnum.BrokerReport]: [PermissionsEnum.ReportsBrokersExport],
    investments: [PermissionsEnum.Investments],
    investors: [PermissionsEnum.Investors, PermissionsEnum.Users],
    leads: [PermissionsEnum.Leads],
    loans: [PermissionsEnum.Loans],
    [PermissionsEnum.LoanPoolCutReport]: [PermissionsEnum.ReportsLoansPoolCut],
    reports: [PermissionsEnum.Reports],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAdministrator(raw: any): IAdministrator {
    return {
        activePasskeyCount: raw.activePasskeyCount,
        leadsAutoAssign: raw.leadsAutoAssign,
        dealMaximumValue: raw.dealMaximumValue,
        dealMinimumValue: raw.dealMinimumValue,
        email: raw.email,
        magicLinkRequestedTime: raw.magicLinkRequestedTime,
        name: raw.name,
        permissions: parsePermissions(raw.permissions),
        photoUrl: raw.photoUrl,
        role: raw.role,
        status: raw.active ? ActiveStatusEnum.Active : ActiveStatusEnum.Inactive,
        title: raw.title,
        uuid: raw.uuid,
    };
}

export function parsePermissions(rawPermissions: string[]): PermissionsEnum[] {
    const permissions: PermissionsEnum[] = [];

    _.each(rawPermissions, (rawPermission: string) => {
        if (legacyPermissionsMap[rawPermission]) {
            _.each(legacyPermissionsMap[rawPermission], (permission: PermissionsEnum) => permissions.push(permission));

            return;
        }

        permissions.push(rawPermission as PermissionsEnum);
    });

    return permissions;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseAdministratorHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parsePasskey(raw: any): IPasskey {
    return {
        createdTime: raw.createdTime,
        lastUsedTime: raw.lastUsedTime,
        status: raw.status as PasskeyStatusEnum,
        uuid: raw.uuid,
    };
}
