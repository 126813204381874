import { Button, Dropdown, MenuProps, Modal, Popover, Space, Spin, Table, Typography } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import { IGlobalState } from '~reducer';
import {
    warehouseProposedPurchaseParametersListAction,
    warehouseProposedSaleCancelAction,
    warehouseProposedSaleProcessAction,
    warehouseProposedSalesPendingListAction,
    warehousesListAction,
} from '~Warehouses/actions';
import {
    warehouseProposedPurchaseParametersSelector,
    warehouseProposedSalesPendingSelector,
    warehouseSelector,
    warehousesSelector,
} from '~Warehouses/selectors';
import Layout from './Layout';
import { Dispatch } from 'redux';
import IWarehouseProposedSale from '~Api/Warehouse/IWarehouseProposedSale';
import { IDictionary } from '~utilities/IDictionary';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseProposedSaleDestination from '~Api/Warehouse/IWarehouseProposedSaleDestination';
import _ from 'lodash';
import { currencyFormatter } from '~utilities/formatters';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import { authCurrentUserSelector, authTokenSelector } from '~Auth/selectors';
import './warehouse.less';
import IWarehouseParameter from '~Api/Warehouse/IWarehouseParameter';
import Parameters from './Parameters';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import IAuthUser from '~Auth/IAuthUser';
import { WarningOutlined } from '@ant-design/icons';

interface ISourceProposedSale {
    primaryWarehouseProposedSaleDestinationUuid: string;
    saleTotal: number;
    sourceWarehouseLoan: IWarehouseLoan;
    sourceWarehouseUuid: string;
    transactionTime: string;
    uuid: string;
}

export default function ProposedPurchases(): ReactElement {
    const { warehouseUuid } = useParams<{warehouseUuid: string}>();

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const parameters: IWarehouseParameter[] = useSelector((state: IGlobalState) => warehouseProposedPurchaseParametersSelector(state, warehouseUuid));
    const token: string = useSelector(authTokenSelector);
    const warehouse: IWarehouse = useSelector((state: IGlobalState) => warehouseSelector(state, warehouseUuid));
    const warehouses: IDictionary<IWarehouse> = useSelector(warehousesSelector);
    const warehouseProposedSales: IDictionary<IWarehouseProposedSale> = useSelector(warehouseProposedSalesPendingSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!parameters) {
            dispatch(warehouseProposedPurchaseParametersListAction(warehouseUuid));
        }
    }, [
        dispatch,
        parameters,
        warehouseUuid,
    ]);

    useEffect(() => {
        if (!warehouses) {
            dispatch(warehousesListAction());
        }
    }, [
        dispatch,
        warehouses,
    ]);

    useEffect(() => {
        if (!warehouseProposedSales) {
            dispatch(warehouseProposedSalesPendingListAction());
        }
    }, [
        dispatch,
        warehouseProposedSales,
    ]);

    if (!parameters || !warehouseProposedSales || !warehouses) {
        return (
            <Layout uuid={warehouseUuid} section='proposed-purchases'>
                <Typography.Title level={2}>Proposed Purchases</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    const sourceProposedSales: IDictionary<ISourceProposedSale> = {};
    const warehouseProposedSaleDestinations: IDictionary<IWarehouseProposedSaleDestination> = {};

    _.each(_.sortBy(warehouseProposedSales, ['transactionTime']), (warehouseProposedSale: IWarehouseProposedSale) => {
        _.each(warehouseProposedSale.warehouseProposedSaleDestinations, (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
            if (warehouseProposedSaleDestination.warehouseUuid !== warehouse.uuid) {
                return;
            }

            warehouseProposedSaleDestinations[warehouseProposedSaleDestination.uuid] = warehouseProposedSaleDestination;

            let sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

            if (!sourceProposedSale) {
                sourceProposedSale = {
                    primaryWarehouseProposedSaleDestinationUuid: warehouseProposedSaleDestination.uuid,
                    saleTotal: warehouseProposedSaleDestination.amount,
                    sourceWarehouseLoan: warehouseProposedSale.sourceWarehouseLoan,
                    sourceWarehouseUuid: warehouseProposedSale.sourceWarehouseLoan.warehouseUuid,
                    transactionTime: warehouseProposedSale.transactionTime,
                    uuid: warehouseProposedSale.uuid,
                };
            } else {
                sourceProposedSale.saleTotal += warehouseProposedSaleDestination.amount;
            }

            sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid] = sourceProposedSale;
        });
    });

    const columns: ColumnsType<IWarehouseProposedSaleDestination> = [
        {
            className: 'date',
            defaultSortOrder: 'ascend',
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return dayjs(sourceProposedSale.transactionTime).format('D/M/YY');
            },
            sorter: (a: IWarehouseProposedSaleDestination, b: IWarehouseProposedSaleDestination) => {
                const aSale: ISourceProposedSale = sourceProposedSales[a.warehouseProposedSaleUuid];
                const bSale: ISourceProposedSale = sourceProposedSales[b.warehouseProposedSaleUuid];

                return aSale.transactionTime > bSale.transactionTime ? 1 : -1;
            },
            title: 'Date',
            width: '10%',
        },
        {
            className: 'loan',
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return (
                    <Link to={`/warehouses/${sourceProposedSale.sourceWarehouseUuid}/loans/${sourceProposedSale.sourceWarehouseLoan.uuid}`}>
                        {sourceProposedSale.sourceWarehouseLoan.loan.code}
                    </Link>
                );
            },
            sorter: (a: IWarehouseProposedSaleDestination, b: IWarehouseProposedSaleDestination) => {
                const aSale: ISourceProposedSale = sourceProposedSales[a.warehouseProposedSaleUuid];
                const bSale: ISourceProposedSale = sourceProposedSales[b.warehouseProposedSaleUuid];

                return aSale.sourceWarehouseLoan.loan.code.localeCompare(bSale.sourceWarehouseLoan.loan.code);
            },
            title: 'Loan',
        },
        {
            className: 'source-warehouse',
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                return (
                    <Link to={`/warehouses/${sourceProposedSale.sourceWarehouseUuid}`}>
                        {warehouses[sourceProposedSale.sourceWarehouseUuid].name}
                    </Link>
                );
            },
            title: 'Source Warehouse',
            width: '20%',
        },
        {
            className: 'amount',
            dataIndex: 'amount',
            render: (amount: number, warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                let warning: string;

                if (0.0 === sourceProposedSale.sourceWarehouseLoan.loan.amountRemaining) {
                    warning = 'Loan has been discharged';
                } else if ((sourceProposedSale.sourceWarehouseLoan.balancePrincipal * -1) < (sourceProposedSale.saleTotal * -1)) {
                    warning = 'Source warehouse principal balance is insufficient for sale amount';
                } else if ([WarehouseTypeEnum.Fwt1, WarehouseTypeEnum.Fwt2].includes(warehouse.type) && sourceProposedSale.sourceWarehouseLoan.loan.amountRemaining !== (amount * -1)) {
                    warning = 'Sale is not for the full loan amount';
                }

                if (warning) {
                    return (
                        <Popover content={warning}>
                            <Space>
                                {currencyFormatter.format(amount * -1)}
                                <WarningOutlined/>
                            </Space>
                        </Popover>
                    );
                }

                return currencyFormatter.format(amount * -1);
            },
            sorter: (a: IWarehouseProposedSaleDestination, b: IWarehouseProposedSaleDestination) => {
                const aSale: ISourceProposedSale = sourceProposedSales[a.warehouseProposedSaleUuid];
                const bSale: ISourceProposedSale = sourceProposedSales[b.warehouseProposedSaleUuid];

                return aSale.saleTotal > bSale.saleTotal ? 1 : -1;
            },
            title: 'Amount',
            width: '15%',
        },
        {
            className: 'proposed-actions',
            render: (warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
                const sourceProposedSale: ISourceProposedSale = sourceProposedSales[warehouseProposedSaleDestination.warehouseProposedSaleUuid];

                const onClickCancel: () => void = () => {
                    dispatch(warehouseProposedSaleCancelAction(sourceProposedSale.uuid));
                };

                if (0.0 === sourceProposedSale.sourceWarehouseLoan.loan.amountRemaining) {
                    return (
                        <Button danger={true} onClick={onClickCancel}>
                            Cancel
                        </Button>
                    );
                }

                const onClickProcess: () => void = () => {
                    Modal.confirm({
                        content: 'Are you sure you want to process this proposed sale?',
                        okText: 'Confirm',
                        onOk: () => {
                            dispatch(warehouseProposedSaleProcessAction(sourceProposedSale.uuid));
                        },
                        title: 'Process Proposed Sale',
                    });
                };

                const actionsMenu: MenuProps = {
                    items: [
                        ...(warehouses[warehouseProposedSaleDestination.warehouseUuid].type === WarehouseTypeEnum.Fwt1 ? [
                            {
                                key: 'download-offer-to-sell',
                                label: (
                                    <a href={`${process.env.API_HOST}/warehouse-proposed-sales/${sourceProposedSale.uuid}/download-offer-to-sell?token=${token}`} rel='noreferrer' target='_blank'>
                                        Download Offer To Sell
                                    </a>
                                ),
                            },
                        ] : []),
                        {
                            key: 'cancel',
                            label: 'Cancel',
                            onClick: onClickCancel,
                        },
                    ],
                };

                const isDisabled: boolean = dayjs(sourceProposedSale.transactionTime).isSameOrAfter(dayjs().endOf('d'));

                return (
                    <Space>
                        <Button type='primary' disabled={isDisabled} onClick={onClickProcess}>
                            Process
                        </Button>
                        <Dropdown.Button menu={actionsMenu} className='secondary-proposed-actions'/>
                    </Space>
                );
            },
            width: '10%',
        },
    ];

    function summary(): JSX.Element {
        const totalPrincipal: number = _.reduce(warehouseProposedSaleDestinations, (sum: number, warehouseProposedSaleDestination: IWarehouseProposedSaleDestination) => {
            return sum + (warehouseProposedSaleDestination.amount * -1);
        }, 0);

        return (
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{_.size(warehouseProposedSaleDestinations)}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>&nbsp;</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{currencyFormatter.format(totalPrincipal)}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>&nbsp;</Table.Summary.Cell>
            </Table.Summary.Row>
        );
    }

    const downloadBlock: JSX.Element = WarehouseTypeEnum.Fwt2 === warehouse.type && currentUser.permissions.includes(PermissionsEnum.ReportsWarehouseFwt2PoolCut) && (
        <Button
            href={`${process.env.API_HOST}/warehouse-reports/download-fwt2-proposed-sales-report?token=${token}`}
            target='_blank'
        >
            Download Report
        </Button>
    );

    return (
        <Layout uuid={warehouseUuid} section='proposed-purchases'>
            <Space className='actions'>
                {downloadBlock}
            </Space>
            <Typography.Title level={2}>Proposed Purchases</Typography.Title>
            <Table
                columns={columns}
                dataSource={_.values(warehouseProposedSaleDestinations)}
                pagination={false}
                rowKey='uuid'
                size='middle'
                summary={summary}
            />
            <Typography.Title level={3}>Parameters</Typography.Title>
            <Parameters parameters={parameters} />
        </Layout>
    );
}
