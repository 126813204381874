import IDeal from './IDeal';
import IDealBorrower from './IDealBorrower';
import INote from './INote';
import IProperty from './IProperty';
import IQuote from './IQuote';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformDeal(deal: IDeal): any {
    return {
        accountantEmail: deal.accountantEmail,
        accountantFirmName: deal.accountantFirmName,
        accountantName: deal.accountantName,
        accountantPhone: deal.accountantPhone,
        bankAccountName: deal.bankAccountName,
        bankAccountNumber: deal.bankAccountNumber,
        bankBranchAddress: deal.bankBranchAddress,
        bankBranchPostcode: deal.bankBranchPostcode,
        bankBranchState: deal.bankBranchState,
        bankBsb: deal.bankBsb,
        bankName: deal.bankName,
        bdmUuid: deal.bdmUuid,
        brokerUuid: deal.brokerUuid,
        comments: deal.comments,
        email: deal.email,
        firstName: deal.firstName,
        isBroker: deal.isBroker,
        isReferralPartner: deal.isReferralPartner,
        lastName: deal.lastName,
        loanAmount: deal.loanAmount,
        loanPurpose: deal.loanPurpose,
        mortgageType: deal.mortgageType,
        otherSource: deal.otherSource,
        phone: deal.phone,
        phoneSource: deal.phoneSource,
        referralPartnerCompanyName: deal.referralPartnerCompanyName,
        referralPartnerEmail: deal.referralPartnerEmail,
        referralPartnerFirstName: deal.referralPartnerFirstName,
        referralPartnerLastName: deal.referralPartnerLastName,
        referralPartnerPhone: deal.referralPartnerPhone,
        referralPartnerUuid: deal.referralPartnerUuid,
        repaymentStrategy: deal.repaymentStrategy,
        solicitorEmail: deal.solicitorEmail,
        solicitorFirmName: deal.solicitorFirmName,
        solicitorMobile: deal.solicitorMobile,
        solicitorName: deal.solicitorName,
        solicitorPhone: deal.solicitorPhone,
        termMonths: deal.termMonths,
        uiSource: deal.uiSource,
    };
}

export function transformDealBorrower(borrower: IDealBorrower) {
    return {
        abn: borrower.abn,
        address: borrower.address,
        businessName: borrower.businessName,
        category: borrower.category,
        dependentNumber: borrower.dependentNumber,
        dob: borrower.dob,
        email: borrower.email,
        firstName: borrower.firstName,
        gender: borrower.gender,
        incomeType: borrower.incomeType,
        jobTitle: borrower.jobTitle,
        lastName: borrower.lastName,
        licenceNumber: borrower.licenceNumber,
        licenceState: borrower.licenceState,
        maritalStatus: borrower.maritalStatus,
        middleName: borrower.middleName,
        monthlyGrossExpenses: borrower.monthlyGrossExpenses,
        monthlyGrossTurnover: borrower.monthlyGrossTurnover,
        monthlyHouseholdExpenses: borrower.monthlyHouseholdExpenses,
        onTitle: borrower.onTitle,
        otherIncomeType: borrower.otherIncomeType,
        passportCountry: borrower.passportCountry,
        passportNumber: borrower.passportNumber,
        phone: borrower.phone,
        residencyStatus: borrower.residencyStatus,
        totalAssets: borrower.totalAssets,
        totalLiabilities: borrower.totalLiabilities,
        trustName: borrower.trustName,
        type: borrower.type,
        yearlyIncome: borrower.yearlyIncome,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformDealNote(note: INote): any {
    return {
        note: note.note,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformDealProperty(property: IProperty): any {
    return {
        addressVersion: property.addressVersion,
        currentDebt: property.currentDebt,
        dwellingCount: property.dwellingCount,
        estimatedValue: property.estimatedValue,
        folioNumber: property.folioNumber,
        insuranceExpiryDate: property.insuranceExpiryDate,
        insuranceReplacementValue: property.insuranceReplacementValue,
        loanAmount: property.loanAmount,
        lotNumber: property.lotNumber,
        lotSizeSquareMetres: property.lotSizeSquareMetres,
        maximumLvr: property.maximumLvr,
        ownershipType: property.ownershipType,
        planNumber: property.planNumber,
        postcode: property.postcode,
        purpose: property.purpose,
        state: property.state,
        strataType: property.strataType,
        streetAddress: property.streetAddress,
        streetName: property.streetName,
        streetNumber: property.streetNumber,
        streetType: property.streetType,
        suburb: property.suburb,
        tenancyType: property.tenancyType,
        unitNumber: property.unitNumber,
        volumeNumber: property.volumeNumber,
        zoneType: property.zoneType,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformDealQuote(quote: IQuote): any {
    return {
        applicationFee: quote.applicationFee,
        applicationFeeFormat: quote.applicationFeeFormat,
        applicationFeePercentage: quote.applicationFeePercentage,
        approvalStatus: quote.approvalStatus,
        brokerageFee: quote.brokerageFee,
        brokerageFeeFormat: quote.brokerageFeeFormat,
        brokerageFeePercentage: quote.brokerageFeePercentage,
        commitmentFee: quote.commitmentFee,
        currentDebt: quote.currentDebt,
        dealUuid: quote.dealUuid,
        establishmentFee: quote.establishmentFee,
        establishmentFeeFormat: quote.establishmentFeeFormat,
        establishmentFeePercentage: quote.establishmentFeePercentage,
        estimatedOutlays: quote.estimatedOutlays,
        interestRate: quote.interestRate,
        legalFees: quote.legalFees,
        loanAmount: quote.loanAmount,
        lvr: quote.lvr,
        maximumLvr: quote.maximumLvr,
        mortgageType: quote.mortgageType,
        netBalance: quote.netBalance,
        paymentType: quote.paymentType,
        propertyValue: quote.propertyValue,
        termMonths: quote.termMonths,
    };
}
