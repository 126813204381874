enum AvailableFundsTypeEnum {
    UnderTenThousand = 'UNDER_TEN_THOUSAND',
    TenToOneHundredThousand = 'TEN_TO_ONE_HUNDRED_THOUSAND',
    OneHundredToTwoHundredFiftyThousand = 'ONE_HUNDRED_TO_TWO_HUNDRED_FIFTY_THOUSAND',
    TwoHundredFiftyToFiveHundredThousand = 'TWO_HUNDRED_FIFTY_TO_FIVE_HUNDRED_THOUSAND',
    FiveHundredThousandToOneMillion = 'FIVE_HUNDRED_THOUSAND_TO_ONE_MILLION',
    OverOneMillion = 'OVER_ONE_MILLION',
}

export default AvailableFundsTypeEnum;
