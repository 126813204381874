import jwt, { TokenExpiredError } from 'jsonwebtoken';
import IToken from '~Auth/IToken';

interface ICompleteToken {
    header: {
        kid: string;
    };
}

export function verifyToken(token: string): IToken {
    const decodedToken: ICompleteToken = jwt.decode(token, { complete: true }) as ICompleteToken;

    if (decodedToken.header.kid === process.env.JWT_KEY_ID) {
        return jwt.verify(token, process.env.JWT_PUBLIC_KEY.replace(/\\n/g, '\n'), {
            algorithms: [process.env.JWT_ALGORITHM as jwt.Algorithm],
        }) as IToken;
    }

    if (process.env.JWT_KEY_ID_PREVIOUS && decodedToken.header.kid === process.env.JWT_KEY_ID_PREVIOUS) {
        return jwt.verify(token, process.env.JWT_PUBLIC_KEY_PREVIOUS.replace(/\\n/g, '\n'), {
            algorithms: [process.env.JWT_ALGORITHM_PREVIOUS as jwt.Algorithm],
        }) as IToken;
    }

    throw new TokenExpiredError(`Unknown JWT token key ID: ${decodedToken.header.kid}`, new Date());
}