import { Breadcrumb, Button, Layout, Modal, Space, Tag, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import IApplicationPropertyValuer from '~Api/Application/IApplicationPropertyValuer';
import { IDictionary } from '~utilities/IDictionary';
import {
    applicationPropertyValuerMarkPaidAction,
    applicationPropertyValuersUnpaidListAction,
} from './actions';
import { applicationPropertyValuersUnpaidSelector } from './selectors';
import { currencyFormatter } from '~utilities/formatters';
import dayjs from 'dayjs';
import IValuer from '~Valuers/IValuer';
import { valuersSelector } from '~Valuers/selectors';
import { valuersListAction } from '~Valuers/actions';

export default function UnpaidValuations(): ReactElement {
    const applicationPropertyValuers: IDictionary<IApplicationPropertyValuer> = useSelector(applicationPropertyValuersUnpaidSelector);
    const valuers: IDictionary<IValuer> = useSelector(valuersSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!applicationPropertyValuers) {
            dispatch(applicationPropertyValuersUnpaidListAction());
        }
    }, [
        applicationPropertyValuers,
        dispatch,
    ]);

    useEffect(() => {
        if (!valuers) {
            dispatch(valuersListAction());
        }
    }, [
        dispatch,
        valuers,
    ]);

    const columns: ColumnsType<IApplicationPropertyValuer> = [
        {
            dataIndex: 'applicationProperty',
            fixed: 'left',
            render: (applicationProperty: IApplicationProperty) => <Link to={`/applications/${applicationProperty.application.uuid}`}>{applicationProperty.application.code}</Link>,
            title: 'Code',
            width: 150,
        },
        {
            dataIndex: ['applicationProperty', 'application', 'formattedName'],
            title: 'Application Name',
            width: 300,
        },
        {
            dataIndex: 'applicationProperty',
            render: (applicationProperty: IApplicationProperty) => <Link to={`/applications/${applicationProperty.application.uuid}/property-valuations/${applicationProperty.uuid}?`}>{applicationProperty.dealProperty.formattedAddress}</Link>,
            title: 'Property',
            width: 320,
        },
        {
            dataIndex: ['valuer'],
            filters: _.map(_.sortBy(valuers, ['name', 'state']), (valuer: IValuer) => ({
                text: <Space>{valuer.name} <Tag>{valuer.state}</Tag></Space>,
                value: valuer.uuid,
            })),
            onFilter: (value: boolean|number|string, applicationPropertyValuer: IApplicationPropertyValuer) => value === applicationPropertyValuer.valuerUuid,
            render: (valuer: IValuer) => <Space>{valuer.name} <Tag>{valuer.state}</Tag></Space>,
            title: 'Valuer',
            width: 300,
        },
        {
            dataIndex: 'quoteAmount',
            render: (quoteAmount: number) => quoteAmount ? currencyFormatter.format(quoteAmount) : '-',
            title: 'Quote',
            width: 140,
        },
        {
            dataIndex: 'valuationReceivedTime',
            defaultSortOrder: 'descend',
            render: (valuationReceivedTime: string) => dayjs(valuationReceivedTime).format('DD/MM/YYYY'),
            sorter: (a: IApplicationPropertyValuer, b: IApplicationPropertyValuer) => dayjs(a.valuationReceivedTime) > dayjs(b.valuationReceivedTime) ? 1 : -1,
            title: 'Received',
            width: 140,
        },
        {
            dataIndex: 'uuid',
            fixed: 'right',
            render: (uuid: string) => {
                function onClickMarkAsPaid(): void {
                    Modal.confirm({
                        content: 'Are you sure you want to mark this invoice as paid?',
                        okText: 'Mark as Paid',
                        onOk: () => {
                            dispatch(applicationPropertyValuerMarkPaidAction(uuid));
                        },
                        title: 'Mark as Paid',
                    });
                }

                return <Button type='primary' onClick={onClickMarkAsPaid}>Mark As Paid</Button>;
            },
            title: 'Actions',
            width: 150,
        },
    ];

    return (
        <Layout className='applications'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Unpaid Valuations</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Unpaid Valuations</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(applicationPropertyValuers)}
                        loading={!applicationPropertyValuers || !valuers}
                        pagination={false}
                        rowKey='uuid'
                        scroll={{ x: 1, y: 800 }}
                        size='middle'
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
