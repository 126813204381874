// isFinite,
const isNil = function(value: any): value is null | undefined {
    /**
     * isNil(null) => true
     * isNil() => true
     */
  return value === null || value === undefined;
};

export default isNil;
