import { Breadcrumb, Button, Layout, Modal, Space, Spin, Table, Tag, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { aggregatorsListAction } from '~Aggregators/actions';
import { aggregatorsSelector } from '~Aggregators/selectors';
import { applicationsOutstandingBrokerCommissionsSelector } from './selectors';
import IAggregator from '~Api/Aggregator/IAggregator';
import ConditionTypeEnum from '~Api/Application/ConditionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IDeal from '~Api/Deal/IDeal';
import UploadedConditionDocumentList from '~Applications/Application/UploadedConditionDocumentList';
import {
    applicationMarkBrokerCommissionPaidAction,
    applicationsOutstandingBrokerCommissionsListAction,
} from '~Applications/actions';
import { IDictionary } from '~utilities/IDictionary';
import { currencyFormatter } from '~utilities/formatters';
import BrokerCommissionFormatEnum from '~Api/Application/BrokerCommissionFormatEnum';
import InvoiceTypeEnum from '~Api/Aggregator/InvoiceTypeEnum';

export default function OutstandingBrokerCommissions(): ReactElement {
    const aggregators: IDictionary<IAggregator> = useSelector(aggregatorsSelector);
    const applications: IDictionary<IApplication> = useSelector(applicationsOutstandingBrokerCommissionsSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!aggregators) {
            dispatch(aggregatorsListAction());
        }
    }, [
        aggregators,
        dispatch,
    ]);

    useEffect(() => {
        if (!applications) {
            dispatch(applicationsOutstandingBrokerCommissionsListAction());
        }
    }, [
        applications,
        dispatch,
    ]);

    if (!aggregators || !applications) {
        return (
            <Layout className='brokers'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Outstanding Broker Commissions</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Outstanding Broker Commissions</Typography.Title>
                        <Spin/>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const columns: ColumnType<IApplication>[] = [
        {
            dataIndex: 'settlementDate',
            defaultSortOrder: 'ascend',
            fixed: 'left',
            render: (settlementDate: string) => dayjs(settlementDate).format('D/M/YYYY'),
            sorter: (a: IApplication, b: IApplication) => dayjs(a.settlementDate) > dayjs(b.settlementDate) ? 1 : -1,
            title: 'Settled',
            width: 100,
        },
        {
            fixed: 'left',
            render: (application: IApplication) => <Link to={`/applications/${application.uuid}`}>{application.code}</Link>,
            title: 'Code',
            width: 120,
        },
        {
            render: (application: IApplication) => <Link to={`/applications/${application.uuid}`}>{application.formattedName || '-'}</Link>,
            title: 'Application',
            width: 300,
        },
        {
            dataIndex: 'deal',
            render: (deal: IDeal) => deal.brokerUuid ? <Link to={`/brokers/${deal.brokerUuid}`}>{deal.broker.firstName} {deal.broker.lastName}</Link> : '-',
            title: 'Broker',
            width: 200,
        },
        {
            dataIndex: 'deal',
            render: (deal: IDeal) => {
                if (!deal.brokerUuid) {
                    return '-';
                }

                const invoicedDirectlyTag: JSX.Element = deal.broker.aggregatorUuid && aggregators[deal.broker.aggregatorUuid].invoiceType === InvoiceTypeEnum.DIRECT ? (<Tag title='Invoiced Directly' color='blue'>DIRECT</Tag>): null;

                return (
                    <Space align='center'>
                        {deal.broker.aggregatorUuid ? aggregators[deal.broker.aggregatorUuid].name : '-'}
                        {invoicedDirectlyTag}
                    </Space>
                );
            },
            title: 'Aggregator',
            width: 300,
        },
        {
            render: (application: IApplication) => (
                <UploadedConditionDocumentList
                    application={application}
                    conditionType={ConditionTypeEnum.BrokerInvoice}
                />
            ),
            title: 'Invoice',
            width: 100,
        },
        {
            render: (application: IApplication) => {
                if (application.brokerCommissionFormat === BrokerCommissionFormatEnum.Dollars) {
                    return currencyFormatter.format(application.brokerCommissionDollars);
                }

                return currencyFormatter.format(Math.ceil((application.brokerCommissionPercentage / 100) * application.loanAmount));
            },
            title: 'Amount',
            width: 100,
        },
        {
            dataIndex: 'uuid',
            fixed: 'right',
            render: (uuid: string) => {
                function onClickMarkAsPaid(): void {
                    Modal.confirm({
                        content: 'Are you sure you want to mark this invoice as paid?',
                        okText: 'Mark as Paid',
                        onOk: () => {
                            dispatch(applicationMarkBrokerCommissionPaidAction(uuid));
                        },
                        title: 'Mark as Paid',
                    });
                }

                return <Button type='primary' onClick={onClickMarkAsPaid}>Mark As Paid</Button>;
            },
            title: 'Actions',
            width: 150,
        },
    ];

    return (
        <Layout className='brokers'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Outstanding Broker Commissions</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Typography.Title level={2}>Outstanding Broker Commissions</Typography.Title>
                    <Table
                        columns={columns}
                        dataSource={_.values(applications)}
                        pagination={false}
                        rowKey='uuid'
                        scroll={{ x: 1370 }}
                        size='middle'
                    />
                </Layout.Content>
            </Layout>
        </Layout>
    );
}
