import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Input, MenuProps, Row, Space, Spin, Typography } from 'antd';
import _ from 'lodash';
import objectHash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IProperty from '~Api/Deal/IProperty';
import {
    applicationGetAction,
    applicationRecalculateAction,
    applicationValueSetAction,
} from '~Applications/actions';
import {
    applicationDealPropertiesSelector,
    applicationSelector,
} from '~Applications/selectors';
import constants from '~constants';
import ICalculatorProperty from '~Deals/ICalculatorProperty';
import {
    DealCalculateBaseAmountEnum,
    IDealCalculateParameters,
    IDealCalculatedAmounts,
    getDealCalculatedAmounts,
} from '~Deals/utilities';
import history from '~history';
import {
    IDealCalculateValidationErrors,
    validateDealEstablishmentFee,
    validateDealInterestRate,
    validateDealLegalFees,
    validateDealMaximumLvr,
} from '~Leads/validators';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import Layout from './Layout';
import { calculateNetPrepaidBalanceOnSettlement } from '../utilities';

const mortgageTypeLabels: IDictionary<string> = {
    [MortgageTypeEnum.FirstMortgage]: 'First Mortgage',
    [MortgageTypeEnum.SecondMortgage]: 'Second Mortgage',
};

interface IDefaultedValues {
    applicationFeeDollars: number;
    applicationFeeFormat: ApplicationFeeFormatEnum;
    applicationFeePercentage: number;
    brokerageFeeDollars: number;
    brokerageFeeFormat: BrokerageFeeFormatEnum;
    brokerageFeePercentage: number;
    commitmentFee: number;
    establishmentFeeDollars: number;
    establishmentFeeFormat: EstablishmentFeeFormatEnum;
    establishmentFeePercentage: number;
    estimatedOutlays: number;
    interestRate: number;
    legalFees: number;
    maximumLvr: number;
    requestedLoanAmount: number;
    requestedPayoutAmount: number;
    termMonths: number;
}

interface IApplicationCalculateValidationErrors extends IDealCalculateValidationErrors {
    netPrepaidBalanceOnSettlement?: string;
}

interface IState {
    applicationFeeDollars: number;
    applicationFeeFormat: ApplicationFeeFormatEnum;
    applicationFeePercentage: number;
    baseAmount: DealCalculateBaseAmountEnum;
    brokerageFeeDollars: number;
    brokerageFeeFormat: BrokerageFeeFormatEnum;
    brokerageFeePercentage: number;
    calculateParametersHash: string;
    calculatedAmounts: IDealCalculatedAmounts;
    commitmentFee: number;
    dirtyFields: IDictionary<boolean>;
    errors: IApplicationCalculateValidationErrors;
    establishmentFeeDollars: number;
    establishmentFeeFormat: EstablishmentFeeFormatEnum;
    establishmentFeePercentage: number;
    estimatedOutlays: number;
    interestRate: number;
    legalFees: number;
    maximumLvr: number;
    requestedLoanAmount: number;
    requestedPayoutAmount: number;
    termMonths: number;
}

interface IMatch {
    applicationUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    properties: IDictionary<IProperty>;
}

interface IPropsDispatch {
    applicationGet: () => void;
    applicationRecalculate: (
        applicationFee: number,
        applicationFeeFormat: ApplicationFeeFormatEnum,
        applicationFeePercentage: number,
        brokerageFee: number,
        brokerageFeeFormat: BrokerageFeeFormatEnum,
        brokerageFeePercentage: number,
        commitmentFee: number,
        establishmentFee: number,
        establishmentFeeFormat: EstablishmentFeeFormatEnum,
        establishmentFeePercentage: number,
        estimatedOutlays: number,
        interestRate: number,
        legalFees: number,
        loanAmount: number,
        lvr: number,
        maximumLvr: number,
        termMonths: number,
    ) => void;
    applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Calculator extends React.Component<Props, IState> {
    public state: IState = {
        applicationFeeDollars: null,
        applicationFeeFormat: null,
        applicationFeePercentage: null,
        baseAmount: DealCalculateBaseAmountEnum.RequestedPayoutAmount,
        brokerageFeeDollars: null,
        brokerageFeeFormat: null,
        brokerageFeePercentage: null,
        calculateParametersHash: null,
        calculatedAmounts: null,
        commitmentFee: null,
        dirtyFields: {},
        errors: {},
        establishmentFeeDollars: null,
        establishmentFeeFormat: null,
        establishmentFeePercentage: null,
        estimatedOutlays: null,
        interestRate: null,
        legalFees: null,
        maximumLvr: null,
        requestedLoanAmount: null,
        requestedPayoutAmount: null,
        termMonths: null,
    };

    private debouncedCalculateDealAmounts: () => void = null;

    constructor(props: Props) {
        super(props);

        this.getDefaultedValues = this.getDefaultedValues.bind(this);
        this.getCalculatedRequestedPayoutAmount = this.getCalculatedRequestedPayoutAmount.bind(this);
        this.onClickBaseAmount = this.onClickBaseAmount.bind(this);
        this.onClickApplicationFeeFormat = this.onClickApplicationFeeFormat.bind(this);
        this.onClickBrokerageFeeFormat = this.onClickBrokerageFeeFormat.bind(this);
        this.onClickEstablishmentFeeFormat = this.onClickEstablishmentFeeFormat.bind(this);

        this.onChangeApplicationFeePercentage = this.onChangeApplicationFeePercentage.bind(this);
        this.onChangeApplicationFeeDollars = this.onChangeApplicationFeeDollars.bind(this);
        this.onChangeBrokerageFeePercentage = this.onChangeBrokerageFeePercentage.bind(this);
        this.onChangeBrokerageFeeDollars = this.onChangeBrokerageFeeDollars.bind(this);
        this.onChangeCommitmentFee = this.onChangeCommitmentFee.bind(this);
        this.onChangeEstablishmentFeePercentage = this.onChangeEstablishmentFeePercentage.bind(this);
        this.onChangeEstablishmentFeeDollars = this.onChangeEstablishmentFeeDollars.bind(this);
        this.onChangeEstimatedOutlays = this.onChangeEstimatedOutlays.bind(this);
        this.onChangeInterestRate = this.onChangeInterestRate.bind(this);
        this.onChangeLegalFees = this.onChangeLegalFees.bind(this);
        this.onChangeMaximumLvr = this.onChangeMaximumLvr.bind(this);
        this.onChangeRequestedLoanAmount = this.onChangeRequestedLoanAmount.bind(this);
        this.onChangeRequestedPayoutAmount = this.onChangeRequestedPayoutAmount.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);

        this.validateEstablishmentFee = this.validateEstablishmentFee.bind(this);
        this.validateInterestRate = this.validateInterestRate.bind(this);
        this.validateLegalFees = this.validateLegalFees.bind(this);
        this.validateMaximumLvr = this.validateMaximumLvr.bind(this);

        this.setError = this.setError.bind(this);

        this.onClickSave = this.onClickSave.bind(this);

        this.debouncedCalculateDealAmounts = _.debounce(this.calculateDealAmounts, 250);
    }

    public componentDidMount(): void {
        const { application } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        this.calculateDealAmounts();
    }

    public componentDidUpdate(): void {
        this.debouncedCalculateDealAmounts();
    }

    private calculateDealAmounts(): void {
        const { application, properties } = this.props;
        const { baseAmount } = this.state;

        if (!application || !properties) {
            return;
        }

        const calculateParameters: IDealCalculateParameters = this.getDefaultedValues();

        const calculateParametersHash: string = objectHash(calculateParameters);

        if (calculateParametersHash !== this.state.calculateParametersHash) {
            const calculatorProperties: ICalculatorProperty[] = [];

            _.forEach(application.properties, (applicationProperty: IApplicationProperty) => {
                const property: ICalculatorProperty = { ...properties[applicationProperty.dealPropertyUuid] };

                if (!!applicationProperty.valuationValue) {
                    property.valuationValue = applicationProperty.valuationValue;
                }

                // Ignore property debt on extension applications
                if (application.extensionType === ExtensionTypeEnum.Renewal) {
                    property.currentDebt = 0;
                }

                calculatorProperties.push(property);
            });

            const calculatedAmounts: IDealCalculatedAmounts = getDealCalculatedAmounts({
                ...calculateParameters,
                baseDealAmount: baseAmount,
                isApplication: true,
                mortgageType: application.mortgageType,
                properties: calculatorProperties,
                totalCurrentDebt: ExtensionTypeEnum.Renewal === application.extensionType ? application.payoutFigure.balanceAmount : null,
            });

            this.setState({
                calculateParametersHash,
                calculatedAmounts,
            });
        }
    }

    public render(): JSX.Element {
        const { application, match, properties } = this.props;
        const {
            baseAmount,
            calculatedAmounts,
            errors,
        } = this.state;

        if (!application || !calculatedAmounts || !properties) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='calculator'>
                    <Spin/>
                </Layout>
            );
        }

        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

        const {
            applicationFeeDollars,
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFeeDollars,
            brokerageFeeFormat,
            brokerageFeePercentage,
            commitmentFee,
            establishmentFeeDollars,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            maximumLvr,
            requestedLoanAmount,
            requestedPayoutAmount,
            termMonths,
        } = this.getDefaultedValues();

        const currentNetPayoutAmount: number = this.getCalculatedRequestedPayoutAmount();

        const {
            applicationFeeTotal,
            brokerageFeeTotal,
            establishmentFeeTotal,
            grossLoanAmount,
            interestPayable,
            lvr,
            netPrepaidBalanceOnSettlement,
            totalCurrentDebt,
            totalValue,
        } = calculatedAmounts;

        const interestRateDefault: number = application.interestRateDefault || (interestRate + 10);

        const monthlyInterestPaymentsNormal: number = Math.ceil(grossLoanAmount * (interestRate / 100) / 12);
        const monthlyInterestPaymentsDefault: number = Math.ceil(grossLoanAmount * (interestRateDefault / 100) / 12);
        const grossBalanceOnSettlement: number = grossLoanAmount - interestPayable - establishmentFeeTotal - applicationFeeTotal - brokerageFeeTotal - legalFees - estimatedOutlays;

        const totalCostsDollars: number = grossLoanAmount - netPrepaidBalanceOnSettlement;
        const totalCostsPercentage: number = Number((grossLoanAmount - netPrepaidBalanceOnSettlement) / grossLoanAmount * 100);
        const totalCostsPercentagePerAnnum: number = Number((establishmentFeeTotal + applicationFeeTotal + brokerageFeeTotal + legalFees + estimatedOutlays + (monthlyInterestPaymentsNormal * 12)) / grossLoanAmount * 100);

        const baseAmountMenu: MenuProps = {
            items: [
                {
                    key: 'base-amount',
                    label: baseAmount === DealCalculateBaseAmountEnum.GrossLoanAmount ? 'Requested Payout Amount' : 'Requested Loan Amount',
                    onClick: this.onClickBaseAmount,
                },
            ],
        };

        const baseAmountLabel: JSX.Element = (
            <Dropdown menu={baseAmountMenu}>
                <a>{baseAmount === DealCalculateBaseAmountEnum.RequestedPayoutAmount ? 'Requested Payout Amount' : 'Requested Loan Amount'} <DownOutlined /></a>
            </Dropdown>
        );

        const establishmentFeeMenu: MenuProps = {
            items: [
                {
                    key: 'establishment-fee-format',
                    label: establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickEstablishmentFeeFormat,
                },
            ],
        };

        const establishmentFeeLabel: JSX.Element = (
            <Dropdown menu={establishmentFeeMenu}>
                <a>{ExtensionTypeEnum.Renewal === application.extensionType ? 'Renewal Fee' : 'Establishment Fee'} <DownOutlined /></a>
            </Dropdown>
        );

        const applicationFeeMenu: MenuProps = {
            items: [
                {
                    key: 'application-fee-format',
                    label: applicationFeeFormat === ApplicationFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickApplicationFeeFormat,
                },
            ],
        };

        const applicationFeeLabel: JSX.Element = (
            <Dropdown menu={applicationFeeMenu}>
                <a>Application Fee <DownOutlined /></a>
            </Dropdown>
        );

        const brokerageFeeMenu: MenuProps = {
            items: [
                {
                    key: 'brokerage-fee-format',
                    label: brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickBrokerageFeeFormat,
                },
            ],
        };

        const brokerageFeeLabel: JSX.Element = (
            <Dropdown menu={brokerageFeeMenu}>
                <a>Brokerage Fee <DownOutlined /></a>
            </Dropdown>
        );

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const grossLoanAmountDifference: number = grossLoanAmount - application.loanAmount;
        const netPayoutDifference: number = netPrepaidBalanceOnSettlement - currentNetPayoutAmount;

        const establishmentFeeDollarsMinimumLimit: number = isRenewal ? constants.APPLICATION_EXTENSION_ESTABLISHMENT_FEE_DOLLARS_MINIMUM : (application.deal.establishmentFeeDollarsMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_DOLLARS_MINIMUM);
        const establishmentFeePercentageMinimumLimit: number = isRenewal ? constants.APPLICATION_EXTENSION_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM : (application.deal.establishmentFeePercentageMinimumOverride ?? constants.DEAL_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM);
        const interestRateMinimumLimit: number = application.deal.interestRateMinimumOverride || (application.mortgageType === MortgageTypeEnum.SecondMortgage ? constants.DEAL_INTEREST_RATE_SECOND_MORTGAGE_MINIMUM : constants.DEAL_INTEREST_RATE_MINIMUM);
        const legalFeesDollarsMinimumLimit: number = application.deal.legalFeesDollarsMinimumOverride ?? constants.DEAL_LEGAL_FEES_DOLLARS_MINIMUM;
        const lvrMaximumLimit: number = application.deal.lvrMaximumOverride || constants.DEAL_LVR_MAXIMUM;

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='calculator'>
                <Button className='save' danger={true} onClick={this.onClickSave}>Save</Button>
                <Typography.Title level={2}>Calculator</Typography.Title>
                <Row>
                    <Col span={12}>
                        <Form.Item className='loan-amount-total' label={baseAmountLabel}>
                            {baseAmount === DealCalculateBaseAmountEnum.RequestedPayoutAmount && <Input addonBefore='$' onChange={this.onChangeRequestedPayoutAmount} type='number' value={requestedPayoutAmount} />}
                            {baseAmount === DealCalculateBaseAmountEnum.GrossLoanAmount && <Input addonBefore='$' onChange={this.onChangeRequestedLoanAmount} type='number' value={requestedLoanAmount} />}
                        </Form.Item>
                        <Form.Item className='term-months' label='Term'>
                            <Input addonAfter='months' min={1} onChange={this.onChangeTermMonths} step={1} type='number' value={termMonths} />
                        </Form.Item>
                        <Form.Item className='interest-rate' help={errors.interestRate} label='Interest Rate' validateStatus={errors.interestRate && 'error'}>
                            <Space>
                                <Input addonAfter='%' min={interestRateMinimumLimit} onBlur={this.validateInterestRate} onChange={this.onChangeInterestRate} step={0.1} type='number' value={interestRate} />
                                <span>({percentageFormatter.format(interestRateDefault / 100)} default)</span>
                            </Space>
                        </Form.Item>
                        <Form.Item className='maximum-lvr' help={errors.maximumLvr} label='Maximum LVR' validateStatus={errors.maximumLvr && 'error'}>
                            <Input addonAfter='%' max={lvrMaximumLimit} onBlur={this.validateMaximumLvr} onChange={this.onChangeMaximumLvr} type='number' value={maximumLvr} />
                        </Form.Item>
                        <Form.Item className='commitment-fee' label='Commitment Fee'>
                            <Input addonBefore='$' onChange={this.onChangeCommitmentFee} type='number' value={commitmentFee} />
                        </Form.Item>
                        <Form.Item
                            className={establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? 'establishment-fee-dollars' : 'establishment-fee-percentage'}
                            help={errors.establishmentFee}
                            label={establishmentFeeLabel}
                            validateStatus={errors.establishmentFee && 'error'}
                        >
                            {establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars && <Space><Input addonBefore='$' min={establishmentFeeDollarsMinimumLimit} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeeDollars} type='number' value={establishmentFeeDollars} /><span>({percentageFormatter.format(establishmentFeeTotal / grossLoanAmount)})</span></Space>}
                            {establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage && <Space><Input addonAfter='%' min={establishmentFeePercentageMinimumLimit} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeePercentage} step={0.1} type='number' value={establishmentFeePercentage} /><span>({currencyFormatter.format(establishmentFeeTotal)})</span></Space>}
                        </Form.Item>
                        <Form.Item className={applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? 'application-fee-dollars' : 'application-fee-percentage'} label={applicationFeeLabel}>
                            {applicationFeeFormat === ApplicationFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeApplicationFeeDollars} type='number' value={applicationFeeDollars} /><span>({percentageFormatter.format(applicationFeeTotal / grossLoanAmount)})</span></Space>}
                            {applicationFeeFormat === ApplicationFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeApplicationFeePercentage} step={0.1} type='number' value={applicationFeePercentage} /><span>({currencyFormatter.format(applicationFeeTotal)} + GST)</span></Space>}
                        </Form.Item>
                        <Form.Item className={brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? 'brokerage-fee-dollars' : 'brokerage-fee-percentage'} label={brokerageFeeLabel}>
                            {brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeBrokerageFeeDollars} type='number' value={brokerageFeeDollars} /><span>({percentageFormatter.format(brokerageFeeTotal / grossLoanAmount)})</span></Space>}
                            {brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeBrokerageFeePercentage} step={0.1} type='number' value={brokerageFeePercentage} /><span>({currencyFormatter.format(brokerageFeeTotal)} + GST)</span></Space>}
                        </Form.Item>
                        <Form.Item className='legal-fees' help={errors.legalFees} label='Legal Documents' validateStatus={errors.legalFees && 'error'}>
                            <Input addonBefore='$' addonAfter='+ GST' min={legalFeesDollarsMinimumLimit} onBlur={this.validateLegalFees} onChange={this.onChangeLegalFees} type='number' value={legalFees} />
                        </Form.Item>
                        <Form.Item className='approximate-outlays' label='Approximate Outlays'>
                            <Input addonBefore='$' onChange={this.onChangeEstimatedOutlays} type='number' value={estimatedOutlays} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className='mortgage-type' label='Mortgage Type'>
                            {mortgageTypeLabels[application.mortgageType]}
                        </Form.Item>
                        <Form.Item className='gross-loan-amount' label='Gross Loan Amount'>
                            {currencyFormatter.format(grossLoanAmount)}
                            {' '}<span className={`difference ${grossLoanAmountDifference < 0 ? 'negative' : 'positive'}`}>({currencyFormatter.format(grossLoanAmountDifference)})</span>
                        </Form.Item>
                        <Form.Item className='property-value' label='Property Value'>
                            {currencyFormatter.format(totalValue)}
                        </Form.Item>
                        <Form.Item className='current-debt' label={isRenewal ? 'Current Debt (Payout Figure)' : 'Current Debt'}>
                            {currencyFormatter.format(totalCurrentDebt)}
                        </Form.Item>
                        <Form.Item className='lvr' label='LVR'>
                            {percentageFormatter.format(lvr / 100)}
                        </Form.Item>
                        <Form.Item className='interest-payable' label='Interest Payable'>
                            {currencyFormatter.format(interestPayable)}
                        </Form.Item>
                        <Form.Item className='monthly-interest-payments' label='Monthly Interest Payments'>
                            {currencyFormatter.format(monthlyInterestPaymentsNormal)}  ({currencyFormatter.format(monthlyInterestPaymentsDefault)} default)
                        </Form.Item>
                        <Form.Item className='gross-balance-on-settlement' label='Gross Balance on Settlement'>
                            {currencyFormatter.format(grossBalanceOnSettlement)}
                        </Form.Item>
                        <Form.Item
                            className='net-prepaid-balance-on-settlement'
                            help={errors.netPrepaidBalanceOnSettlement}
                            label='Net Balance on Settlement'
                            validateStatus={errors.netPrepaidBalanceOnSettlement && 'error'}
                        >
                            {currencyFormatter.format(netPrepaidBalanceOnSettlement)}
                            {' '}<span className={`difference ${netPayoutDifference < 0 ? 'negative' : 'positive'}`}>({currencyFormatter.format(netPayoutDifference)})</span>
                        </Form.Item>
                        <Form.Item className='total-costs-dollars' label='Total Costs'>
                            {currencyFormatter.format(totalCostsDollars)}
                            {' '}({percentageFormatter.format(totalCostsPercentage / 100)}, {percentageFormatter.format(totalCostsPercentagePerAnnum / 100)} pa)
                        </Form.Item>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private getDefaultedValues(): IDefaultedValues {
        const { application } = this.props;
        const { dirtyFields } = this.state;
        let {
            applicationFeeDollars,
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFeeDollars,
            brokerageFeeFormat,
            brokerageFeePercentage,
            commitmentFee,
            establishmentFeeDollars,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            maximumLvr,
            requestedLoanAmount,
            requestedPayoutAmount,
            termMonths,
        } = this.state;

        const currentNetPayoutAmount: number = this.getCalculatedRequestedPayoutAmount();

        applicationFeeDollars = dirtyFields.applicationFeeDollars ? applicationFeeDollars : application.applicationFee;
        applicationFeeFormat = dirtyFields.applicationFeeFormat ? applicationFeeFormat : (application.applicationFeeFormat || ApplicationFeeFormatEnum.Percentage);
        applicationFeePercentage = dirtyFields.applicationFeePercentage ? applicationFeePercentage : (application.applicationFeePercentage || roundTo((application.applicationFee / application.loanAmount * 100), 2));
        brokerageFeeDollars = dirtyFields.brokerageFeeDollars ? brokerageFeeDollars : application.brokerageFee;
        brokerageFeeFormat = dirtyFields.brokerageFeeFormat ? brokerageFeeFormat : (application.brokerageFeeFormat || BrokerageFeeFormatEnum.Percentage);
        brokerageFeePercentage = dirtyFields.brokerageFeePercentage ? brokerageFeePercentage : (application.brokerageFeePercentage || roundTo((application.brokerageFee / application.loanAmount * 100), 2));
        commitmentFee = dirtyFields.commitmentFee ? commitmentFee : application.commitmentFee;
        establishmentFeeDollars = dirtyFields.establishmentFeeDollars ? establishmentFeeDollars : application.establishmentFee;
        establishmentFeeFormat = dirtyFields.establishmentFeeFormat ? establishmentFeeFormat : (application.establishmentFeeFormat || EstablishmentFeeFormatEnum.Percentage);
        establishmentFeePercentage = dirtyFields.establishmentFeePercentage ? establishmentFeePercentage : (application.establishmentFeePercentage || roundTo((application.establishmentFee / application.loanAmount * 100), 2));
        estimatedOutlays = dirtyFields.estimatedOutlays ? estimatedOutlays : application.estimatedOutlays;
        interestRate = dirtyFields.interestRate ? interestRate : application.interestRate;
        legalFees = dirtyFields.legalFees ? legalFees : application.legalFees;
        maximumLvr = dirtyFields.maximumLvr ? maximumLvr : (application.maximumLvr || application.lvr);
        requestedLoanAmount = dirtyFields.requestedLoanAmount ? requestedLoanAmount : application.loanAmount;
        requestedPayoutAmount = dirtyFields.requestedPayoutAmount ? requestedPayoutAmount : currentNetPayoutAmount;
        termMonths = dirtyFields.termMonths ? termMonths : application.termMonths;

        return {
            applicationFeeDollars,
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFeeDollars,
            brokerageFeeFormat,
            brokerageFeePercentage,
            commitmentFee,
            establishmentFeeDollars,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            maximumLvr,
            requestedLoanAmount,
            requestedPayoutAmount,
            termMonths,
        };
    }

    private getCalculatedRequestedPayoutAmount(): number {
        const { application, properties } = this.props;

        return calculateNetPrepaidBalanceOnSettlement(application, properties);
    }

    private onClickBaseAmount(): void {
        const { baseAmount } = this.state;

        this.setState({
            baseAmount: baseAmount === DealCalculateBaseAmountEnum.GrossLoanAmount ? DealCalculateBaseAmountEnum.RequestedPayoutAmount : DealCalculateBaseAmountEnum.GrossLoanAmount,
        });
    }

    private onClickApplicationFeeFormat(): void {
        const { applicationFeeFormat, dirtyFields } = this.state;

        this.setState({
            applicationFeeFormat: applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? ApplicationFeeFormatEnum.Percentage : ApplicationFeeFormatEnum.Dollars,
            dirtyFields: {
                ...dirtyFields,
                applicationFeeFormat: true,
            },
        });
    }

    private onClickBrokerageFeeFormat(): void {
        const { brokerageFeeFormat, dirtyFields } = this.state;

        this.setState({
            brokerageFeeFormat: brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? BrokerageFeeFormatEnum.Percentage : BrokerageFeeFormatEnum.Dollars,
            dirtyFields: {
                ...dirtyFields,
                brokerageFeeFormat: true,
            },
        });
    }

    private onClickEstablishmentFeeFormat(): void {
        const { establishmentFeeFormat, dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                establishmentFeeFormat: true,
            },
            establishmentFeeFormat: establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? EstablishmentFeeFormatEnum.Percentage : EstablishmentFeeFormatEnum.Dollars,
        });
    }

    private onChangeApplicationFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            applicationFeeDollars: event.target.value ? Number(event.target.value) : null,
            dirtyFields: {
                ...dirtyFields,
                applicationFeeDollars: true,
            },
        });
    }

    private onChangeApplicationFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            applicationFeePercentage: event.target.value ? Number(event.target.value) : null,
            dirtyFields: {
                ...dirtyFields,
                applicationFeePercentage: true,
            },
        });
    }

    private onChangeBrokerageFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            brokerageFeeDollars: event.target.value ? Number(event.target.value) : null,
            dirtyFields: {
                ...dirtyFields,
                brokerageFeeDollars: true,
            },
        });
    }

    private onChangeBrokerageFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            brokerageFeePercentage: event.target.value ? Number(event.target.value) : null,
            dirtyFields: {
                ...dirtyFields,
                brokerageFeePercentage: true,
            },
        });
    }

    private onChangeCommitmentFee(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            commitmentFee: event.target.value ? Number(event.target.value) : null,
            dirtyFields: {
                ...dirtyFields,
                commitmentFee: true,
            },
        });
    }

    private onChangeEstablishmentFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        const establishmentFeeDollars: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                establishmentFeeDollars: true,
            },
            establishmentFeeDollars: event.target.value ? establishmentFeeDollars : null,
        });
    }

    private onChangeEstablishmentFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        const establishmentFeePercentage: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                establishmentFeePercentage: true,
            },
            establishmentFeePercentage: event.target.value ? establishmentFeePercentage : null,
        });
    }

    private onChangeEstimatedOutlays(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                estimatedOutlays: true,
            },
            estimatedOutlays: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeInterestRate(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        const interestRate: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                interestRate: true,
            },
            interestRate: event.target.value ? interestRate : null,
        });
    }

    private onChangeLegalFees(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        const legalFees: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                legalFees: true,
            },
            legalFees: event.target.value ? legalFees : null,
        });
    }

    private onChangeMaximumLvr(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        const maximumLvr: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                maximumLvr: true,
            },
            maximumLvr: event.target.value ? maximumLvr : null,
        });
    }

    private onChangeRequestedLoanAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                requestedLoanAmount: true,
            },
            requestedLoanAmount: event.target.valueAsNumber,
        });
    }

    private onChangeRequestedPayoutAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        const requestedPayoutAmount: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                requestedPayoutAmount: true,
            },
            requestedPayoutAmount: event.target.value ? requestedPayoutAmount : null,
        });
    }

    private onChangeTermMonths(event: React.ChangeEvent<HTMLInputElement>): void {
        const { dirtyFields } = this.state;

        const termMonths: number = Number(event.target.value);

        this.setState({
            dirtyFields: {
                ...dirtyFields,
                termMonths: true,
            },
            termMonths: event.target.value ? termMonths : null,
        });
    }

    private validateEstablishmentFee(): boolean {
        const { application } = this.props;
        const { calculatedAmounts } = this.state;

        const parameters: IDealCalculateParameters = {
            ...this.state, // Honestly not sure we need this but leaving in for now
            ...this.getDefaultedValues(),
        };

        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;

        const establishmentFeeDollarsMinimumOverride: number = application.deal.establishmentFeeDollarsMinimumOverride ?? (isRenewal ? 0 : null);
        const establishmentFeePercentageMinimumOverride: number = application.deal.establishmentFeePercentageMinimumOverride ?? (isRenewal ? constants.APPLICATION_EXTENSION_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM : null);

        const {
            establishmentFeeTotal,
            grossLoanAmount,
        } = calculatedAmounts;

        return validateDealEstablishmentFee({
            ...parameters,
            establishmentFeeDollarsMinimumOverride,
            establishmentFeePercentageMinimumOverride,
            establishmentFeeTotal,
            grossLoanAmount,
            isBroker: (application.deal.isBroker || !!application.deal.brokerUuid),
        }, this.setError);
    }

    private validateInterestRate(): boolean {
        const { application } = this.props;

        const parameters: IDealCalculateParameters = {
            ...this.state,
            ...this.getDefaultedValues(),
        };

        return validateDealInterestRate({
            ...parameters,
            interestRateMinimumOverride: application.deal.interestRateMinimumOverride,
            mortgageType: application.mortgageType,
        }, this.setError);
    }

    private validateLegalFees(): boolean {
        const { application } = this.props;

        const parameters: IDealCalculateParameters = {
            ...this.state,
            ...this.getDefaultedValues(),
        };

        return validateDealLegalFees({
            ...parameters,
            legalFeesDollarsMinimumOverride: application.deal.legalFeesDollarsMinimumOverride,
        }, this.setError);
    }

    private validateMaximumLvr(): boolean {
        const { application } = this.props;
        const { calculatedAmounts } = this.state;

        const parameters: IDealCalculateParameters = {
            ...this.state,
            ...this.getDefaultedValues(),
        };

        const {
            netPrepaidBalanceOnSettlement,
        } = calculatedAmounts;

        return validateDealMaximumLvr({
            ...parameters,
            lvrMaximumOverride: application.deal.lvrMaximumOverride,
            netPrepaidBalanceOnSettlement,
        }, this.setError);
    }

    private validateNetPrepaidBalanceOnSettlement(): boolean {
        const { application } = this.props;
        const { calculatedAmounts } = this.state;
        const { netPrepaidBalanceOnSettlement } = calculatedAmounts;

        let error: string = null;

        if (application.extensionType === ExtensionTypeEnum.Renewal && netPrepaidBalanceOnSettlement < 0) {
            error = 'Net payout amount can\'t be lower than $0';
        }

        this.setError('netPrepaidBalanceOnSettlement', error);

        return !error;
    }

    private onClickSave(): void {
        const { application } = this.props;
        const { calculatedAmounts } = this.state;

        let valid: boolean = true;

        valid = this.validateEstablishmentFee() && valid;
        valid = this.validateInterestRate() && valid;
        valid = this.validateLegalFees() && valid;
        valid = this.validateMaximumLvr() && valid;
        valid = this.validateNetPrepaidBalanceOnSettlement() && valid;

        if (!valid) {
            return;
        }

        const {
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFeeFormat,
            brokerageFeePercentage,
            commitmentFee,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            maximumLvr,
            termMonths,
        } = this.getDefaultedValues();

        const {
            applicationFeeTotal,
            brokerageFeeTotal,
            establishmentFeeTotal,
            grossLoanAmount,
            lvr,
        } = calculatedAmounts;

        this.props.applicationRecalculate(
            applicationFeeTotal,
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFeeTotal,
            brokerageFeeFormat,
            brokerageFeePercentage,
            commitmentFee,
            establishmentFeeTotal,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            grossLoanAmount,
            lvr,
            maximumLvr,
            termMonths,
        );

        history.push(`/applications/${application.uuid}`);
    }

    private setError(key: keyof IState['errors'], value: string): void {
        this.setState((previousState: IState) => ({
            errors: {
                ...previousState.errors,
                [key]: value,
            },
        }));
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        properties: applicationDealPropertiesSelector(state, ownProps.match.params.applicationUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        applicationRecalculate: (
            applicationFee: number,
            applicationFeeFormat: ApplicationFeeFormatEnum,
            applicationFeePercentage: number,
            brokerageFee: number,
            brokerageFeeFormat: BrokerageFeeFormatEnum,
            brokerageFeePercentage: number,
            commitmentFee: number,
            establishmentFee: number,
            establishmentFeeFormat: EstablishmentFeeFormatEnum,
            establishmentFeePercentage: number,
            estimatedOutlays: number,
            interestRate: number,
            legalFees: number,
            loanAmount: number,
            lvr: number,
            maximumLvr: number,
            termMonths: number,
        ) => dispatch(applicationRecalculateAction(
            ownProps.match.params.applicationUuid,
            applicationFee,
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFee,
            brokerageFeeFormat,
            brokerageFeePercentage,
            commitmentFee,
            establishmentFee,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            loanAmount,
            lvr,
            maximumLvr,
            termMonths,
        )),
        applicationValueSet: (key: keyof IApplication, value: boolean|number|string) => dispatch(applicationValueSetAction(ownProps.match.params.applicationUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Calculator);
