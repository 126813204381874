import IAdministrator from './IAdministrator';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformAdministrator(administrator: IAdministrator): any {
    return {
        leadsAutoAssign: administrator.leadsAutoAssign,
        dealMaximumValue: administrator.dealMaximumValue,
        dealMinimumValue: administrator.dealMinimumValue,
        email: administrator.email,
        name: administrator.name,
        permissions: administrator.permissions,
        photoUrl: administrator.photoUrl,
        role: administrator.role,
        title: administrator.title,
    };
}
