import { Button, Checkbox, Form, Input, Modal, Select, Space, Spin, Tag, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import GenderEnum from '~Api/Application/GenderEnum';
import IApplication from '~Api/Application/IApplication';
import IBorrower from '~Api/Application/IBorrower';
import IncomeTypeEnum from '~Api/Application/IncomeTypeEnum';
import LicenceStateEnum from '~Api/Application/LicenceStateEnum';
import MaritalStatusEnum from '~Api/Application/MaritalStatusEnum';
import TrusteeTypeEnum from '~Api/Application/TrusteeTypeEnum';
import IDealBorrower from '~Api/Deal/IDealBorrower';
import {
    applicationBorrowerDeleteAction,
    applicationBorrowerGetAction,
    applicationBorrowerValueSetAction,
    applicationGetAction,
} from '~Applications/actions';
import {
    applicationBorrowerSelector,
    applicationSelector,
} from '~Applications/selectors';
import { countriesGetAction } from '~Countries/actions';
import ICountry from '~Countries/ICountry';
import { countriesSelector } from '~Countries/selectors';
import { dealBorrowerValueSetAction } from '~Deals/actions';
import { applicationBorrowerDealBorrowerSelector } from '~Deals/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';
import Layout from './Layout';
import ResidencyStatusEnum from '~Api/Application/ResidencyStatusEnum';

interface IMatch {
    applicationUuid: string;
    applicationBorrowerUuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    application: IApplication;
    applicationBorrower: IBorrower;
    countries: ICountry[];
    dealBorrower: IDealBorrower;
}

interface IPropsDispatch {
    applicationBorrowerValueSet: (key: keyof IBorrower, value: any) => void;
    applicationGet: () => void;
    borrowerDelete: () => void;
    borrowerGet: () => void;
    countriesGet: () => void;
    dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: any) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Borrower extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.onChangeType = this.onChangeType.bind(this);

        this.onChangeAbn = this.onChangeAbn.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
        this.onChangeDependentNumber = this.onChangeDependentNumber.bind(this);
        this.onChangeDob = this.onChangeDob.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeJobTitle = this.onChangeJobTitle.bind(this);
        this.onChangeIncomeType = this.onChangeIncomeType.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeLicenceNumber = this.onChangeLicenceNumber.bind(this);
        this.onChangeLicenceState = this.onChangeLicenceState.bind(this);
        this.onChangeMaritalStatus = this.onChangeMaritalStatus.bind(this);
        this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
        this.onChangeMonthlyGrossExpenses = this.onChangeMonthlyGrossExpenses.bind(this);
        this.onChangeMonthlyGrossTurnover = this.onChangeMonthlyGrossTurnover.bind(this);
        this.onChangeMonthlyHouseholdExpenses = this.onChangeMonthlyHouseholdExpenses.bind(this);
        this.onChangeCreditScore = this.onChangeCreditScore.bind(this);
        this.onChangeOnTitle = this.onChangeOnTitle.bind(this);
        this.onChangeOtherIncomeType = this.onChangeOtherIncomeType.bind(this);
        this.onChangePassportCountry = this.onChangePassportCountry.bind(this);
        this.onChangePassportNumber = this.onChangePassportNumber.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeResidencyStatus = this.onChangeResidencyStatus.bind(this);
        this.onChangeTotalAssets = this.onChangeTotalAssets.bind(this);
        this.onChangeTotalLiabilities = this.onChangeTotalLiabilities.bind(this);
        this.onChangeTrustName = this.onChangeTrustName.bind(this);
        this.onChangeYearlyIncome = this.onChangeYearlyIncome.bind(this);

        this.onClickDelete = this.onClickDelete.bind(this);
    }

    public componentDidMount() {
        const { application, countries } = this.props;

        if (!application) {
            this.props.applicationGet();
        }

        if (!countries) {
            this.props.countriesGet();
        }
    }

    public render(): JSX.Element {
        const { application, applicationBorrower, countries, dealBorrower, match } = this.props;

        if (!application || !applicationBorrower || !countries || !dealBorrower) {
            return (
                <Layout applicationUuid={match.params.applicationUuid} section='borrower'>
                    <Spin />
                </Layout>
            );
        }

        const companyBlock: JSX.Element = dealBorrower.type === BorrowerTypeEnum.Company && (
            <React.Fragment>
                <Form.Item className='business-name' label='Company Name'>
                    <Input onChange={this.onChangeBusinessName} value={dealBorrower.businessName} />
                </Form.Item>
                <Form.Item className='abn' label='ACN or ABN'>
                    <Input onChange={this.onChangeAbn} value={dealBorrower.abn} />
                </Form.Item>
                <Form.Item className='monthly-gross-turnover' label='Monthly Gross Turnover'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeMonthlyGrossTurnover} type='number' value={dealBorrower.monthlyGrossTurnover} />
                </Form.Item>
                <Form.Item className='monthly-gross-expenses' label='Monthly Gross Expenses'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeMonthlyGrossExpenses} type='number' value={dealBorrower.monthlyGrossExpenses} />
                </Form.Item>
                <Form.Item className='total-assets' label='Total Assets'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalAssets} type='number' value={dealBorrower.totalAssets} />
                </Form.Item>
                <Form.Item className='total-liabilities' label='Total Liabilities'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalLiabilities} type='number' value={dealBorrower.totalLiabilities} />
                </Form.Item>
                <Form.Item className='credit-score' label='Credit Score'>
                    <Input min={0} max={1200} onChange={this.onChangeCreditScore} type='number' value={applicationBorrower.creditScore} />
                </Form.Item>
            </React.Fragment>
        );

        const jobTitleBlock: JSX.Element = [IncomeTypeEnum.PaygFullTime, IncomeTypeEnum.PaygPartTime].includes(dealBorrower.incomeType) && (
            <Form.Item className='job-title' label='Job Title'>
                <Input onChange={this.onChangeJobTitle} value={dealBorrower.jobTitle} />
            </Form.Item>
        );

        const otherIncomeTypeBlock: JSX.Element = dealBorrower.incomeType === IncomeTypeEnum.Other && (
            <Form.Item className='other-income-type' label='Other Income Details'>
                <Input onChange={this.onChangeOtherIncomeType} value={dealBorrower.otherIncomeType} />
            </Form.Item>
        );

        const selfEmployedBlock: JSX.Element = dealBorrower.incomeType === IncomeTypeEnum.SelfEmployed && (
            <React.Fragment>
                <Form.Item className='business-name' label='Business Name'>
                    <Input onChange={this.onChangeBusinessName} value={dealBorrower.businessName} />
                </Form.Item>
                <Form.Item className='abn' label='ABN'>
                    <Input onChange={this.onChangeAbn} value={dealBorrower.abn} />
                </Form.Item>
            </React.Fragment>
        );

        const individualBlock: JSX.Element = dealBorrower.type === BorrowerTypeEnum.Individual && (
            <React.Fragment>
                <Form.Item className='first-name' label='First Name'>
                    <Input onChange={this.onChangeFirstName} value={dealBorrower.firstName} />
                </Form.Item>
                <Form.Item className='middle-name' label='Middle Name(s)'>
                    <Input onChange={this.onChangeMiddleName} value={dealBorrower.middleName} />
                </Form.Item>
                <Form.Item className='last-name' label='Last Name'>
                    <Input onChange={this.onChangeLastName} value={dealBorrower.lastName} />
                </Form.Item>
                <Form.Item className='phone' label='Phone'>
                    <Input onChange={this.onChangePhone} value={dealBorrower.phone} />
                </Form.Item>
                <Form.Item className='email' label='Email'>
                    <Input onChange={this.onChangeEmail} value={dealBorrower.email} />
                </Form.Item>
                <Form.Item className='dob' label='DOB'>
                    <DatePicker onChange={this.onChangeDob} format='DD/MM/YYYY' value={dealBorrower.dob ? dayjs(dealBorrower.dob) : null} />
                </Form.Item>
                <Form.Item className='gender' label='Gender'>
                    <Select onChange={this.onChangeGender} value={dealBorrower.gender}>
                        <Select.Option value={GenderEnum.Female}>Female</Select.Option>
                        <Select.Option value={GenderEnum.Male}>Male</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='licence-state' label='Licence State'>
                    <Select onChange={this.onChangeLicenceState} value={dealBorrower.licenceState}>
                        {_.values(LicenceStateEnum).map((stateCode: string) => <Select.Option key={stateCode} value={stateCode}>{stateCode}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='licence-number' label='Licence Number'>
                    <Input onChange={this.onChangeLicenceNumber} value={dealBorrower.licenceNumber} />
                </Form.Item>
                <Form.Item className='passport-country' label='Passport Country'>
                    <Select onChange={this.onChangePassportCountry} value={dealBorrower.passportCountry}>
                        {countries.map((country: ICountry) => <Select.Option key={country.iso3} value={country.iso3}>{country.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='passport-number' label='Passport Number'>
                    <Input onChange={this.onChangePassportNumber} value={dealBorrower.passportNumber} />
                </Form.Item>
                <Form.Item className='residency-status' label='Residency Status'>
                    <Select onChange={this.onChangeResidencyStatus} value={dealBorrower.residencyStatus}>
                        <Select.Option value={ResidencyStatusEnum.Resident}>Resident</Select.Option>
                        <Select.Option value={ResidencyStatusEnum.NonResident}>Non-Resident</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='address' label='Address'>
                    <Input onChange={this.onChangeAddress} value={dealBorrower.address} />
                </Form.Item>
                <Form.Item className='dependent-number' label='Number of Dependents'>
                    <Select onChange={this.onChangeDependentNumber} value={dealBorrower.dependentNumber}>
                        {_.times(11, (n: number) => <Select.Option key={n} value={n}>{n}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item className='marital-status' label='Marital Status'>
                    <Select onChange={this.onChangeMaritalStatus} value={dealBorrower.maritalStatus}>
                        <Select.Option value={MaritalStatusEnum.Single}>Single</Select.Option>
                        <Select.Option value={MaritalStatusEnum.Married}>Married</Select.Option>
                        <Select.Option value={MaritalStatusEnum.DeFacto}>De Facto</Select.Option>
                        <Select.Option value={MaritalStatusEnum.Separated}>Separated</Select.Option>
                        <Select.Option value={MaritalStatusEnum.Divorced}>Divorced</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='income-type' label='Income Type'>
                    <Select onChange={this.onChangeIncomeType} value={dealBorrower.incomeType}>
                        <Select.Option value={IncomeTypeEnum.PaygFullTime}>PAYG - Full Time</Select.Option>
                        <Select.Option value={IncomeTypeEnum.PaygPartTime}>PAYG - Part Time</Select.Option>
                        <Select.Option value={IncomeTypeEnum.SelfEmployed}>Self Employed</Select.Option>
                        <Select.Option value={IncomeTypeEnum.Other}>Other</Select.Option>
                    </Select>
                </Form.Item>
                {otherIncomeTypeBlock}
                {jobTitleBlock}
                {selfEmployedBlock}
                <Form.Item className='yearly-income' label='Yearly Income'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeYearlyIncome} type='number' value={dealBorrower.yearlyIncome} />
                </Form.Item>
                <Form.Item className='monthly-household-expenses' extra='Monthly' label='Household Expenses'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeMonthlyHouseholdExpenses} type='number' value={dealBorrower.monthlyHouseholdExpenses} />
                </Form.Item>
                <Form.Item className='total-assets' label='Total Assets'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalAssets} type='number' value={dealBorrower.totalAssets} />
                </Form.Item>
                <Form.Item className='total-liabilities' label='Total Liabilities'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalLiabilities} type='number' value={dealBorrower.totalLiabilities} />
                </Form.Item>
                <Form.Item className='credit-score' label='Credit Score'>
                    <Input min={0} max={1200} onChange={this.onChangeCreditScore} type='number' value={applicationBorrower.creditScore} />
                </Form.Item>
            </React.Fragment>
        );

        const trusteeNameBlock: JSX.Element = dealBorrower.type === BorrowerTypeEnum.Trust && dealBorrower.trusteeName && (
            <Form.Item className='trustee-name' label='Trustee Name'>
                <Input disabled={true} value={dealBorrower.trusteeName} />
            </Form.Item>
        );

        const trusteeTypeBlock: JSX.Element = dealBorrower.type === BorrowerTypeEnum.Trust && dealBorrower.trusteeType && (
            <Form.Item className='trustee-type' label='Trustee Type'>
                <Select disabled={true} value={dealBorrower.trusteeType}>
                    <Select.Option value={TrusteeTypeEnum.Company}>Company</Select.Option>
                    <Select.Option value={TrusteeTypeEnum.Individual}>Individual</Select.Option>
                </Select>
            </Form.Item>
        );

        const trustBlock: JSX.Element = dealBorrower.type === BorrowerTypeEnum.Trust && (
            <React.Fragment>
                <Form.Item className='trust-name' label='Trust Name'>
                    <Input onChange={this.onChangeTrustName} value={dealBorrower.trustName} />
                </Form.Item>
                <Form.Item className='abn' label='ACN or ABN'>
                    <Input onChange={this.onChangeAbn} value={dealBorrower.abn} />
                </Form.Item>
                {trusteeTypeBlock}
                {trusteeNameBlock}
                <Form.Item className='total-assets' label='Total Assets'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalAssets} type='number' value={dealBorrower.totalAssets} />
                </Form.Item>
                <Form.Item className='total-liabilities' label='Total Liabilities'>
                    <Input addonBefore='$' min={0} onChange={this.onChangeTotalLiabilities} type='number' value={dealBorrower.totalLiabilities} />
                </Form.Item>
            </React.Fragment>
        );

        return (
            <Layout applicationUuid={match.params.applicationUuid} section='borrower'>
                <Space className='actions'>
                    {application.primaryBorrowerUuid === applicationBorrower.uuid && <Tag>Primary</Tag>}
                    {application.primaryBorrowerUuid !== applicationBorrower.uuid && <Button className='delete-borrower' danger={true} onClick={this.onClickDelete}>Delete</Button>}
                </Space>
                <Typography.Title level={2}>Borrower</Typography.Title>
                <Form.Item className='category' label='Category'>
                    <Select onChange={this.onChangeCategory} value={dealBorrower.category}>
                        <Select.Option value={BorrowerCategoryEnum.Borrower}>Borrower</Select.Option>
                        <Select.Option value={BorrowerCategoryEnum.Guarantor}>Guarantor</Select.Option>
                        <Select.Option value={BorrowerCategoryEnum.Trustee}>Trustee</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item className='type' label='Type'>
                    <Select onChange={this.onChangeType} value={dealBorrower.type}>
                        <Select.Option value={BorrowerTypeEnum.Company}>Company</Select.Option>
                        <Select.Option value={BorrowerTypeEnum.Individual}>Individual</Select.Option>
                        {dealBorrower.category !== BorrowerCategoryEnum.Trustee && <Select.Option value={BorrowerTypeEnum.Trust}>Trust</Select.Option>}
                    </Select>
                </Form.Item>
                {companyBlock}
                {individualBlock}
                {trustBlock}
                <Form.Item className='on-title' label='On Title'>
                    <Checkbox onChange={this.onChangeOnTitle} checked={dealBorrower.onTitle} />
                </Form.Item>
            </Layout>
        );
    }

    private onChangeCategory(value: BorrowerCategoryEnum) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'category', value);
    }

    private onChangeType(value: BorrowerTypeEnum) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'type', value);
    }

    private onChangeAbn(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'abn', event.target.value.substr(0, 11));
    }

    private onChangeAddress(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'address', event.target.value.substr(0, 255));
    }

    private onChangeBusinessName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'businessName', event.target.value.substr(0, 100));
    }

    private onChangeCreditScore(event: React.ChangeEvent<HTMLInputElement>) {
        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 1200) {
            return;
        }

        this.props.applicationBorrowerValueSet('creditScore', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeDependentNumber(value: number) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'dependentNumber', value);
    }

    private onChangeDob(date: Dayjs) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'dob', date ? date.format('YYYY-MM-DD') : null);
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'email', event.target.value.substr(0, 100));
    }

    private onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'firstName', event.target.value.substr(0, 50));
    }

    private onChangeGender(value: GenderEnum) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'gender', value);
    }

    private onChangeIncomeType(value: IncomeTypeEnum) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'incomeType', value);
    }

    private onChangeJobTitle(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'jobTitle', event.target.value.substr(0, 100));
    }

    private onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'lastName', event.target.value.substr(0, 50));
    }

    private onChangeLicenceNumber(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'licenceNumber', event.target.value.substr(0, 20));
    }

    private onChangeLicenceState(value: LicenceStateEnum) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'licenceState', value);
    }

    private onChangeMaritalStatus(value: MaritalStatusEnum) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'maritalStatus', value);
    }

    private onChangeMiddleName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'middleName', event.target.value.substr(0, 255));
    }

    private onChangeMonthlyGrossExpenses(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'monthlyGrossExpenses', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeMonthlyGrossTurnover(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'monthlyGrossTurnover', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeMonthlyHouseholdExpenses(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'monthlyHouseholdExpenses', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeOtherIncomeType(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'otherIncomeType', event.target.value.substr(0, 100));
    }

    private onChangeOnTitle(event: CheckboxChangeEvent) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'onTitle', event.target.checked);
    }

    private onChangePassportCountry(value: string) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'passportCountry', value.substr(0, 3));
    }

    private onChangePassportNumber(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'passportNumber', event.target.value.substr(0, 20));
    }

    private onChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'phone', event.target.value.substr(0, 20));
    }

    private onChangeResidencyStatus(value: ResidencyStatusEnum): void {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'residencyStatus', value);
    }

    private onChangeTotalAssets(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'totalAssets', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeTotalLiabilities(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        if (/[^0-9]/.test(event.target.value)) {
            return;
        }

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'totalLiabilities', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onChangeTrustName(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'trustName', event.target.value.substr(0, 100));
    }

    private onChangeYearlyIncome(event: React.ChangeEvent<HTMLInputElement>) {
        const { dealBorrower } = this.props;

        if (/[^0-9]/.test(event.target.value) || event.target.valueAsNumber < 0 || event.target.valueAsNumber > 99999999) {
            return;
        }

        this.props.dealBorrowerValueSet(dealBorrower.uuid, 'yearlyIncome', event.target.value === '' ? null : event.target.valueAsNumber);
    }

    private onClickDelete() {
        Modal.confirm({
            content: 'Are you sure you want to delete this borrower?',
            okText: 'Delete',
            okType: 'danger',
            onOk: () => {
                this.props.borrowerDelete();
            },
            title: 'Delete',
        });
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: applicationSelector(state, ownProps.match.params.applicationUuid),
        applicationBorrower: applicationBorrowerSelector(state, ownProps.match.params.applicationUuid, ownProps.match.params.applicationBorrowerUuid),
        countries: countriesSelector(state),
        dealBorrower: applicationBorrowerDealBorrowerSelector(state, ownProps.match.params.applicationUuid, ownProps.match.params.applicationBorrowerUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationBorrowerValueSet: (key: keyof IBorrower, value: any) => dispatch(applicationBorrowerValueSetAction(ownProps.match.params.applicationUuid, ownProps.match.params.applicationBorrowerUuid, key, value)),
        applicationGet: () => dispatch(applicationGetAction(ownProps.match.params.applicationUuid)),
        borrowerDelete: () => dispatch(applicationBorrowerDeleteAction(ownProps.match.params.applicationUuid, ownProps.match.params.applicationBorrowerUuid)),
        borrowerGet: () => dispatch(applicationBorrowerGetAction(ownProps.match.params.applicationUuid, ownProps.match.params.applicationBorrowerUuid)),
        countriesGet: () => dispatch(countriesGetAction()),
        dealBorrowerValueSet: (dealBorrowerUuid: string, key: keyof IDealBorrower, value: any) => dispatch(dealBorrowerValueSetAction(dealBorrowerUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Borrower);
