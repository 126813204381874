import { Form, InputNumber, Modal, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import { loanRecordDrawdownAction } from '~Loans/actions';
import DatePicker from '~UI/DatePicker';
import TimePicker from '~UI/TimePicker';
import constants from '~constants';

export default function RecordDrawdownModal(props: {
    loan: ILoan;
    isOpen: boolean;
    onCancel: () => void;
}): ReactElement {
    const {
        loan,
        isOpen,
        onCancel,
    } = props;

    const [ retainedFunds, setRetainedFunds ] = useState<number>(null);
    const [ retainedContingency, setRetainedContingency ] = useState<number>(null);
    const [ transactionDateTime, setTransactionDateTime ] = useState<string>(null);

    const dispatch: Dispatch = useDispatch();

    const onChangeDate: (date: Dayjs) => void = useCallback((date: Dayjs) => {
        setTransactionDateTime(dayjs(`${date.format('YYYY-MM-DD')} ${dayjs(transactionDateTime || undefined).format('HH:mm')}`).format());
    }, [transactionDateTime]);

    const onChangeTime: (time: Dayjs) => void = useCallback((time: Dayjs) => {
        setTransactionDateTime(dayjs(`${dayjs(transactionDateTime || undefined).format('YYYY-MM-DD')} ${time.format('HH:mm')}`).format());
    }, [transactionDateTime]);

    const onChangeRetainedFunds: (value: number) => void = useCallback((value: number) => {
        setRetainedFunds(value);
    }, []);

    const onChangeRetainedContingency: (value: number) => void = useCallback((value: number) => {
        setRetainedContingency(value);
    }, []);

    const transactionDateTimeDayjs: Dayjs = dayjs(transactionDateTime || undefined);

    const onClickOk: () => void = useCallback(() => {
        dispatch(loanRecordDrawdownAction(
            loan.uuid,
            retainedContingency,
            retainedFunds,
            transactionDateTimeDayjs.format(constants.DATE_ISO_FORMAT),
        ));

        onCancel();
    }, [
        dispatch,
        loan.uuid,
        onCancel,
        retainedContingency,
        retainedFunds,
        transactionDateTimeDayjs,
    ]);

    return (
        <Modal
            okText='Record Drawdown'
            onCancel={onCancel}
            onOk={onClickOk}
            open={isOpen}
            title={`Record Drawdown for ${loan.code}`}
            wrapClassName='loan-record-drawdown-modal'
        >
            <Form.Item
                label='Transaction Date'
                className='transaction-date'
            >
                <Space>
                    <DatePicker
                        className='transaction-pick-date'
                        onChange={onChangeDate}
                        value={transactionDateTimeDayjs || dayjs()}
                        format='DD/MM/YYYY'
                    />
                    <TimePicker
                        className='transaction-pick-time'
                        onChange={onChangeTime}
                        value={transactionDateTimeDayjs || dayjs()}
                        format='H:mm'
                    />
                </Space>
            </Form.Item>
            <Form.Item className='amount' label='Retained Funds'>
                <InputNumber addonBefore='$' min={0} onChange={onChangeRetainedFunds} type='number' value={retainedFunds} />
            </Form.Item>
            <Form.Item className='amount' label='Retained Contingency'>
                <InputNumber addonBefore='$' min={0} onChange={onChangeRetainedContingency} type='number' value={retainedContingency} />
            </Form.Item>
        </Modal>
    );
}
