import { fetchGet, fetchPost } from '~utilities/fetch';
import IDestinationWarehouseAmount from './IDestinationWarehouseAmount';

interface IWarehouseProposedSalesAddRequest {
    destinationWarehouseAmounts: IDestinationWarehouseAmount[];
    sourceWarehouseLoanUuid: string;
    transactionTime: string;
}

export async function warehouseAdjustInterestRateRequest(warehouseUuid: string, interestRate: number, description: string, transactionTime: string): Promise<Response> {
    return fetchPost(`/warehouses/${warehouseUuid}/adjust-interest-rate`, {
        description,
        interestRate,
        transactionTime,
    });
}

export async function warehouseAdjustPrincipalRequest(
    warehouseUuid: string,
    aNotes: number,
    amount: number,
    capacityLimit: number,
    principalDraw: number,
    sellerNotes: number,
    description: string,
    transactionTime: string,
    xNotes: number,
    prefund: number,
): Promise<Response> {
    return fetchPost(`/warehouses/${warehouseUuid}/adjust-principal`, {
        aNotes,
        amount,
        capacityLimit,
        description,
        prefund,
        principalDraw,
        sellerNotes,
        transactionTime,
        xNotes,
    });
}

export async function warehouseEligibleLoansRequest(warehouseUuid: string): Promise<Response> {
    return fetchGet(`/warehouses/${warehouseUuid}/eligible-loans`);
}

export async function warehouseLoanGetRequest(warehouseLoanUuid: string): Promise<Response> {
    return fetchGet(`/warehouse-loans/${warehouseLoanUuid}`);
}

export async function warehouseLoanSellRequest(
    warehouseLoanUuid: string,
    destinationWarehouseAmounts: IDestinationWarehouseAmount[],
    transactionTime: string,
): Promise<Response> {
    return fetchPost(`/warehouse-loans/${warehouseLoanUuid}/sell`, {
        destinationWarehouseAmounts,
        transactionTime,
    });
}

export async function warehouseLoanTransactionsListRequest(warehouseLoanUuid: string): Promise<Response> {
    return fetchGet(`/warehouse-loans/${warehouseLoanUuid}/transactions`);
}

export async function warehouseLoansListRequest(warehouseUuid: string): Promise<Response> {
    return fetchGet(`/warehouses/${warehouseUuid}/loans`);
}

export async function warehouseParametersListRequest(warehouseUuid: string): Promise<Response> {
    return fetchGet(`/warehouses/${warehouseUuid}/parameters`);
}

export async function warehousePendingApplicationsRequest(warehouseUuid: string): Promise<Response> {
    return fetchGet(`/warehouses/${warehouseUuid}/pending-applications`);
}

export async function warehouseProposedPurchaseAddRequest(
    warehouseUuid: string,
    loanUuid: string,
    transactionTime: string,
): Promise<Response> {
    return fetchPost(`/warehouses/${warehouseUuid}/propose-purchase`, {
        loanUuid,
        transactionTime,
    });
}

export async function warehouseProposedPurchaseParametersListRequest(warehouseUuid: string): Promise<Response> {
    return fetchGet(`/warehouses/${warehouseUuid}/proposed-purchase-parameters`);
}

export async function warehouseProposedSalesAddRequest(body: IWarehouseProposedSalesAddRequest): Promise<Response> {
    return fetchPost('/warehouse-proposed-sales', body);
}

export async function warehouseProposedSalesPendingListRequest(): Promise<Response> {
    return fetchGet('/warehouse-proposed-sales/pending');
}

export async function warehouseProposedSaleCancelRequest(warehouseProposedSaleUuid: string): Promise<Response> {
    return fetchPost(`/warehouse-proposed-sales/${warehouseProposedSaleUuid}/cancel`);
}

export async function warehouseProposedSaleProcessRequest(warehouseProposedSaleUuid: string): Promise<Response> {
    return fetchPost(`/warehouse-proposed-sales/${warehouseProposedSaleUuid}/process`);
}

export async function warehouseActiveReportRequest(warehouseUuid: string): Promise<Response> {
    return fetchGet(`/warehouse-reports/${warehouseUuid}/active-report`);
}

export async function warehouseTransactionsListRequest(warehouseUuid: string): Promise<Response> {
    return fetchGet(`/warehouses/${warehouseUuid}/transactions`);
}

export async function warehousesListRequest(): Promise<Response> {
    return fetchGet('/warehouses');
}

export async function warehousesLoanBookForecastRequest(): Promise<Response> {
    return fetchGet('/warehouse-reports/loan-book-forecast');
}

export async function warehousesPortfolioDashboardListRequest(): Promise<Response> {
    return fetchGet('/warehouse-reports/portfolio-dashboard');
}
