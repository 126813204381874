import { Button, Spin, Table, Typography } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import ISpPostcodeCategory from '~Api/Deal/ISpPostcodeCategory';
import zoneTypeLabels from '~Api/Deal/ZoneTypeLabels';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';
import WarehouseTypeEnum from '~Api/Warehouse/WarehouseTypeEnum';
import { dealPropertiesSpPostcodeCategoriesListAction } from '~Deals/actions';
import propertyStateLabels from '~Deals/propertyStateLabels';
import { dealPropertiesSpPostcodeCategoriesSelector } from '~Deals/selectors';
import spRegionLabels from '~Deals/spRegionLabels';
import LoanStatusTag from '~Loans/LoanStatusTag';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { currencyFormatter, percentageFormatter } from '~utilities/formatters';
import {
    warehouseEligibleLoansListAction,
    warehousesListAction,
} from '~Warehouses/actions';
import {
    warehouseEligibleLoansSelector,
    warehousesSelector,
} from '~Warehouses/selectors';
import Layout from './Layout';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import ILoan from '~Api/Loan/ILoan';
import ProposePurchaseModal from './ProposePurchaseModal';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import ModalStateEnum from '~UI/ModalStateEnum';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';

enum LoanEligibilityEnum {
    Eligible = 'ELIGIBLE',
    Ineligible = 'INELIGIBLE',
}

export default function EligibleLoans(): ReactElement {
    const { warehouseUuid } = useParams<{ warehouseUuid: string }>();

    const [ loanEligibilityFilter, setLoanEligibilityFilter ] = useState<LoanEligibilityEnum[]>([LoanEligibilityEnum.Eligible]);
    const [ loanWorkflowStatusFilter, setLoanWorkflowStatusFilter ] = useState<WorkflowStatusEnum[]>(null);
    const [ warehouseUuidFilter, setWarehouseUuidFilter ] = useState<string[]>(null);
    const [ isProposePurchaseModalOpen, setIsProposePurchaseModalOpen ] = useState<ModalStateEnum>(ModalStateEnum.Off);
    const [ currentWarehouseEligibleLoanUuid, setCurrentWarehouseEligibleLoanUuid ] = useState<string>(null);

    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const spPostcodeCategories: IDictionary<ISpPostcodeCategory> = useSelector(dealPropertiesSpPostcodeCategoriesSelector);
    const warehouseEligibleLoans: IDictionary<IWarehouseEligibleLoan> = useSelector((state: IGlobalState) => warehouseEligibleLoansSelector(state, warehouseUuid));
    const warehouses: IDictionary<IWarehouse> = useSelector(warehousesSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!spPostcodeCategories) {
            dispatch(dealPropertiesSpPostcodeCategoriesListAction());
        }
    }, [
        dispatch,
        spPostcodeCategories,
    ]);

    useEffect(() => {
        if (!warehouses) {
            dispatch(warehousesListAction());
        }
    }, [
        dispatch,
        warehouses,
    ]);

    useEffect(() => {
        dispatch(warehouseEligibleLoansListAction(warehouseUuid));
    }, [
        dispatch,
        warehouseUuid,
    ]);

    const onClickProposePurchase: (loan: ILoan) => void = useCallback((loan: ILoan) => {
        setCurrentWarehouseEligibleLoanUuid(loan.uuid);
        setIsProposePurchaseModalOpen(ModalStateEnum.Open);
    }, []);

    const onClickProposePurchaseCancel: () => void = useCallback(() => {
        setIsProposePurchaseModalOpen(ModalStateEnum.Closed);
    }, []);

    const onChangeTable: (config: TablePaginationConfig, filters: Record<string, FilterValue | null>) => void = useCallback((config: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
        setLoanEligibilityFilter((filters['loan.code'] as LoanEligibilityEnum[]) || null);
        setLoanWorkflowStatusFilter((filters['loan.workflowStatus'] as WorkflowStatusEnum[]) || null);
        setWarehouseUuidFilter((filters['warehouseLoans'] as string[]) || null);
    }, []);

    const getFilteredWarehouseEligibleLoans: () => IWarehouseEligibleLoan[] = useCallback(() => {
        return _.filter(warehouseEligibleLoans, (eligibleLoan: IWarehouseEligibleLoan) => {
            // Loan Eligibility filter
            if (loanEligibilityFilter) {
                if (!eligibleLoan.isEligible && !loanEligibilityFilter.includes(LoanEligibilityEnum.Ineligible)) {
                    return false;
                }

                if (eligibleLoan.isEligible && !loanEligibilityFilter.includes(LoanEligibilityEnum.Eligible)) {
                    return false;
                }
            }

            // Loan Workflow Status filter
            if (loanWorkflowStatusFilter && !loanWorkflowStatusFilter.includes(eligibleLoan.loan.workflowStatus)) {
                return false;
            }

            // Source Warehouse filter
            if (warehouseUuidFilter) {
                const hasMatchingWarehouse: boolean = eligibleLoan.warehouseLoans.some((warehouseLoan: IWarehouseLoan) => warehouseUuidFilter.includes(warehouseLoan.warehouseUuid));
                if (!hasMatchingWarehouse) {
                    return false;
                }
            }

            return true;
        });
    }, [
        loanEligibilityFilter,
        loanWorkflowStatusFilter,
        warehouseEligibleLoans,
        warehouseUuidFilter,
    ]);

    if (!spPostcodeCategories || !warehouseEligibleLoans || !warehouses) {
        return (
            <Layout uuid={warehouseUuid} section='eligible-loans'>
                <Typography.Title level={2}>Eligible Loans</Typography.Title>
                <Spin/>
            </Layout>
        );
    }

    const filteredWarehouses: IWarehouse[] = _.filter(warehouses, (warehouse: IWarehouse) => warehouse.type !== WarehouseTypeEnum.Private && warehouse.uuid !== warehouseUuid);
    const filteredWarehouseEligibleLoans: IWarehouseEligibleLoan[] = getFilteredWarehouseEligibleLoans();

    const columns: ColumnType<IWarehouseEligibleLoan>[] = [
        {
            dataIndex: ['loan', 'code'],
            filteredValue: loanEligibilityFilter,
            filters: [
                {
                    text: 'Eligible',
                    value: LoanEligibilityEnum.Eligible,
                },
                {
                    text: 'Ineligible',
                    value: LoanEligibilityEnum.Ineligible,
                },
            ],
            fixed: 'left',
            render: (code: string, eligibleLoan: IWarehouseEligibleLoan) =>
                <>
                    <Link to={`/loans/${eligibleLoan.loanUuid}`}>{code}</Link>
                    {eligibleLoan.loan.salesforceCode && eligibleLoan.loan.salesforceCode !== code && `(${eligibleLoan.loan.salesforceCode})`}
                </>,
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => a.loan.code.localeCompare(b.loan.code),
            title: 'Code',
            width: 150,
        },
        {
            dataIndex: ['loan', 'name'],
            render: (name: string, eligibleLoan: IWarehouseEligibleLoan) => <Link to={`/loans/${eligibleLoan.loanUuid}`}>{name}</Link>,
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => a.loan.name.localeCompare(b.loan.name),
            title: 'Name',
            width: 200,
        },
        {
            dataIndex: ['loan', 'application', 'properties'],
            render: (properties: IApplicationProperty[]) => {
                const propertyTypes: string[] = _.map(properties, (property: IApplicationProperty) => zoneTypeLabels[property.dealProperty.zoneType]);
                return _.join(propertyTypes, ', ');
            },
            title: 'Property Type',
            width: 180,
        },
        {
            dataIndex: ['loan', 'application', 'properties'],
            render: (properties: IApplicationProperty[]) => {
                const states: string[] = _.map(properties, (property: IApplicationProperty) => propertyStateLabels[property.dealProperty.state]);
                return _.join(states, ', ');
            },
            title: 'Property State',
            width: 150,
        },
        {
            dataIndex: ['loan', 'application', 'properties'],
            render: (properties: IApplicationProperty[]) => {
                const regions: string[] = _.map(properties, (property: IApplicationProperty) => spRegionLabels[spPostcodeCategories[property.dealProperty.postcode].region]);
                return _.join(regions, ', ');
            },
            title: 'S&P Region',
            width: 160,
        },
        {
            dataIndex: ['loan', 'application', 'lvr'],
            render: (lvr: number) => percentageFormatter.format(lvr / 100),
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => a.loan.application.lvr > b.loan.application.lvr ? 1 : -1,
            title: 'LVR',
            width: 100,
        },
        {
            dataIndex: ['loan', 'termMonths'],
            render: (termMonths: number) => `${termMonths} months`,
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => a.loan.termMonths > b.loan.termMonths ? 1 : -1,
            title: 'Term',
            width: 120,
        },
        {
            dataIndex: ['loan', 'endDate'],
            defaultSortOrder: 'descend',
            render: (endDate: string) => dayjs(endDate).format('Do MMMM YYYY'),
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => dayjs(a.loan.endDate) > dayjs(b.loan.endDate) ? 1 : -1,
            title: 'Maturity Date',
            width: 180,
        },
        {
            dataIndex: ['loan', 'workflowStatus'],
            filteredValue: loanWorkflowStatusFilter,
            filters: [
                {
                    text: 'Active',
                    value: WorkflowStatusEnum.Active,
                },
                {
                    text: 'Discharge',
                    value: WorkflowStatusEnum.Discharge,
                },
                {
                    text: 'Extension',
                    value: WorkflowStatusEnum.Extension,
                },
            ],
            render: (workflowStatus: WorkflowStatusEnum, eligibleLoan: IWarehouseEligibleLoan): JSX.Element => <LoanStatusTag loan={eligibleLoan.loan} />,
            title: 'Status',
            width: 150,
        },
        {
            dataIndex: ['loan', 'interestRate'],
            render: (interestRate: number) => percentageFormatter.format(interestRate / 100),
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => a.loan.interestRate > b.loan.interestRate ? 1 : -1,
            title: 'Rate',
            width: 120,
        },
        {
            dataIndex: 'warehouseLoans',
            filteredValue: warehouseUuidFilter,
            filters: _.values(filteredWarehouses).map((warehouse: IWarehouse) => ({
                text: warehouse.type,
                value: warehouse.uuid,
            })),
            render: (warehouseLoans: IWarehouseLoan[]) => {
                const warehouseTypes: string[] = _.map(warehouseLoans, (warehouseLoan: IWarehouseLoan) => warehouses[warehouseLoan.warehouseUuid].type);
                return _.join(warehouseTypes, ', ');
            },
            title: 'Warehouse',
            width: 150,
        },
        {
            dataIndex: ['loan'],
            render: (loan: ILoan) => {
                const totalRetained: number = loan.retainedContingency + loan.retainedFunds;

                return totalRetained > 0 ? currencyFormatter.format(totalRetained) : '-';
            },
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => {
                const aRetained: number = a.loan.retainedContingency + a.loan.retainedFunds;
                const bRetained: number = b.loan.retainedContingency + b.loan.retainedFunds;

                return aRetained > bRetained ? 1 : -1;
            },
            title: 'Retained',
            width: 180,
        },
        {
            dataIndex: ['loan', 'amountRemaining'],
            fixed: 'right',
            render: (amountRemaining: number) => currencyFormatter.format(amountRemaining),
            sorter: (a: IWarehouseEligibleLoan, b: IWarehouseEligibleLoan) => a.loan.amountRemaining > b.loan.amountRemaining ? 1 : -1,
            title: 'Amount Remaining',
            width: 180,
        },
    ];

    if (currentUser.permissions.includes(PermissionsEnum.WarehouseLoanSell)) {
        columns.push({
            fixed: 'right',
            render: (warehouseEligibleLoan: IWarehouseEligibleLoan) => {
                const onClick: () => void = () => onClickProposePurchase(warehouseEligibleLoan.loan);

                const proposePurchaseModal: JSX.Element = (
                    ModalStateEnum.Off !== isProposePurchaseModalOpen &&
                    warehouseEligibleLoan.loan.uuid === currentWarehouseEligibleLoanUuid && (
                        <ProposePurchaseModal
                            isOpen={ModalStateEnum.Open === isProposePurchaseModalOpen && warehouseEligibleLoan.loan.uuid === currentWarehouseEligibleLoanUuid}
                            onCancel={onClickProposePurchaseCancel}
                            loan={warehouseEligibleLoan.loan}
                            warehouseUuid={warehouseUuid}
                        />
                    )
                );
    
                return (
                    <div>
                        <Button
                            type='primary'
                            onClick={onClick}
                        >
                            Propose Purchase
                        </Button>
                        {proposePurchaseModal}
                    </div>
                );
            },
            title: 'Actions',
            width: 200,
        });
    }

    return (
        <Layout uuid={warehouseUuid} section='eligible-loans'>
            <Typography.Title level={2}>Eligible Loans</Typography.Title>
            <Table
                columns={columns}
                dataSource={filteredWarehouseEligibleLoans}
                onChange={onChangeTable}
                pagination={false}
                rowKey='uuid'
                scroll={{ x: 1, y: 800 }}
                size='middle'
            />
        </Layout>

    );
}
