import { DownOutlined } from '@ant-design/icons';
import { Alert, Col, Dropdown, Form, Input, MenuProps, Modal, Row, Space, Spin } from 'antd';
import _ from 'lodash';
import objectHash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import roundTo from 'round-to';
import IApplication from '~Api/Application/IApplication';
import IApplicationProperty from '~Api/Application/IApplicationProperty';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import ApplicationFeeFormatEnum from '~Api/Deal/ApplicationFeeFormatEnum';
import BrokerageFeeFormatEnum from '~Api/Deal/BrokerageFeeFormatEnum';
import EstablishmentFeeFormatEnum from '~Api/Deal/EstablishmentFeeFormatEnum';
import IProperty from '~Api/Deal/IProperty';
import ILoan from '~Api/Loan/ILoan';
import ILoanPayoutFigure from '~Api/Loan/ILoanPayoutFigure';
import {
    applicationsCreateRenewalFromLoanAction,
    applicationsCreateRenewalFromLoanErrorSetAction,
} from '~Applications/actions';
import {
    applicationsCreateRenewalFromLoanErrorsSelector,
    applicationsCreateRenewalFromLoanInProgressSelector,
    loanPayoutFigureApplicationSelector,
} from '~Applications/selectors';
import constants from '~constants';
import ICalculatorProperty from '~Deals/ICalculatorProperty';
import {
    DealCalculateBaseAmountEnum,
    IDealCalculateParameters,
    IDealCalculatedAmounts,
    getDealCalculatedAmounts,
} from '~Deals/utilities';
import {
    validateDealEstablishmentFee,
    validateDealInterestRate,
    validateDealLegalFees,
    validateDealMaximumLvr,
} from '~Leads/validators';
import { loanPayoutFigureGetWithLoanAndApplicationAction } from '~Loans/actions';
import {
    loanPayoutFigureDealPropertiesSelector,
    loanPayoutFigureLoanSelector,
    loanPayoutFigureSelector,
} from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';

const mortgageTypeLabels: IDictionary<string> = {
    [MortgageTypeEnum.FirstMortgage]: 'First Mortgage',
    [MortgageTypeEnum.SecondMortgage]: 'Second Mortgage',
};

interface IState {
    applicationFeeDollars: number;
    applicationFeeFormat: ApplicationFeeFormatEnum;
    applicationFeePercentage: number;
    baseAmount: DealCalculateBaseAmountEnum;
    brokerageFeeDollars: number;
    brokerageFeeFormat: BrokerageFeeFormatEnum;
    brokerageFeePercentage: number;
    calculateParametersHash: string;
    calculatedAmounts: IDealCalculatedAmounts;
    establishmentFeeDollars: number;
    establishmentFeeFormat: EstablishmentFeeFormatEnum;
    establishmentFeePercentage: number;
    estimatedOutlays: number;
    interestRate: number;
    legalFees: number;
    maximumLvr: number;
    requestedLoanAmount: number;
    requestedPayoutAmount: number;
    termMonths: number;
}

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    loanPayoutFigureUuid: string;
}

interface IPropsSelector {
    application: IApplication;
    errors: IDictionary<string>;
    inProgress: boolean;
    loan: ILoan;
    payoutFigure: ILoanPayoutFigure;
    properties: IDictionary<IProperty>;
}

interface IPropsDispatch {
    applicationsCreateRenewalFromLoan: (applicationFee: number, applicationFeeFormat: ApplicationFeeFormatEnum, applicationFeePercentage: number, brokerageFee: number, brokerageFeeFormat: BrokerageFeeFormatEnum, brokerageFeePercentage: number, establishmentFee: number, establishmentFeeFormat: EstablishmentFeeFormatEnum, establishmentFeePercentage: number, estimatedOutlays: number, interestRate: number, legalFees: number, loanAmount: number, lvr: number, maximumLvr: number, termMonths: number) => void;
    payoutFigureGetWithLoanAndApplication: () => void;
    setError: (key: string, value: string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class CreateExtensionApplicationModal extends React.Component<Props, IState> {
    public state: IState = {
        applicationFeeDollars: null,
        applicationFeeFormat: ApplicationFeeFormatEnum.Percentage,
        applicationFeePercentage: null,
        baseAmount: DealCalculateBaseAmountEnum.RequestedPayoutAmount,
        brokerageFeeDollars: null,
        brokerageFeeFormat: BrokerageFeeFormatEnum.Percentage,
        brokerageFeePercentage: null,
        calculateParametersHash: null,
        calculatedAmounts: null,
        establishmentFeeDollars: null,
        establishmentFeeFormat: EstablishmentFeeFormatEnum.Percentage,
        establishmentFeePercentage: null,
        estimatedOutlays: null,
        interestRate: null,
        legalFees: null,
        maximumLvr: null,
        requestedLoanAmount: null,
        requestedPayoutAmount: null,
        termMonths: null,
    };

    private readonly debouncedCalculateDealAmounts: () => void = null;

    constructor(props: Props) {
        super(props);

        this.onChangeApplicationFeeDollars = this.onChangeApplicationFeeDollars.bind(this);
        this.onChangeApplicationFeePercentage = this.onChangeApplicationFeePercentage.bind(this);
        this.onClickBaseAmount = this.onClickBaseAmount.bind(this);
        this.onChangeBrokerageFeeDollars = this.onChangeBrokerageFeeDollars.bind(this);
        this.onChangeBrokerageFeePercentage = this.onChangeBrokerageFeePercentage.bind(this);
        this.onChangeEstablishmentFeeDollars = this.onChangeEstablishmentFeeDollars.bind(this);
        this.onChangeEstablishmentFeePercentage = this.onChangeEstablishmentFeePercentage.bind(this);
        this.onChangeEstimatedOutlays = this.onChangeEstimatedOutlays.bind(this);
        this.onChangeInterestRate = this.onChangeInterestRate.bind(this);
        this.onChangeLegalFees = this.onChangeLegalFees.bind(this);
        this.onChangeMaximumLvr = this.onChangeMaximumLvr.bind(this);
        this.onChangeRequestedLoanAmount = this.onChangeRequestedLoanAmount.bind(this);
        this.onChangeRequestedPayoutAmount = this.onChangeRequestedPayoutAmount.bind(this);
        this.onChangeTermMonths = this.onChangeTermMonths.bind(this);

        this.onClickApplicationFeeFormat = this.onClickApplicationFeeFormat.bind(this);
        this.onClickBrokerageFeeFormat = this.onClickBrokerageFeeFormat.bind(this);
        this.onClickCreateExtensionApplication = this.onClickCreateExtensionApplication.bind(this);
        this.onClickEstablishmentFeeFormat = this.onClickEstablishmentFeeFormat.bind(this);

        this.validateEstablishmentFee = this.validateEstablishmentFee.bind(this);
        this.validateInterestRate = this.validateInterestRate.bind(this);
        this.validateLegalFees = this.validateLegalFees.bind(this);
        this.validateMaximumLvr = this.validateMaximumLvr.bind(this);

        this.debouncedCalculateDealAmounts = _.debounce(this.calculateDealAmounts, 250);
    }

    public componentDidMount(): void {
        const { application, loan, payoutFigure, properties } = this.props;

        if (!application || !loan || !payoutFigure || !properties) {
            this.props.payoutFigureGetWithLoanAndApplication();
        }

        this.calculateDealAmounts();
    }

    public componentDidUpdate(): void {
        this.debouncedCalculateDealAmounts();
    }

    public render(): JSX.Element {
        const { application, errors, inProgress, isOpen, loan, payoutFigure, properties } = this.props;
        const { calculatedAmounts } = this.state;

        if (!application || !calculatedAmounts || !loan || !payoutFigure || !properties) {
            return (
                <Modal
                    destroyOnClose={true}
                    footer={false}
                    onCancel={this.props.onClose}
                    open={isOpen}
                    title='Create Extension Application'
                    width={1200}
                    wrapClassName='loan-create-extension-application-modal'
                >
                    <Spin/>
                </Modal>
            );
        }

        const {
            applicationFeeDollars,
            applicationFeeFormat,
            applicationFeePercentage,
            baseAmount,
            brokerageFeeDollars,
            brokerageFeeFormat,
            brokerageFeePercentage,
            establishmentFeeDollars,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            maximumLvr,
            requestedLoanAmount,
            requestedPayoutAmount,
            termMonths,
        } = this.state;

        const {
            applicationFeeTotal,
            brokerageFeeTotal,
            establishmentFeeTotal,
            grossLoanAmount,
            interestPayable,
            lvr,
            netPrepaidBalanceOnSettlement,
            totalValue,
        } = calculatedAmounts;

        const interestRateDefault: number = interestRate ? roundTo(interestRate + 10, 2) : null;

        const monthlyInterestPaymentsNormal: number = Math.ceil(grossLoanAmount * (interestRate / 100) / 12);
        const monthlyInterestPaymentsDefault: number = Math.ceil(grossLoanAmount * (interestRateDefault / 100) / 12);
        const grossBalanceOnSettlement: number = grossLoanAmount - interestPayable - establishmentFeeTotal - applicationFeeTotal - brokerageFeeTotal - legalFees - estimatedOutlays;

        const totalCostsDollars: number = grossLoanAmount - netPrepaidBalanceOnSettlement;
        const totalCostsPercentage: number = Number((grossLoanAmount - netPrepaidBalanceOnSettlement) / grossLoanAmount * 100);
        const totalCostsPercentagePerAnnum: number = Number((establishmentFeeTotal + applicationFeeTotal + brokerageFeeTotal + legalFees + estimatedOutlays + (monthlyInterestPaymentsNormal * 12)) / grossLoanAmount * 100);

        const showCalculatedAmounts: boolean = !!interestRate && !!termMonths;

        const baseAmountMenu: MenuProps = {
            items: [
                {
                    key: 'base-amount',
                    label: baseAmount === DealCalculateBaseAmountEnum.GrossLoanAmount ? 'Requested Payout Amount' : 'Requested Loan Amount',
                    onClick: this.onClickBaseAmount,
                },
            ],
        };

        const baseAmountLabel: JSX.Element = (
            <Dropdown menu={baseAmountMenu}>
                <a>{baseAmount === DealCalculateBaseAmountEnum.RequestedPayoutAmount ? 'Requested Payout Amount' : 'Requested Loan Amount'} <DownOutlined/></a>
            </Dropdown>
        );

        const establishmentFeeMenu: MenuProps = {
            items: [
                {
                    key: 'establishment-fee-format',
                    label: establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickEstablishmentFeeFormat,
                },
            ],
        };

        const establishmentFeeLabel: JSX.Element = (
            <Dropdown menu={establishmentFeeMenu}>
                <a>Renewal Fee <DownOutlined /></a>
            </Dropdown>
        );

        const applicationFeeMenu: MenuProps = {
            items: [
                {
                    key: 'application-fee-format',
                    label: applicationFeeFormat === ApplicationFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickApplicationFeeFormat,
                },
            ],
        };

        const applicationFeeLabel: JSX.Element = (
            <Dropdown menu={applicationFeeMenu}>
                <a>Application Fee <DownOutlined /></a>
            </Dropdown>
        );

        const brokerageFeeMenu: MenuProps = {
            items: [
                {
                    key: 'brokerage-fee-format',
                    label: brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage ? 'Dollar Value' : 'Percentage',
                    onClick: this.onClickBrokerageFeeFormat,
                },
            ],
        };

        const brokerageFeeLabel: JSX.Element = (
            <Dropdown menu={brokerageFeeMenu}>
                <a>Brokerage Fee <DownOutlined /></a>
            </Dropdown>
        );

        const currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
            currency: 'AUD',
            style: 'currency',
        });

        const percentageFormatter: Intl.NumberFormat = Intl.NumberFormat('en-AU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: 'percent',
        });

        const establishmentFeeDollarsMinimumLimit: number = constants.APPLICATION_EXTENSION_ESTABLISHMENT_FEE_DOLLARS_MINIMUM;
        const establishmentFeePercentageMinimumLimit: number = constants.APPLICATION_EXTENSION_ESTABLISHMENT_FEE_PERCENTAGE_MINIMUM;
        const interestRateMinimumLimit: number = application.deal.interestRateMinimumOverride || (application.mortgageType === MortgageTypeEnum.SecondMortgage ? constants.DEAL_INTEREST_RATE_SECOND_MORTGAGE_MINIMUM : constants.DEAL_INTEREST_RATE_MINIMUM);
        const legalFeesDollarsMinimumLimit: number = application.deal.legalFeesDollarsMinimumOverride ?? constants.DEAL_LEGAL_FEES_DOLLARS_MINIMUM;
        const lvrMaximumLimit: number = application.deal.lvrMaximumOverride || constants.DEAL_LVR_MAXIMUM;

        // Handle API errors that are not tied to a specific form item
        const errorsWithoutFormItem: IDictionary<string> = _.omit(errors, ['establishmentFee', 'interestRate', 'legalFees', 'maximumLvr']);
        const errorsBlock: JSX.Element = _.keys(errorsWithoutFormItem).length > 0 && (
            <Alert
                className='form-error-message'
                description={<ul>{_.map(errorsWithoutFormItem, (error: string, index: string) => <li key={index}>{error}</li>)}</ul>}
                message='There was a problem creating the extension application:'
                showIcon={true}
                type='error'
            />
        );

        return (
            <Modal
                destroyOnClose={true}
                okButtonProps={{ danger: true, disabled: !!loan.extensionApplicationUuid, loading: inProgress }}
                okText='Create Extension Application'
                onCancel={this.props.onClose}
                onOk={this.onClickCreateExtensionApplication}
                open={isOpen}
                title='Create Extension Application'
                width={1200}
                wrapClassName='loan-create-extension-application-modal'
            >
                {errorsBlock}
                <Row>
                    <Col span={12}>
                        <Form.Item className='loan-amount-total' label={baseAmountLabel}>
                            {baseAmount === DealCalculateBaseAmountEnum.RequestedPayoutAmount && <Input addonBefore='$' onChange={this.onChangeRequestedPayoutAmount} type='number' value={requestedPayoutAmount} />}
                            {baseAmount === DealCalculateBaseAmountEnum.GrossLoanAmount && <Input addonBefore='$' onChange={this.onChangeRequestedLoanAmount} type='number' value={requestedLoanAmount} />}
                        </Form.Item>
                        <Form.Item className='term-months' label='Term'>
                            <Input addonAfter='months' min={1} onChange={this.onChangeTermMonths} step={1} type='number' value={termMonths} />
                        </Form.Item>
                        <Form.Item className='interest-rate' help={errors.interestRate} label='Interest Rate' validateStatus={errors.interestRate && 'error'}>
                            <Space>
                                <Input addonAfter='%' min={interestRateMinimumLimit} onBlur={this.validateInterestRate} onChange={this.onChangeInterestRate} step={0.1} type='number' value={interestRate} />
                                <span>({percentageFormatter.format(interestRateDefault / 100)} default)</span>
                            </Space>
                        </Form.Item>
                        <Form.Item className='maximum-lvr' help={errors.maximumLvr} label='Maximum LVR' validateStatus={errors.maximumLvr && 'error'}>
                            <Input addonAfter='%' max={lvrMaximumLimit} onBlur={this.validateMaximumLvr} onChange={this.onChangeMaximumLvr} type='number' value={maximumLvr} />
                        </Form.Item>
                        <Form.Item
                            className={establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? 'establishment-fee-dollars' : 'establishment-fee-percentage'}
                            help={errors.establishmentFee}
                            label={establishmentFeeLabel}
                            validateStatus={errors.establishmentFee && 'error'}
                        >
                            {establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars && <Space><Input addonBefore='$' min={establishmentFeeDollarsMinimumLimit} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeeDollars} type='number' value={establishmentFeeDollars} /><span>({percentageFormatter.format(establishmentFeeTotal / grossLoanAmount)})</span></Space>}
                            {establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage && <Space><Input addonAfter='%' min={establishmentFeePercentageMinimumLimit} onBlur={this.validateEstablishmentFee} onChange={this.onChangeEstablishmentFeePercentage} step={0.1} type='number' value={establishmentFeePercentage} /><span>({currencyFormatter.format(establishmentFeeTotal)})</span></Space>}
                        </Form.Item>
                        <Form.Item className={applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? 'application-fee-dollars' : 'application-fee-percentage'} label={applicationFeeLabel}>
                            {applicationFeeFormat === ApplicationFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeApplicationFeeDollars} type='number' value={applicationFeeDollars} /><span>({percentageFormatter.format(applicationFeeTotal / grossLoanAmount)})</span></Space>}
                            {applicationFeeFormat === ApplicationFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeApplicationFeePercentage} step={0.1} type='number' value={applicationFeePercentage} /><span>({currencyFormatter.format(applicationFeeTotal)} + GST)</span></Space>}
                        </Form.Item>
                        <Form.Item className={brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? 'brokerage-fee-dollars' : 'brokerage-fee-percentage'} label={brokerageFeeLabel}>
                            {brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars && <Space><Input addonBefore='$' addonAfter='+ GST' onChange={this.onChangeBrokerageFeeDollars} type='number' value={brokerageFeeDollars} /><span>({percentageFormatter.format(brokerageFeeTotal / grossLoanAmount)})</span></Space>}
                            {brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage && <Space><Input addonAfter='%' onChange={this.onChangeBrokerageFeePercentage} step={0.1} type='number' value={brokerageFeePercentage} /><span>({currencyFormatter.format(brokerageFeeTotal)} + GST)</span></Space>}
                        </Form.Item>
                        <Form.Item className='legal-fees' help={errors.legalFees} label='Legal Documents' validateStatus={errors.legalFees && 'error'}>
                            <Input addonBefore='$' addonAfter='+ GST' min={legalFeesDollarsMinimumLimit} onBlur={this.validateLegalFees} onChange={this.onChangeLegalFees} type='number' value={legalFees} />
                        </Form.Item>
                        <Form.Item className='approximate-outlays' label='Approximate Outlays'>
                            <Input addonBefore='$' onChange={this.onChangeEstimatedOutlays} type='number' value={estimatedOutlays} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className='mortgage-type' label='Mortgage Type'>
                            {mortgageTypeLabels[application.mortgageType]}
                        </Form.Item>
                        <Form.Item className='gross-loan-amount' label='Gross Loan Amount'>
                            {showCalculatedAmounts ? currencyFormatter.format(grossLoanAmount) : '-'}
                        </Form.Item>
                        <Form.Item className='property-value' label='Property Value'>
                            {currencyFormatter.format(totalValue)}
                        </Form.Item>
                        <Form.Item className='current-debt' label='Current Debt (Payout Figure)'>
                            {currencyFormatter.format(payoutFigure.balanceAmount)}
                        </Form.Item>
                        <Form.Item className='lvr' label='LVR'>
                            {showCalculatedAmounts ? percentageFormatter.format(lvr / 100) : '-'}
                        </Form.Item>
                        <Form.Item className='interest-payable' label='Interest Payable'>
                            {showCalculatedAmounts ? currencyFormatter.format(interestPayable) : '-'}
                        </Form.Item>
                        <Form.Item className='monthly-interest-payments' label='Monthly Interest Payments'>
                            {showCalculatedAmounts ? currencyFormatter.format(monthlyInterestPaymentsNormal) : '-'}  ({currencyFormatter.format(monthlyInterestPaymentsDefault)} default)
                        </Form.Item>
                        <Form.Item className='gross-balance-on-settlement' label='Gross Balance on Settlement'>
                            {showCalculatedAmounts ? currencyFormatter.format(grossBalanceOnSettlement) : '-'}
                        </Form.Item>
                        <Form.Item className='net-prepaid-balance-on-settlement' label='Net Balance on Settlement'>
                            {showCalculatedAmounts ? currencyFormatter.format(netPrepaidBalanceOnSettlement) : '-'}
                        </Form.Item>
                        <Form.Item className='total-costs-dollars' label='Total Costs'>
                            {showCalculatedAmounts ? currencyFormatter.format(totalCostsDollars) + ` (${percentageFormatter.format(totalCostsPercentage / 100)}, ${percentageFormatter.format(totalCostsPercentagePerAnnum / 100)} pa)` : '-'}
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        );
    }

    private calculateDealAmounts(): void {
        const { application, payoutFigure, properties } = this.props;
        const { baseAmount } = this.state;

        if (!application || !properties) {
            return;
        }

        const calculateParameters: IDealCalculateParameters = _.omit(this.state, ['baseAmount', 'calculateParametersHash', 'calculatedAmounts']);

        const calculateParametersHash: string = objectHash(calculateParameters);

        if (calculateParametersHash !== this.state.calculateParametersHash) {
            const calculatorProperties: ICalculatorProperty[] = [];

            _.forEach(application.properties, (applicationProperty: IApplicationProperty) => {
                const property: ICalculatorProperty = { ...properties[applicationProperty.dealPropertyUuid] };

                if (!!applicationProperty.valuationValue) {
                    property.valuationValue = applicationProperty.valuationValue;
                }

                // Ignore property debt on extension applications
                property.currentDebt = 0;

                calculatorProperties.push(property);
            });

            const calculatedAmounts: IDealCalculatedAmounts = getDealCalculatedAmounts({
                ...calculateParameters,
                baseDealAmount: baseAmount,
                isApplication: true,
                mortgageType: application.mortgageType,
                properties: calculatorProperties,
                totalCurrentDebt: payoutFigure.balanceAmount,
            });

            this.setState({
                calculateParametersHash,
                calculatedAmounts,
            });
        }
    }

    private onClickApplicationFeeFormat(): void {
        const { applicationFeeFormat } = this.state;

        this.setState({
            applicationFeeFormat: applicationFeeFormat === ApplicationFeeFormatEnum.Dollars ? ApplicationFeeFormatEnum.Percentage : ApplicationFeeFormatEnum.Dollars,
        });
    }

    private onClickBrokerageFeeFormat(): void {
        const { brokerageFeeFormat } = this.state;

        this.setState({
            brokerageFeeFormat: brokerageFeeFormat === BrokerageFeeFormatEnum.Dollars ? BrokerageFeeFormatEnum.Percentage : BrokerageFeeFormatEnum.Dollars,
        });
    }

    private onClickEstablishmentFeeFormat(): void {
        const { establishmentFeeFormat } = this.state;

        this.setState({
            establishmentFeeFormat: establishmentFeeFormat === EstablishmentFeeFormatEnum.Dollars ? EstablishmentFeeFormatEnum.Percentage : EstablishmentFeeFormatEnum.Dollars,
        });
    }

    private onChangeApplicationFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            applicationFeeDollars: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeApplicationFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            applicationFeePercentage: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onClickBaseAmount(): void {
        const { baseAmount } = this.state;

        this.setState({
            baseAmount: baseAmount === DealCalculateBaseAmountEnum.GrossLoanAmount ? DealCalculateBaseAmountEnum.RequestedPayoutAmount : DealCalculateBaseAmountEnum.GrossLoanAmount,
        });
    }

    private onChangeBrokerageFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            brokerageFeeDollars: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeBrokerageFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            brokerageFeePercentage: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeEstablishmentFeeDollars(event: React.ChangeEvent<HTMLInputElement>): void {
        const establishmentFeeDollars: number = Number(event.target.value);

        this.setState({
            establishmentFeeDollars: event.target.value ? establishmentFeeDollars : null,
        });
    }

    private onChangeEstablishmentFeePercentage(event: React.ChangeEvent<HTMLInputElement>): void {
        const establishmentFeePercentage: number = Number(event.target.value);

        this.setState({
            establishmentFeePercentage: event.target.value ? establishmentFeePercentage : null,
        });
    }

    private onChangeEstimatedOutlays(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            estimatedOutlays: event.target.value ? Number(event.target.value) : null,
        });
    }

    private onChangeInterestRate(event: React.ChangeEvent<HTMLInputElement>): void {
        const interestRate: number = Number(event.target.value);

        this.setState({
            interestRate: event.target.value ? interestRate : null,
        });
    }

    private onChangeLegalFees(event: React.ChangeEvent<HTMLInputElement>): void {
        const legalFees: number = Number(event.target.value);

        this.setState({
            legalFees: event.target.value ? legalFees : null,
        });
    }

    private onChangeMaximumLvr(event: React.ChangeEvent<HTMLInputElement>): void {
        const maximumLvr: number = Number(event.target.value);

        this.setState({
            maximumLvr: event.target.value ? maximumLvr : null,
        });
    }

    private onChangeRequestedLoanAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        const requestedLoanAmount: number = Number(event.target.value);

        this.setState({
            requestedLoanAmount: event.target.value ? requestedLoanAmount : null,
        });
    }

    private onChangeRequestedPayoutAmount(event: React.ChangeEvent<HTMLInputElement>): void {
        const requestedPayoutAmount: number = Number(event.target.value);

        this.setState({
            requestedPayoutAmount: event.target.value ? requestedPayoutAmount : null,
        });
    }

    private onChangeTermMonths(event: React.ChangeEvent<HTMLInputElement>): void {
        const termMonths: number = Number(event.target.value);

        this.setState({
            termMonths: event.target.value ? termMonths : null,
        });
    }

    private onClickCreateExtensionApplication(): void {
        const {
            applicationFeeFormat,
            applicationFeePercentage,
            brokerageFeeFormat,
            brokerageFeePercentage,
            calculatedAmounts,
            establishmentFeeFormat,
            establishmentFeePercentage,
            estimatedOutlays,
            interestRate,
            legalFees,
            maximumLvr,
            termMonths,
        } = this.state;

        let valid: boolean = true;

        valid = this.validateEstablishmentFee() && valid;
        valid = this.validateInterestRate() && valid;
        valid = this.validateLegalFees() && valid;
        valid = this.validateMaximumLvr() && valid;

        if (!valid) {
            return;
        }

        const {
            applicationFeeTotal,
            brokerageFeeTotal,
            establishmentFeeTotal,
            grossLoanAmount,
            lvr,
        } = calculatedAmounts;

        this.props.applicationsCreateRenewalFromLoan(
            applicationFeeTotal,
            applicationFeeFormat,
            applicationFeeFormat === ApplicationFeeFormatEnum.Percentage ? applicationFeePercentage : null,
            brokerageFeeTotal,
            brokerageFeeFormat,
            brokerageFeeFormat === BrokerageFeeFormatEnum.Percentage ? brokerageFeePercentage : null,
            establishmentFeeTotal,
            establishmentFeeFormat,
            establishmentFeeFormat === EstablishmentFeeFormatEnum.Percentage ? establishmentFeePercentage : null,
            estimatedOutlays,
            interestRate,
            legalFees,
            grossLoanAmount,
            lvr,
            maximumLvr,
            termMonths,
        );
    }

    private validateEstablishmentFee(): boolean {
        const { application } = this.props;
        const { calculatedAmounts } = this.state;

        const parameters: IDealCalculateParameters = { ...this.state };

        const { establishmentFeeTotal, grossLoanAmount } = calculatedAmounts;

        return validateDealEstablishmentFee({
            ...parameters,
            establishmentFeeDollarsMinimumOverride: application.deal.establishmentFeeDollarsMinimumOverride,
            establishmentFeePercentageMinimumOverride: application.deal.establishmentFeePercentageMinimumOverride,
            establishmentFeeTotal,
            grossLoanAmount,
            isBroker: (application.deal.isBroker || !!application.deal.brokerUuid),
        }, this.props.setError);
    }

    private validateInterestRate(): boolean {
        const { application } = this.props;

        const parameters: IDealCalculateParameters = { ...this.state };

        return validateDealInterestRate({
            ...parameters,
            interestRateMinimumOverride: application.deal.interestRateMinimumOverride,
            mortgageType: application.mortgageType,
        }, this.props.setError);
    }

    private validateLegalFees(): boolean {
        const { application } = this.props;

        const parameters: IDealCalculateParameters = { ...this.state };

        return validateDealLegalFees({
            ...parameters,
            legalFeesDollarsMinimumOverride: application.deal.legalFeesDollarsMinimumOverride,
        }, this.props.setError);
    }

    private validateMaximumLvr(): boolean {
        const { application } = this.props;
        const { calculatedAmounts } = this.state;

        const parameters: IDealCalculateParameters = { ...this.state };

        const { netPrepaidBalanceOnSettlement } = calculatedAmounts;

        return validateDealMaximumLvr({
            ...parameters,
            lvrMaximumOverride: application.deal.lvrMaximumOverride,
            netPrepaidBalanceOnSettlement,
        }, this.props.setError);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        application: loanPayoutFigureApplicationSelector(state, ownProps.loanPayoutFigureUuid),
        errors: applicationsCreateRenewalFromLoanErrorsSelector(state, ownProps.loanPayoutFigureUuid),
        inProgress: applicationsCreateRenewalFromLoanInProgressSelector(state),
        loan: loanPayoutFigureLoanSelector(state, ownProps.loanPayoutFigureUuid),
        payoutFigure: loanPayoutFigureSelector(state, ownProps.loanPayoutFigureUuid),
        properties: loanPayoutFigureDealPropertiesSelector(state, ownProps.loanPayoutFigureUuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        applicationsCreateRenewalFromLoan: (applicationFee: number, applicationFeeFormat: ApplicationFeeFormatEnum, applicationFeePercentage: number, brokerageFee: number, brokerageFeeFormat: BrokerageFeeFormatEnum, brokerageFeePercentage: number, establishmentFee: number, establishmentFeeFormat: EstablishmentFeeFormatEnum, establishmentFeePercentage: number, estimatedOutlays: number, interestRate: number, legalFees: number, loanAmount: number, lvr: number, maximumLvr: number, termMonths: number) => dispatch(applicationsCreateRenewalFromLoanAction(applicationFee, applicationFeeFormat, applicationFeePercentage, brokerageFee, brokerageFeeFormat, brokerageFeePercentage, establishmentFee, establishmentFeeFormat, establishmentFeePercentage, estimatedOutlays, interestRate, legalFees, loanAmount, ownProps.loanPayoutFigureUuid, lvr, maximumLvr, termMonths)),
        payoutFigureGetWithLoanAndApplication: () => dispatch(loanPayoutFigureGetWithLoanAndApplicationAction(ownProps.loanPayoutFigureUuid)),
        setError: (key: string, value: string) => dispatch(applicationsCreateRenewalFromLoanErrorSetAction(ownProps.loanPayoutFigureUuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateExtensionApplicationModal);
