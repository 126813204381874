import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Spin } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import {
    administratorsActiveSelector,
    administratorsSelector,
    currentAdministratorSelector,
} from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import IDeal from '~Api/Deal/IDeal';
import { leadValueSetAction } from '~Leads/actions';
import { IDictionary } from '~utilities/IDictionary';

export default function BdmSelector(props: {
    deal: IDeal;
}): ReactElement {
    const { deal } = props;

    const activeAdministrators: IAdministrator[] = useSelector(administratorsActiveSelector);
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!administrators) {
            dispatch(administratorsListAction());
        }
    }, [
        administrators,
        dispatch,
    ]);

    if (!activeAdministrators || !administrators || !currentAdministrator) {
        return (
            <Spin />
        );
    }

    const bdm: IAdministrator = !!deal.bdmUuid && administrators[deal.bdmUuid];

    if ([
        RoleEnum.BusinessDevelopmentManager,
        RoleEnum.InternalBusinessDevelopmentManager,
    ].includes(currentAdministrator.role)) {
        return <>{deal.bdmUuid ? (bdm ? bdm.name : 'Inactive User') : 'None'}</>;
    }

    const filteredBdms: IAdministrator[] = _.filter(activeAdministrators, (admin: IAdministrator) => {
        if ([
            RoleEnum.BusinessDevelopmentManager,
            RoleEnum.InternalBusinessDevelopmentManager,
            RoleEnum.SeniorBusinessDevelopmentManager,
        ].includes(admin.role)) {
            return true;
        }

        return false;
    });

    const menu: MenuProps = {
        items: [
            {
                key: 0,
                label: 'None',
                onClick: () => dispatch(leadValueSetAction(deal.uuid, 'bdmUuid', null)),
            },
            ...(_.sortBy(filteredBdms, ['name']).map((administrator: IAdministrator): ItemType => ({
                key: administrator.uuid,
                label: administrator.name,
                onClick: () => dispatch(leadValueSetAction(deal.uuid, 'bdmUuid', administrator.uuid)),
            }))),
            ...(!activeAdministrators.includes(bdm) ? [
                {
                    disabled: true,
                    key: bdm.uuid,
                    label: bdm.name,
                },
            ] : []),
        ],
        selectedKeys: deal.bdmUuid ? [deal.bdmUuid] : ['0'],
    };

    return (
        <Dropdown menu={menu}>
            <a>
                {deal.bdmUuid ? (bdm ? bdm.name : 'Inactive User') : 'None'}
                {' '}<DownOutlined/>
            </a>
        </Dropdown>
    );
}
