enum WorkflowStatusEnum {
    ConditionalApproval = 'CONDITIONAL_APPROVAL',
    Draft = 'DRAFT',
    FormalApproval = 'FORMAL_APPROVAL',
    LegalDocuments = 'LEGAL_DOCUMENTS',
    New = 'NEW',
    Settlement = 'SETTLEMENT',
    Underwriting = 'UNDERWRITING',
    Warehoused = 'WAREHOUSED',
}

export default WorkflowStatusEnum;
