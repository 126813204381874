import BorrowerCategoryEnum from '~Api/Application/BorrowerCategoryEnum';
import BorrowerTypeEnum from '~Api/Application/BorrowerTypeEnum';
import IncomeTypeEnum from '~Api/Application/IncomeTypeEnum';
import MaritalStatusEnum from '~Api/Application/MaritalStatusEnum';
import TrusteeTypeEnum from '~Api/Application/TrusteeTypeEnum';
import AddressVersionEnum from '~Api/Deal/AddressVersionEnum';
import propertyStreetTypeLabels from '~Api/Deal/PropertyStreetTypeLabels';
import ApprovalStatusEnum from './ApprovalStatusEnum';
import BankBranchStateEnum from './BankBranchStateEnum';
import CloseReasonEnum from './CloseReasonEnum';
import IDeal from './IDeal';
import IDealBorrower from './IDealBorrower';
import IDocument from './IDocument';
import IHistory from './IHistory';
import IOriginationReportLead from './IOriginationReportLead';
import INote from './INote';
import IPostcodeCategory from './IPostcodeCategory';
import IProperty from './IProperty';
import IQuote from './IQuote';
import ISpPostcodeCategory from './ISpPostcodeCategory';
import LoanPurposeEnum from './LoanPurposeEnum';
import MortgageTypeEnum from './MortgageTypeEnum';
import PaymentTypeEnum from './PaymentTypeEnum';
import PropertyPurposeEnum from './PropertyPurposeEnum';
import PropertyStateEnum from './PropertyStateEnum';
import ReferredToEnum from './ReferredToEnum';
import RegionEnum from './RegionEnum';
import RejectReasonEnum from './RejectReasonEnum';
import RepaymentStrategyEnum from './RepaymentStrategyEnum';
import RiskBandEnum from './RiskBandEnum';
import SourceEnum from './SourceEnum';
import SpRegionEnum from './SpRegionEnum';
import StrataTypeEnum from './StrataTypeEnum';
import ZoneTypeEnum from './ZoneTypeEnum';
import IMovementReportLead from './IMovementReportLead';
import { parseBroker } from '~Api/Broker/parsers';
import UiSourceEnum from '~Deals/UiSourceEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDeal(raw: any): IDeal {
    return {
        accountantEmail: raw.accountantEmail,
        accountantFirmName: raw.accountantFirmName,
        accountantName: raw.accountantName,
        accountantPhone: raw.accountantPhone,
        administratorUuid: raw.administratorUuid,
        applicationUuid: raw.applicationUuid,
        approvalStatus: raw.approvalStatus as ApprovalStatusEnum,
        bankAccountName: raw.bankAccountName,
        bankAccountNumber: raw.bankAccountNumber,
        bankBranchAddress: raw.bankBranchAddress,
        bankBranchPostcode: raw.bankBranchPostcode,
        bankBranchState: raw.bankBranchState as BankBranchStateEnum,
        bankBsb: raw.bankBsb,
        bankName: raw.bankName,
        bdmUuid: raw.bdmUuid,
        broker: raw.broker ? parseBroker(raw.broker) : null,
        brokerUuid: raw.brokerUuid,
        closeReason: raw.closeReason as CloseReasonEnum,
        closedTime: raw.closedTime,
        code: raw.code,
        comments: raw.comments,
        createdTime: raw.createdTime,
        email: raw.email,
        establishmentFeeDollarsMinimumOverride: raw.establishmentFeeDollarsMinimumOverride,
        establishmentFeePercentageMinimumOverride: raw.establishmentFeePercentageMinimumOverride,
        firstName: raw.firstName,
        followUpTime: raw.followUpTime,
        formattedName: (raw.formattedName || '').trim(),
        interestRateMinimumOverride: raw.interestRateMinimumOverride,
        isBroker: raw.isBroker,
        isReferralPartner: raw.isReferralPartner,
        landingPage: raw.landingPage,
        lastName: raw.lastName,
        lastQuoteTime: raw.lastQuoteTime,
        legacy: raw.legacy,
        legalFeesDollarsMinimumOverride: raw.legalFeesDollarsMinimumOverride,
        loanAmount: Number(raw.loanAmount),
        loanProcessorUuids: raw.loanProcessorUuids,
        loanPurpose: raw.loanPurpose as LoanPurposeEnum,
        lvrMaximumOverride: raw.lvrMaximumOverride,
        modifiedTime: raw.modifiedTime,
        mortgageType: raw.mortgageType as MortgageTypeEnum,
        otherSource: raw.otherSource,
        phone: raw.phone,
        phoneSource: raw.phoneSource,
        properties: raw.properties.map(parseDealProperty),
        quoteUuid: raw.quoteUuid,
        referralPartnerCompanyName: raw.referralPartnerCompanyName,
        referralPartnerEmail: raw.referralPartnerEmail,
        referralPartnerFirstName: raw.referralPartnerFirstName,
        referralPartnerLastName: raw.referralPartnerLastName,
        referralPartnerPhone: raw.referralPartnerPhone,
        referralPartnerUuid: raw.referralPartnerUuid,
        referredTo: raw.referredTo as ReferredToEnum,
        rejectReason: raw.rejectReason as RejectReasonEnum,
        repaymentStrategy: raw.repaymentStrategy as RepaymentStrategyEnum,
        secondsInStatusCurrent: raw.secondsInStatusCurrent,
        secondsInStatusTotal: raw.secondsInStatusTotal,
        solicitorEmail: raw.solicitorEmail,
        solicitorFirmName: raw.solicitorFirmName,
        solicitorMobile: raw.solicitorMobile,
        solicitorName: raw.solicitorName,
        solicitorPhone: raw.solicitorPhone,
        source: raw.source as SourceEnum,
        termMonths: raw.termMonths,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealBorrower(raw: any): IDealBorrower {
    const formattedName: string = (raw.type === BorrowerTypeEnum.Individual ? `${raw.firstName || ''} ${raw.lastName || ''}` : (raw.type === BorrowerTypeEnum.Company ? raw.businessName || '' : raw.trustName || '')).trim();

    return {
        abn: raw.abn,
        address: raw.address,
        businessName: raw.businessName,
        category: raw.category as BorrowerCategoryEnum,
        createdTime: raw.createdTime,
        dependentNumber: raw.dependentNumber,
        dob: raw.dob,
        email: raw.email,
        firstName: raw.firstName,
        formattedName: formattedName || '-',
        gender: raw.gender,
        incomeType: raw.incomeType as IncomeTypeEnum,
        jobTitle: raw.jobTitle,
        lastName: raw.lastName,
        licenceNumber: raw.licenceNumber,
        licenceState: raw.licenceState,
        maritalStatus: raw.maritalStatus as MaritalStatusEnum,
        middleName: raw.middleName,
        monthlyGrossExpenses: raw.monthlyGrossExpenses ? Number(raw.monthlyGrossExpenses) : null,
        monthlyGrossTurnover: raw.monthlyGrossTurnover ? Number(raw.monthlyGrossTurnover) : null,
        monthlyHouseholdExpenses: raw.monthlyHouseholdExpenses ? Number(raw.monthlyHouseholdExpenses) : null,
        onTitle: raw.onTitle,
        otherIncomeType: raw.otherIncomeType,
        passportCountry: raw.passportCountry,
        passportNumber: raw.passportNumber,
        phone: raw.phone,
        residencyStatus: raw.residencyStatus,
        totalAssets: raw.totalAssets,
        totalLiabilities: raw.totalLiabilities,
        trustName: raw.trustName,
        trusteeName: raw.trusteeName,
        trusteeType: raw.trusteeType as TrusteeTypeEnum,
        type: raw.type as BorrowerTypeEnum,
        uuid: raw.uuid,
        yearlyIncome: raw.yearlyIncome ? Number(raw.yearlyIncome) : null,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealDocument(raw: any): IDocument {
    return {
        bytes: raw.bytes,
        createdTime: raw.createdTime,
        filename: raw.filename,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealHistory(raw: any): IHistory {
    return {
        createdTime: raw.createdTime,
        description: raw.description,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealNote(raw: any): INote {
    return {
        administratorUuid: raw.administratorUuid,
        createdTime: raw.createdTime,
        note: raw.note,
        uuid: raw.uuid,
    };
}

export function getStreetAddress(property: IProperty): string {
    switch (property.addressVersion) {
        case AddressVersionEnum.V2: {
            let streetAddress: string = '';

            if (property.unitNumber && property.unitNumber.length > 0) {
                streetAddress += property.unitNumber + '/';
            }

            const streetTypeHumanReadable: string = propertyStreetTypeLabels[property.streetType];

            streetAddress += `${property.streetNumber || '-'} ${property.streetName || '-'} ${streetTypeHumanReadable || '-'}`;

            return streetAddress;
        }

        default:
            return property.streetAddress;
    }
}

export function getFormattedAddress(property: IProperty): string {
    return `${getStreetAddress(property) || '-'}, ${property.suburb || '-'} ${property.state || '-'} ${property.postcode || '-'}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealProperty(raw: any): IProperty {
    const property: IProperty = {
        addressVersion: raw.addressVersion,
        createdTime: raw.createdTime,
        currentDebt: raw.currentDebt ? Number(raw.currentDebt) : null,
        dealUuid: raw.dealUuid,
        dwellingCount: raw.dwellingCount,
        estimatedValue: raw.estimatedValue ? Number(raw.estimatedValue) : null,
        folioNumber: raw.folioNumber,
        insuranceExpiryDate: raw.insuranceExpiryDate,
        insuranceReplacementValue: raw.insuranceReplacementValue,
        loanAmount: raw.loanAmount ? Number(raw.loanAmount) : null,
        lotNumber: raw.lotNumber,
        lotSizeSquareMetres: raw.lotSizeSquareMetres,
        maximumLvr: raw.maximumLvr ? Number(raw.maximumLvr) : null,
        ownershipType: raw.ownershipType ? raw.ownershipType : null,
        planNumber: raw.planNumber,
        postcode: raw.postcode,
        purpose: raw.purpose as PropertyPurposeEnum,
        state: raw.state as PropertyStateEnum,
        strataType: raw.strataType as StrataTypeEnum,
        streetAddress: raw.streetAddress,
        streetName: raw.streetName,
        streetNumber: raw.streetNumber,
        streetType: raw.streetType,
        suburb: raw.suburb,
        tenancyType: raw.tenancyType,
        unitNumber: raw.unitNumber,
        uuid: raw.uuid,
        volumeNumber: raw.volumeNumber,
        zoneType: raw.zoneType as ZoneTypeEnum,
    };

    return {
        ...property,
        formattedAddress: getFormattedAddress(property),
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealPropertiesPostcodeCategory(raw: any): IPostcodeCategory {
    return {
        postcode: raw.postcode,
        region: raw.region as RegionEnum,
        riskBand: raw.riskBand as RiskBandEnum,
        state: raw.state as PropertyStateEnum,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealPropertiesSpPostcodeCategory(raw: any): ISpPostcodeCategory {
    return {
        postcode: raw.postcode,
        region: raw.region as SpRegionEnum,
        state: raw.state as PropertyStateEnum,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseDealQuote(raw: any): IQuote {
    return {
        administratorUuid: raw.administratorUuid,
        applicationFee: Number(raw.applicationFee),
        approvalStatus: raw.approvalStatus,
        brokerageFee: Number(raw.brokerageFee),
        code: raw.code,
        commitmentFee: Number(raw.commitmentFee),
        createdTime: raw.createdTime,
        currentDebt: Number(raw.currentDebt),
        deal: raw.deal && parseDeal(raw.deal),
        dealUuid: raw.dealUuid,
        establishmentFee: Number(raw.establishmentFee),
        estimatedOutlays: Number(raw.estimatedOutlays),
        interestRate: Number(raw.interestRate),
        legalFees: Number(raw.legalFees),
        linkCode: raw.linkCode,
        loanAmount: Number(raw.loanAmount),
        lvr: Number(raw.lvr),
        mortgageType: raw.mortgageType as MortgageTypeEnum,
        netBalance: Number(raw.netBalance),
        paymentType: raw.paymentType as PaymentTypeEnum,
        propertyValue: Number(raw.propertyValue),
        termMonths: raw.termMonths,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseOriginationReportLead(raw: any): IOriginationReportLead {
    return {
        aggregatorName: raw.aggregatorName,
        aggregatorUuid: raw.aggregatorUuid,
        bdmName: raw.bdmName,
        bdmUuid: raw.bdmUuid,
        brokerFirstName: raw.brokerFirstName,
        brokerLastName: raw.brokerLastName,
        brokerUuid: raw.brokerUuid,
        loanAmount: raw.loanAmount,
        source: raw.source as SourceEnum,
        uiSource: raw.uiSource as UiSourceEnum,
        uuid: raw.uuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseMovementReportLead(raw: any): IMovementReportLead {
    return {
        administratorName: raw.administratorName,
        administratorUuid: raw.administratorUuid,
        bdmName: raw.bdmName,
        bdmUuid: raw.bdmUuid,
        closeReason: raw.closeReason as CloseReasonEnum,
        dealUuid: raw.dealUuid,
        dealWorkflowTimeUuid: raw.dealWorkflowTimeUuid,
        loanAmount: raw.loanAmount,
        referredTo: raw.referredTo as ReferredToEnum,
        rejectReason: raw.rejectReason as RejectReasonEnum,
        workflowStatus: raw.workflowStatus as ApprovalStatusEnum,
    };
}
