import { Checkbox, Form, Input, Modal, Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { administratorsAddAction } from '~Administrators/actions';
import ActiveStatusEnum from '~Api/Administrator/ActiveStatusEnum';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import './administrators.less';
import { currencyFormatter } from '~utilities/formatters';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface IState {
    leadsAutoAssign: boolean;
    dealMaximumValue: number;
    dealMinimumValue: number;
    email: string;
    name: string;
    photoUrl: string;
    role: RoleEnum;
    title: string;
}

interface IProps {
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsDispatch {
    add: (administrator: IAdministrator) => void;
}

type Props = IProps & IPropsDispatch;

class AddModal extends React.Component<Props, IState> {
    public state: IState = {
        leadsAutoAssign: false,
        dealMaximumValue: null,
        dealMinimumValue: null,
        email: null,
        name: null,
        photoUrl: null,
        role: null,
        title: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangePhotoUrl = this.onChangePhotoUrl.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.onChangeDealMaximumValue = this.onChangeDealMaximumValue.bind(this);
        this.onChangeDealMinimumValue = this.onChangeDealMinimumValue.bind(this);
        this.onChangeAutoAssign = this.onChangeAutoAssign.bind(this);

        this.onClickOk = this.onClickOk.bind(this);
    }

    public render(): JSX.Element {
        const { isOpen } = this.props;
        const {
            leadsAutoAssign,
            dealMaximumValue,
            dealMinimumValue,
            email,
            name,
            photoUrl,
            role,
            title,
        } = this.state;

        const dealMinimumBlock: JSX.Element = leadsAutoAssign && (
            <Form.Item label='Deal Minimum' className='deal-minimum-value' extra={currencyFormatter.format(dealMinimumValue)}>
                <Input addonBefore='$' onChange={this.onChangeDealMinimumValue} type='number' value={dealMinimumValue} />
            </Form.Item>
        );

        const dealMaximumBlock: JSX.Element = leadsAutoAssign && (
            <Form.Item label='Deal Maximum' className='deal-maximum-value' extra={currencyFormatter.format(dealMaximumValue)}>
                <Input addonBefore='$' onChange={this.onChangeDealMaximumValue} type='number' value={dealMaximumValue} />
            </Form.Item>
        );

        return (
            <Modal
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Add Administrator'
                wrapClassName='administrator-add-modal'
            >
                <Form.Item label='Name' className='name'>
                    <Input onChange={this.onChangeName} value={name} />
                </Form.Item>
                <Form.Item label='Email' className='email'>
                    <Input onChange={this.onChangeEmail} value={email} />
                </Form.Item>
                <Form.Item label='Photo URL' className='photo-url'>
                    <Input onChange={this.onChangePhotoUrl} value={photoUrl} />
                </Form.Item>
                <Form.Item label='Title' className='title'>
                    <Input onChange={this.onChangeTitle} value={title} />
                </Form.Item>
                <Form.Item label='Role' className='role'>
                    <Select onChange={this.onChangeRole} value={role}>
                        <Select.Option value={null}>None</Select.Option>
                        <Select.Option key={RoleEnum.BusinessDevelopmentManager} value={RoleEnum.BusinessDevelopmentManager}>Business Development Manager</Select.Option>
                        <Select.Option key={RoleEnum.CreditManager} value={RoleEnum.CreditManager}>Credit Manager</Select.Option>
                        <Select.Option key={RoleEnum.InternalBusinessDevelopmentManager} value={RoleEnum.InternalBusinessDevelopmentManager}>Internal Business Development Manager</Select.Option>
                        <Select.Option key={RoleEnum.InvestorManager} value={RoleEnum.InvestorManager}>Investor Manager</Select.Option>
                        <Select.Option key={RoleEnum.LoanManager} value={RoleEnum.LoanManager}>Loan Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorBusinessDevelopmentManager} value={RoleEnum.SeniorBusinessDevelopmentManager}>Senior Business Development Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorCreditManager} value={RoleEnum.SeniorCreditManager}>Senior Credit Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorInvestorManager} value={RoleEnum.SeniorInvestorManager}>Senior Investor Manager</Select.Option>
                        <Select.Option key={RoleEnum.SeniorLoanManager} value={RoleEnum.SeniorLoanManager}>Senior Loan Manager</Select.Option>
                        <Select.Option key={RoleEnum.SuperAdmin} value={RoleEnum.SuperAdmin}>Super Admin</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Auto Assign Leads'>
                    <Checkbox onChange={this.onChangeAutoAssign} checked={leadsAutoAssign} />
                </Form.Item>
                {dealMinimumBlock}
                {dealMaximumBlock}
            </Modal>
        );
    }

    private onChangeAutoAssign(event: CheckboxChangeEvent): void {
        this.setState({
            leadsAutoAssign: event.target.checked,
        });
    }

    private onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            name: event.target.value,
        });
    }

    private onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    private onChangePhotoUrl(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            photoUrl: event.target.value,
        });
    }

    private onChangeTitle(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            title: event.target.value,
        });
    }

    private onChangeRole(value: RoleEnum): void {
        this.setState({
            role: value,
        });
    }

    private onChangeDealMaximumValue(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            dealMaximumValue: event.target.value.length > 0 ? Number(event.target.value) : null,
        });
    }

    private onChangeDealMinimumValue(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            dealMinimumValue: event.target.value.length > 0 ? Number(event.target.value) : null,
        });
    }

    private onClickOk(): void {
        const {
            leadsAutoAssign,
            dealMaximumValue,
            dealMinimumValue,
            email,
            name,
            photoUrl,
            role,
            title,
        } = this.state;

        this.props.onCancel();

        const administrator: IAdministrator = {
            leadsAutoAssign,
            dealMaximumValue,
            dealMinimumValue,
            email,
            name,
            permissions: [],
            photoUrl,
            role,
            status: ActiveStatusEnum.Active,
            title,
        };

        this.props.add(administrator);
    }
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        add: (administrator: IAdministrator) => dispatch(administratorsAddAction(administrator)),
    };
}

export default connect(
    null,
    mapDispatchToProps,
)(AddModal);
