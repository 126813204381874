import { applyMiddleware, compose, createStore, Store } from 'redux';
import reduxSaga, { SagaMiddleware } from 'redux-saga';

import reducer from './reducer';
import sagas from './sagas';

const sagaMiddleware: SagaMiddleware = reduxSaga();

const composeEnhancers =
    typeof window === 'object' &&
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const store: Store = createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(sagaMiddleware),
    ),
);

sagaMiddleware.run(sagas);

export default store;
