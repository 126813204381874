import { Spin, Tag, Tooltip } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import IPostcodeCategory from '~Api/Deal/IPostcodeCategory';
import IProperty from '~Api/Deal/IProperty';
import RegionEnum from '~Api/Deal/RegionEnum';
import RiskBandEnum from '~Api/Deal/RiskBandEnum';
import ZoneTypeEnum from '~Api/Deal/ZoneTypeEnum';
import { IDictionary } from '~utilities/IDictionary';
import { dealPropertiesPostcodeCategoriesListAction, dealPropertiesSpPostcodeCategoriesListAction } from './actions';
import propertyStateLabels from './propertyStateLabels';
import { dealPropertiesPostcodeCategoriesSelector, dealPropertiesSpPostcodeCategoriesSelector } from './selectors';
import ISpPostcodeCategory from '~Api/Deal/ISpPostcodeCategory';
import spRegionLabels from './spRegionLabels';

const regionLabels: IDictionary<string> = {
    [RegionEnum.Metro]: 'Metro',
    [RegionEnum.MetroHighDensity]: 'Metro (High Density)',
    [RegionEnum.Regional]: 'Regional',
    [RegionEnum.RegionalHighDensity]: 'Regional (High Density)',
};

const regionHighDensityMaps: IDictionary<RegionEnum> = {
    [RegionEnum.MetroHighDensity]: RegionEnum.Metro,
    [RegionEnum.RegionalHighDensity]: RegionEnum.Regional,
};

const riskBandLabels: IDictionary<string> = {
    [RiskBandEnum.BandOne]: '1',
    [RiskBandEnum.BandTwo]: '2',
    [RiskBandEnum.BandThree]: '3',
    [RiskBandEnum.BandZero]: '0',
};

export default function PostcodeCategory(props: {
    property: IProperty;
}): ReactElement {
    const { property } = props;

    const postcodeCategories: IDictionary<IPostcodeCategory> = useSelector(dealPropertiesPostcodeCategoriesSelector);
    const spPostcodeCategories: IDictionary<ISpPostcodeCategory> = useSelector(dealPropertiesSpPostcodeCategoriesSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!postcodeCategories) {
            dispatch(dealPropertiesPostcodeCategoriesListAction());
        }
    }, [
        dispatch,
        postcodeCategories,
    ]);

    useEffect(() => {
        if (!spPostcodeCategories) {
            dispatch(dealPropertiesSpPostcodeCategoriesListAction());
        }
    }, [
        dispatch,
        spPostcodeCategories,
    ]);

    if (!postcodeCategories || !spPostcodeCategories) {
        return <Spin size='small' />;
    }

    let genworthBlock: JSX.Element;
    const postcodeCategory: IPostcodeCategory = postcodeCategories[property.postcode];
    if (postcodeCategory) {
        const isHighDensity: boolean = (property.zoneType === ZoneTypeEnum.ResidentialUnit);
        let region: RegionEnum = postcodeCategory.region;
        if (!isHighDensity && regionHighDensityMaps[postcodeCategory.region]) {
            region = regionHighDensityMaps[postcodeCategory.region];
        }
        genworthBlock = (
            <Tooltip title='Genworth Category'>
                <Tag>{`${propertyStateLabels[postcodeCategory.state]} ${regionLabels[region]} Cat ${riskBandLabels[postcodeCategory.riskBand]}`}</Tag>
            </Tooltip>
        );
    }

    let spBlock: JSX.Element;
    if (spPostcodeCategories[property.postcode]) {
        spBlock = (
            <Tooltip title='S&P Category'>
                <Tag>{spRegionLabels[spPostcodeCategories[property.postcode].region]}</Tag>
            </Tooltip>
        );
    }

    return (
        <>
            {genworthBlock}
            {spBlock}
        </>
    );
}
