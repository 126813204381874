import { FilterFilled, FilterTwoTone } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Layout, Modal, Row, Space, Spin, Table, Tree, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Dispatch } from 'redux';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import CloseReasonEnum from '~Api/Application/CloseReasonEnum';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import MortgageTypeEnum from '~Api/Application/MortgageTypeEnum';
import WorkflowStatusEnum from '~Api/Application/WorkflowStatusEnum';
import { IDictionary } from '~utilities/IDictionary';
import ITreeStructureGroup from '~utilities/ITreeStructureGroup';
import { applicationsSettlementForecastListAction } from './actions';
import { settlementForecastApplicationsSelector } from './selectors';
import CheckedKeys from '~utilities/CheckedKeys';
import ModalStateEnum from '~UI/ModalStateEnum';
import { currencyFormatter } from '~utilities/formatters';

const applicationStatusLabels: IDictionary<string> = {
    [WorkflowStatusEnum.ConditionalApproval]: 'Conditional Approval',
    [WorkflowStatusEnum.Draft]: 'Draft',
    [WorkflowStatusEnum.FormalApproval]: 'Formal Approval',
    [WorkflowStatusEnum.LegalDocuments]: 'Legal Documents',
    [WorkflowStatusEnum.New]: 'New',
    [WorkflowStatusEnum.Settlement]: 'Settlement',
    [WorkflowStatusEnum.Underwriting]: 'Underwriting',
    [WorkflowStatusEnum.Warehoused]: 'Warehoused',
};

const unassignedKey: string = 'UNASSIGNED';

enum ChannelTreeGroupTypeEnum {
    Broker = 'BROKER',
    Direct = 'DIRECT',
}

interface IBarData {
    extensionsSettled: number;
    extensionsUnsettled: number;
    month: string;
    newLoansSettled: number;
    newLoansUnsettled: number;
    totalSettled: number;
    totalUnsettled: number;
}

interface IDay {
    applicationCount: number;
    date: string;
    loanTotal: number;
    primaryRowUuid?: string;
}

interface IForecast {
    application?: IApplication;
    date: string;
    uuid: string;
}

const tooltipCurrencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-AU', {
    currency: 'AUD',
    minimumFractionDigits: 0,
    style: 'currency',
});

export default function SettlementForecast(): ReactElement {
    const [ bdmFilter, setBdmFilter] = useState<string[]>([]);
    const [ channelFilter, setChannelFilter] = useState<string[]>([]);
    const [ isChangeFiltersModalOpen, setIsChangeFiltersModalOpen] = useState<ModalStateEnum>(ModalStateEnum.Off);
    const [ mortgageTypeFilter, setMortgageTypeFilter] = useState<string[]>([]);

    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const applications: IDictionary<IApplication> = useSelector(settlementForecastApplicationsSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!administrators) {
            dispatch(administratorsListAction());
        }
    }, [
        administrators,
        dispatch,
    ]);

    useEffect(() => {
        dispatch(applicationsSettlementForecastListAction());

        const refreshInterval: NodeJS.Timeout = setInterval(() => {
            dispatch(applicationsSettlementForecastListAction());
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(refreshInterval);
        };
    }, [dispatch]);

    const onChangeBdmFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys): void => {
        setBdmFilter(checkedKeys as string[]);
    }, []);

    const onChangeChannelFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys) => {
        setChannelFilter(checkedKeys as string[]);
    }, []);

    const onChangeMortgageTypeFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys) => {
        setMortgageTypeFilter(checkedKeys as string[]);
    }, []);

    const onCancelChangeFilters: () => void = useCallback(() => {
        setIsChangeFiltersModalOpen(ModalStateEnum.Closed);
    }, []);

    const onClickChangeFilters: () => void = useCallback(() => {
        setIsChangeFiltersModalOpen(ModalStateEnum.Open);
    }, []);

    const tooltipFormatter: (value: number) => string = useCallback((value: number) => {
        return tooltipCurrencyFormatter.format(value);
    }, []);

    if (!administrators || !applications) {
        return (
            <Layout className='applications forecast'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Settlement Forecast</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Button disabled={true} icon={<FilterFilled/>} />
                        </Space>
                        <Typography.Title level={2}>Settlement Forecast</Typography.Title>
                        <Spin />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const days: IDictionary<IDay> = {};
    const expiredSettlements: IApplication[] = [];
    const forecasts: IForecast[] = [];
    const months: IDictionary<IBarData> = {};
    const visibleBdms: IDictionary<IAdministrator> = {};

    for (let i: number = 0; i < 45; i++) {
        const loopDate: dayjs.Dayjs = dayjs().add(i, 'days');
        days[loopDate.format('YYYY-MM-DD')] = {
            applicationCount: 0,
            date: loopDate.format('YYYY-MM-DD'),
            loanTotal: 0,
        };
        forecasts.push({
            date: loopDate.format('YYYY-MM-DD'),
            uuid: loopDate.format('YYYY-MM-DD'),
        });
    }

    for (let i: number = -5; i <= 2; i++) {
        const loopDate: dayjs.Dayjs = dayjs().add(i, 'months');
        months[loopDate.format('YYYY-MM')] = {
            extensionsSettled: 0,
            extensionsUnsettled: 0,
            month: loopDate.format('MMMM'),
            newLoansSettled: 0,
            newLoansUnsettled: 0,
            totalSettled: 0,
            totalUnsettled: 0,
        };
    }

    const checkDate: Dayjs = dayjs();
    _.each(applications, (application: IApplication) => {
        const { deal } = application;

        if (!application.settlementDate) {
            return;
        }

        const bdm: IAdministrator = administrators[deal.bdmUuid];

        if (deal.bdmUuid && !visibleBdms[bdm.uuid]) {
            visibleBdms[bdm.uuid] = bdm;
        }

        if (bdmFilter.length > 0) {
            let shouldShow: boolean = false;
            if (!deal.bdmUuid && bdmFilter.includes(unassignedKey)) {
                shouldShow = true;
            } else if (deal.bdmUuid && bdmFilter.includes(bdm.uuid)) {
                shouldShow = true;
            }
            if (!shouldShow) {
                return;
            }
        }

        if (channelFilter.length > 0) {
            let shouldShow: boolean = false;
            if (channelFilter.includes(ChannelTreeGroupTypeEnum.Broker) && (deal.brokerUuid || deal.isBroker)) {
                shouldShow = true;
            }
            if (channelFilter.includes(ChannelTreeGroupTypeEnum.Direct) && !deal.brokerUuid && !deal.isBroker) {
                shouldShow = true;
            }
            if (!shouldShow) {
                return;
            }
        }

        if (mortgageTypeFilter.length > 0) {
            let shouldShow: boolean = false;
            if (application.mortgageType && mortgageTypeFilter.includes(application.mortgageType)) {
                shouldShow = true;
            }
            if (!shouldShow) {
                return;
            }
        }

        const settlementDateDaysjs: Dayjs = dayjs(application.settlementDate);
        const settlementMonth: string = settlementDateDaysjs.format('YYYY-MM');
        const settlementDate: string = settlementDateDaysjs.format('YYYY-MM-DD');

        if (application.extensionType !== ExtensionTypeEnum.GracePeriod && (!application.closeReason || application.closeReason === CloseReasonEnum.Settled)) {
            // Settled or open and not a grace period type
            if (application.workflowStatus === WorkflowStatusEnum.Warehoused) {
                // Settled
                if (months[settlementMonth]) {
                    months[settlementMonth].totalSettled += application.loanAmount;

                    if (application.extensionNumber) {
                        months[settlementMonth].extensionsSettled += application.loanAmount;
                    } else {
                        months[settlementMonth].newLoansSettled += application.loanAmount;
                    }
                }
            } else {
                // Unsettled
                if (settlementDateDaysjs.isBefore(checkDate, 'day')) {
                    // Expired (before today)
                    expiredSettlements.push(application);
                }

                if (months[settlementMonth]) {
                    // Today or future
                    months[settlementMonth].totalUnsettled += application.loanAmount;

                    if (application.extensionNumber) {
                        months[settlementMonth].extensionsUnsettled += application.loanAmount;
                    } else {
                        months[settlementMonth].newLoansUnsettled += application.loanAmount;
                    }

                    if (!days[settlementDate]) {
                        return;
                    }

                    days[settlementDate].applicationCount++;
                    days[settlementDate].loanTotal += application.loanAmount;

                    if (!days[settlementDate].primaryRowUuid) {
                        days[settlementDate].primaryRowUuid = application.uuid;
                    }

                    const initialForecast: IForecast = _.find(forecasts, (forecast: IForecast) => forecast.date === settlementDate);
                    if (!initialForecast.application) {
                        initialForecast.application = application;
                    } else {
                        forecasts.push({
                            application,
                            date: settlementDate,
                            uuid: application.uuid,
                        });
                    }
                }
            }
        }
    });

    const columns: ColumnsType<IForecast> = [
        {
            className: 'date',
            render: (forecast: IForecast) => {
                if (!forecast.application) {
                    return dayjs(forecast.date).format('D/M');
                }

                const day: IDay = days[forecast.date];

                return {
                    children: dayjs(day.date).format('D/M'),
                    props: {
                        rowSpan: day.primaryRowUuid === forecast.application.uuid ? day.applicationCount : 0,
                    },
                };
            },
            title: 'Date',
            width: '10%',
        },
        {
            className: 'day',
            render: (forecast: IForecast) => {
                if (!forecast.application) {
                    return dayjs(forecast.date).format('dddd');
                }

                const day: IDay = days[forecast.date];

                return {
                    children: dayjs(day.date).format('dddd'),
                    props: {
                        rowSpan: day.primaryRowUuid === forecast.application.uuid ? day.applicationCount : 0,
                    },
                };
            },
            title: 'Day',
            width: '10%',
        },
        {
            className: 'total',
            render: (forecast: IForecast) => {
                if (!forecast.application) {
                    return '-';
                }

                const day: IDay = days[forecast.date];

                return {
                    children: day.loanTotal > 0 ? currencyFormatter.format(day.loanTotal) : '-',
                    props: {
                        rowSpan: day.primaryRowUuid === forecast.application.uuid ? day.applicationCount : 0,
                    },
                };
            },
            title: 'Total',
            width: '15%',
        },
        {
            dataIndex: 'application',
            render: (application: IApplication) => application ? (application.code) : null,
            title: 'Code',
            width: '10%',
        },
        {
            dataIndex: 'application',
            render: (application: IApplication) => {
                return {
                    children: application ? (
                        <Row key={application.uuid}>
                            <Link to={`/applications/${application.uuid}`}>{application.formattedName}</Link>
                        </Row>
                    ) : null,
                };
            },
            title: 'Name',
        },
        {
            dataIndex: 'application',
            render: (application: IApplication) => application && application.loanAmount > 0 ? currencyFormatter.format(application.loanAmount) : null,
            title: 'Amount',
            width: '15%',
        },
        {
            dataIndex: 'application',
            render: (application: IApplication) => application ? applicationStatusLabels[application.workflowStatus] : null,
            title: 'Status',
            width: '15%',
        },
    ];

    const expiredColumns: ColumnsType<IApplication> = [
        {
            dataIndex: 'settlementDate',
            render: (settlementDate: string) => dayjs(settlementDate).format('D/M'),
            title: 'Settlement Date',
            width: '10%',
        },
        {
            dataIndex: 'code',
            title: 'Code',
            width: '10%',
        },
        {
            render: (application: IApplication) => <Link to={`/applications/${application.uuid}`}>{application.formattedName}</Link>,
            title: 'Name',
        },
        {
            dataIndex: 'loanAmount',
            render: (loanAmount: number) => loanAmount > 0 ? currencyFormatter.format(loanAmount) : '-',
            title: 'Amount',
            width: '15%',
        },
        {
            dataIndex: 'workflowStatus',
            render: (workflowStatus: WorkflowStatusEnum) => applicationStatusLabels[workflowStatus],
            title: 'Status',
            width: '15%',
        },
    ];

    const hasPastDays: boolean = _.size(expiredSettlements) > 0;
    const pastSettlementBlock: JSX.Element = hasPastDays && (
        <div className='expired-settlements'>
            <h3>Expired Settlements</h3>
            <Table
                columns={expiredColumns}
                dataSource={_.orderBy(expiredSettlements, ['settlementDate'], ['asc'])}
                pagination={false}
                rowClassName='forecast-expired-settlement-row'
                rowKey='uuid'
                size='middle'
            />
        </div>
    );

    let changeFiltersModal: JSX.Element;

    if (ModalStateEnum.Off !== isChangeFiltersModalOpen) {
        const bdmTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: unassignedKey,
                title: 'Unassigned',
                value: unassignedKey,
            },
        ];

        _.forEach(_.sortBy(visibleBdms, ['name']), (loopAdmin: IAdministrator) => {
            if ([RoleEnum.BusinessDevelopmentManager, RoleEnum.InternalBusinessDevelopmentManager, RoleEnum.SeniorBusinessDevelopmentManager].includes(loopAdmin.role)) {
                bdmTreeData.push({
                    children: [],
                    key: loopAdmin.uuid,
                    title: loopAdmin.name,
                    value: loopAdmin.uuid,
                });
            }
        });

        const channelTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: ChannelTreeGroupTypeEnum.Broker,
                title: 'Broker',
                value: ChannelTreeGroupTypeEnum.Broker,
            },
            {
                children: [],
                key: ChannelTreeGroupTypeEnum.Direct,
                title: 'Direct',
                value: ChannelTreeGroupTypeEnum.Direct,
            },
        ];

        const mortgageTypeTreeData: ITreeStructureGroup[] = [
            {
                children: [],
                key: MortgageTypeEnum.FirstMortgage,
                title: 'First Mortgage',
                value: MortgageTypeEnum.FirstMortgage,
            },
            {
                children: [],
                key: MortgageTypeEnum.SecondMortgage,
                title: 'Second Mortgage',
                value: MortgageTypeEnum.SecondMortgage,
            },
        ];

        changeFiltersModal = (
            <Modal
                onCancel={onCancelChangeFilters}
                onOk={onCancelChangeFilters}
                open={ModalStateEnum.Open === isChangeFiltersModalOpen}
                title='Change Filters'
                wrapClassName='settlement-forecast-change-filters-modal'
            >
                <Form.Item label='Channel'>
                    <Tree
                        checkable={true}
                        treeData={channelTreeData}
                        checkedKeys={channelFilter}
                        onCheck={onChangeChannelFilter}
                        defaultExpandAll={true}
                        selectable={false}
                    />
                </Form.Item>
                <Form.Item label='BDMs'>
                    <Tree
                        checkable={true}
                        treeData={bdmTreeData}
                        checkedKeys={bdmFilter}
                        onCheck={onChangeBdmFilter}
                        defaultExpandAll={true}
                        selectable={false}
                    />
                </Form.Item>
                <Form.Item label='Mortgage Types'>
                    <Tree
                        checkable={true}
                        treeData={mortgageTypeTreeData}
                        checkedKeys={mortgageTypeFilter}
                        onCheck={onChangeMortgageTypeFilter}
                        defaultExpandAll={true}
                        selectable={false}
                    />
                </Form.Item>
            </Modal>
        );
    }

    return (
        <Layout className='applications forecast'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/applications'>Applications</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Settlement Forecast</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Space className='actions'>
                        <Button onClick={onClickChangeFilters} icon={(bdmFilter.length > 0 || channelFilter.length > 0 || mortgageTypeFilter.length > 0) ? <FilterTwoTone/> : <FilterFilled/>} />
                    </Space>
                    <Typography.Title level={2}>Settlement Forecast</Typography.Title>
                    <ResponsiveContainer width='97%' height={250}>
                        <BarChart data={_.values(months)}>
                            <XAxis dataKey='month' />
                            <YAxis tickFormatter={tooltipFormatter} width={100} />
                            <Tooltip formatter={tooltipFormatter} />
                            <Bar dataKey='extensionsSettled' fill='#f06305' name='Extensions Settled' stackId='extensions' />
                            <Bar dataKey='extensionsUnsettled' fill='#f29352' name='Extensions Unsettled' stackId='extensions' />
                            <Bar dataKey='newLoansSettled' fill='#006eb8' name='New Loans Settled' stackId='newLoans' />
                            <Bar dataKey='newLoansUnsettled' fill='#639bbf' name='New Loans Unsettled' stackId='newLoans' />
                            <Bar dataKey='totalSettled' fill='#00c48c' name='Total Settled' stackId='total' />
                            <Bar dataKey='totalUnsettled' fill='#7ddfc3' name='Total Unsettled' stackId='total' />
                        </BarChart>
                    </ResponsiveContainer>
                    {pastSettlementBlock}
                    <div className='forecast-current'>
                        {hasPastDays && <h3>Current Forecast</h3>}
                        <Table
                            columns={columns}
                            dataSource={_.sortBy(forecasts, ['date'], ['desc'])}
                            pagination={false}
                            rowKey='uuid'
                            size='middle'
                        />
                    </div>
                </Layout.Content>
            </Layout>
            {changeFiltersModal}
        </Layout>
    );
}
