import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import { IDictionary } from '~utilities/IDictionary';

const statusLabels: IDictionary<string> = {
    [LoanStatusEnum.ActiveBadStanding]: 'Active - Bad Standing',
    [LoanStatusEnum.ActiveGoodStanding]: 'Active - Good Standing',
    [LoanStatusEnum.ActiveMatured]: 'Active - Matured',
    [LoanStatusEnum.Approved]: 'Approved',
    [LoanStatusEnum.Cancelled]: 'Cancelled',
    [LoanStatusEnum.ClosedObligationsMet]: 'Closed - Obligations Met',
    [LoanStatusEnum.ClosedRefinanced]: 'Closed - Refinanced',
    [LoanStatusEnum.ClosedWrittenOff]: 'Closed - Written Off',
    [LoanStatusEnum.PartialApplication]: 'Partial Application',
    [LoanStatusEnum.PendingApproval]: 'Pending Approval',
};

export default statusLabels;