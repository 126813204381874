import { Alert, Checkbox, Form, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import ExtensionTypeEnum from '~Api/Application/ExtensionTypeEnum';
import IApplication from '~Api/Application/IApplication';
import { applicationWarehousedAction } from '~Applications/actions';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import { IGlobalState } from '~reducer';
import DatePicker from '~UI/DatePicker';

interface IState {
    sendEmail: boolean;
    settlementDate: string;
}

interface IProps {
    application: IApplication;
    isOpen: boolean;
    onCancel: () => void;
}

interface IPropsSelector {
    currentUser: IAuthUser;
}

interface IPropsDispatch {
    warehoused: (settlementDate: string, sendEmail: boolean, force: boolean) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class SettledModal extends React.Component<Props, IState> {
    public state: IState = {
        sendEmail: true,
        settlementDate: null,
    };

    constructor(props: Props) {
        super(props);

        this.onChangeSettlementDate = this.onChangeSettlementDate.bind(this);
        this.onChangeSendEmail = this.onChangeSendEmail.bind(this);
        this.onClickOk = this.onClickOk.bind(this);

        this.state.settlementDate = dayjs().format('YYYY-MM-DD');
    }

    public render(): JSX.Element {
        const { application, isOpen } = this.props;
        const { sendEmail, settlementDate } = this.state;

        const isGracePeriod: boolean = ExtensionTypeEnum.GracePeriod === application.extensionType;
        const isRenewal: boolean = ExtensionTypeEnum.Renewal === application.extensionType;
        const dealEmailExists: boolean = !!application.deal.email;

        const settlementDateBlock: JSX.Element = !isRenewal && (
            <Form.Item label='Settlement Date' className='settlement-date'>
                <DatePicker onChange={this.onChangeSettlementDate} format='DD/MM/YYYY' value={settlementDate ? dayjs(settlementDate) : null} />
            </Form.Item>
        );

        const sendEmailBlock: JSX.Element = !isGracePeriod && dealEmailExists && (
            <Form.Item className='send-email' label='Email Borrower'>
                <Checkbox onChange={this.onChangeSendEmail} checked={sendEmail} />
            </Form.Item>
        );

        return (
            <Modal
                okText='Settle'
                okType='danger'
                onCancel={this.props.onCancel}
                onOk={this.onClickOk}
                open={isOpen}
                title='Settle'
                wrapClassName='application-settled-modal'
            >
                {!sendEmailBlock && <Alert message='Please note that the lead for this application has no email address so the borrower will not be notified by email.' type='warning' />}
                {settlementDateBlock}
                {sendEmailBlock}
            </Modal>
        );
    }

    private onChangeSettlementDate(date: Dayjs): void {
        this.setState({
            settlementDate: date ? date.format('YYYY-MM-DD') : null,
        });
    }

    private onChangeSendEmail(event: CheckboxChangeEvent): void {
        this.setState({
            sendEmail: event.target.checked,
        });
    }

    private onClickOk(): void {
        const { sendEmail, settlementDate } = this.state;
        const { application, currentUser } = this.props;

        const isGracePeriod: boolean = ExtensionTypeEnum.GracePeriod === application.extensionType;

        this.props.warehoused(settlementDate, (!isGracePeriod && !!application.deal.email) ? sendEmail : false, currentUser.permissions.includes(PermissionsEnum.LoanPayoutFigureForceProcess));
        this.props.onCancel();
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        currentUser: authCurrentUserSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        warehoused: (settlementDate: string, sendEmail: boolean, force: boolean) => dispatch(applicationWarehousedAction(ownProps.application.uuid, settlementDate, sendEmail, force)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettledModal);
