enum LoanPurposeEnum {
    BridgingLoan = 'BRIDGING_LOAN',
    BusinessLoan = 'BUSINESS_LOAN',
    DebtConsolidation = 'DEBT_CONSOLIDATION',
    DevelopmentLoan = 'DEVELOPMENT_LOAN',
    PersonalLoan = 'PERSONAL_LOAN',
    PurchaseNew = 'PURCHASE_NEW',
    Refinance = 'REFINANCE',
    RenovateOrBuild = 'RENOVATE_OR_BUILD',
}

export default LoanPurposeEnum;
