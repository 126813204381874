import { Action } from 'redux';
import IApplicationWarehouse from '~Api/Application/IApplicationWarehouse';
import IWarehouse from '~Api/Warehouse/IWarehouse';
import IWarehouseActiveReportLoan from '~Api/Warehouse/IWarehouseActiveReportLoan';
import IWarehouseEligibleLoan from '~Api/Warehouse/IWarehouseEligibleLoan';
import IWarehouseLoan from '~Api/Warehouse/IWarehouseLoan';
import IWarehouseLoanTransaction from '~Api/Warehouse/IWarehouseLoanTransaction';
import IWarehouseParameter from '~Api/Warehouse/IWarehouseParameter';
import IWarehouseProposedSale from '~Api/Warehouse/IWarehouseProposedSale';
import IWarehouseTransaction from '~Api/Warehouse/IWarehouseTransaction';
import IWarehousesPortfolioMetrics from '~Api/Warehouse/IWarehousesPortfolioMetrics';
import { IDictionary } from '~utilities/IDictionary';
import WarehousesActionsEnum from './ActionsEnum';
import ILoanBookForecastDay from '~Api/Warehouse/ILoanBookForecastDay';

export interface IWarehouseActiveReportListAction extends Action {
    warehouseUuid: string;
}

export function warehouseActiveReportListAction(warehouseUuid: string): IWarehouseActiveReportListAction {
    return {
        type: WarehousesActionsEnum.WarehouseActiveReportList,
        warehouseUuid,
    };
}

export interface IWarehouseActiveReportSetAction extends Action {
    warehouseLoans: IWarehouseActiveReportLoan[];
    warehouseUuid: string;
}

export function warehouseActiveReportSetAction(warehouseUuid: string, warehouseLoans: IWarehouseActiveReportLoan[]): IWarehouseActiveReportSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseActiveReportSet,
        warehouseLoans,
        warehouseUuid,
    };
}

export interface IWarehouseAdjustInterestRateAction extends Action {
    description: string;
    interestRate: number;
    transactionTime: string;
    warehouseUuid: string;
}

export function warehouseAdjustInterestRateAction(warehouseUuid: string, interestRate: number, description: string, transactionTime: string): IWarehouseAdjustInterestRateAction {
    return {
        description,
        interestRate,
        transactionTime,
        type: WarehousesActionsEnum.WarehouseAdjustInterestRate,
        warehouseUuid,
    };
}

export interface IWarehouseAdjustPrincipalAction extends Action {
    aNotes: number,
    amount: number;
    capacityLimit: number;
    description: string;
    prefund: number;
    principalDraw: number;
    sellerNotes: number;
    transactionTime: string;
    warehouseUuid: string;
    xNotes: number;
}

export function warehouseAdjustPrincipalAction(
    warehouseUuid: string,
    aNotes: number,
    amount: number,
    capacityLimit: number,
    principalDraw: number,
    sellerNotes: number,
    description: string,
    transactionTime: string,
    xNotes: number,
    prefund: number,
): IWarehouseAdjustPrincipalAction {
    return {
        aNotes,
        amount,
        capacityLimit,
        description,
        prefund,
        principalDraw,
        sellerNotes,
        transactionTime,
        type: WarehousesActionsEnum.WarehouseAdjustPrincipal,
        warehouseUuid,
        xNotes,
    };
}
export interface IWarehouseEligibleLoansListAction extends Action {
    warehouseUuid: string;
}

export function warehouseEligibleLoansListAction(warehouseUuid: string): IWarehouseEligibleLoansListAction {
    return {
        type: WarehousesActionsEnum.WarehouseEligibleLoansList,
        warehouseUuid,
    };
}

export interface IWarehouseEligibleLoansSetAction extends Action {
    eligibleLoans: IWarehouseEligibleLoan[];
    warehouseUuid: string;
}

export function warehouseEligibleLoansSetAction(warehouseUuid: string, eligibleLoans: IWarehouseEligibleLoan[]): IWarehouseEligibleLoansSetAction {
    return {
        eligibleLoans,
        type: WarehousesActionsEnum.WarehouseEligibleLoansSet,
        warehouseUuid,
    };
}

export interface IWarehouseGetAction extends Action {
    warehouseUuid: string;
}

export function warehouseGetAction(warehouseUuid: string): IWarehouseGetAction {
    return {
        type: WarehousesActionsEnum.WarehouseGet,
        warehouseUuid,
    };
}

export interface IWarehouseLoanGetAction extends Action {
    warehouseLoanUuid: string;
}

export function warehouseLoanGetAction(warehouseLoanUuid: string): IWarehouseLoanGetAction {
    return {
        type: WarehousesActionsEnum.WarehouseLoanGet,
        warehouseLoanUuid,
    };
}

export interface IWarehouseLoanSellAction extends Action {
    destinationWarehouseAmounts: IDictionary<number>;
    transactionTime: string;
    warehouseLoanUuid: string;
}

export function warehouseLoanSellAction(warehouseLoanUuid: string, destinationWarehouseAmounts: IDictionary<number>, transactionTime: string): IWarehouseLoanSellAction {
    return {
        destinationWarehouseAmounts,
        transactionTime,
        type: WarehousesActionsEnum.WarehouseLoanSell,
        warehouseLoanUuid,
    };
}

export interface IWarehouseLoanSetAction extends Action {
    warehouseLoan: IWarehouseLoan;
}

export function warehouseLoanSetAction(warehouseLoan: IWarehouseLoan): IWarehouseLoanSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseLoanSet,
        warehouseLoan,
    };
}

export interface IWarehouseLoanTransactionsListAction extends Action {
    warehouseLoanUuid: string;
}

export function warehouseLoanTransactionsListAction(warehouseLoanUuid: string): IWarehouseLoanTransactionsListAction {
    return {
        type: WarehousesActionsEnum.WarehouseLoanTransactionsList,
        warehouseLoanUuid,
    };
}

export interface IWarehouseLoanTransactionsSetAction extends Action {
    warehouseLoanTransactions: IWarehouseLoanTransaction[];
    warehouseLoanUuid: string;
}

export function warehouseLoanTransactionsSetAction(warehouseLoanUuid: string, warehouseLoanTransactions: IWarehouseLoanTransaction[]): IWarehouseLoanTransactionsSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseLoanTransactionsSet,
        warehouseLoanTransactions,
        warehouseLoanUuid,
    };
}

export interface IWarehouseLoansListAction extends Action {
    warehouseUuid: string;
}

export function warehouseLoansListAction(warehouseUuid: string): IWarehouseLoansListAction {
    return {
        type: WarehousesActionsEnum.WarehouseLoansList,
        warehouseUuid,
    };
}

export interface IWarehouseLoansSetAction extends Action {
    warehouseLoans: IWarehouseLoan[];
    warehouseUuid: string;
}

export function warehouseLoansSetAction(warehouseUuid: string, warehouseLoans: IWarehouseLoan[]): IWarehouseLoansSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseLoansSet,
        warehouseLoans,
        warehouseUuid,
    };
}

export interface IWarehouseParametersListAction extends Action {
    warehouseUuid: string;
}

export function warehouseParametersListAction(warehouseUuid: string): IWarehouseParametersListAction {
    return {
        type: WarehousesActionsEnum.WarehouseParametersList,
        warehouseUuid,
    };
}

export interface IWarehouseParametersSetAction extends Action {
    warehouseParameters: IWarehouseParameter[];
    warehouseUuid: string;
}

export function warehouseParametersSetAction(warehouseUuid: string, warehouseParameters: IWarehouseParameter[]): IWarehouseParametersSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseParametersSet,
        warehouseParameters,
        warehouseUuid,
    };
}

export interface IWarehousePendingApplicationsListAction extends Action {
    warehouseUuid: string;
}

export function warehousePendingApplicationsListAction(warehouseUuid: string): IWarehousePendingApplicationsListAction {
    return {
        type: WarehousesActionsEnum.WarehousePendingApplicationsList,
        warehouseUuid,
    };
}

export interface IWarehousePendingApplicationsSetAction extends Action {
    applicationWarehouses: IApplicationWarehouse[];
    warehouseUuid: string;
}

export function warehousePendingApplicationsSetAction(warehouseUuid: string, applicationWarehouses: IApplicationWarehouse[]): IWarehousePendingApplicationsSetAction {
    return {
        applicationWarehouses,
        type: WarehousesActionsEnum.WarehousePendingApplicationsSet,
        warehouseUuid,
    };
}

export interface IWarehouseProposedPurchaseParametersListAction extends Action {
    warehouseUuid: string;
}

export function warehouseProposedPurchaseParametersListAction(warehouseUuid: string): IWarehouseProposedPurchaseParametersListAction {
    return {
        type: WarehousesActionsEnum.WarehouseProposedPurchaseParametersList,
        warehouseUuid,
    };
}

export interface IWarehouseProposedPurchaseParametersSetAction extends Action {
    warehouseParameters: IWarehouseParameter[];
    warehouseUuid: string;
}

export function warehouseProposedPurchaseParametersSetAction(warehouseUuid: string, warehouseParameters: IWarehouseParameter[]): IWarehouseProposedPurchaseParametersSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseProposedPurchaseParametersSet,
        warehouseParameters,
        warehouseUuid,
    };
}

export interface IWarehouseProposedPurchasesAddAction extends Action {
    loanUuid: string;
    transactionTime: string;
    warehouseUuid: string;
}

export function warehouseProposedPurchasesAddAction(warehouseUuid: string, loanUuid: string, transactionTime: string): IWarehouseProposedPurchasesAddAction {
    return {
        loanUuid,
        transactionTime,
        type: WarehousesActionsEnum.WarehouseProposedPurchasesAdd,
        warehouseUuid,
    };
}

export interface IWarehouseProposedSalesAddAction extends Action {
    destinationWarehouseAmounts: IDictionary<number>;
    sourceWarehouseLoanUuid: string;
    transactionTime: string;
}

export function warehouseProposedSalesAddAction(sourceWarehouseLoanUuid: string, destinationWarehouseAmounts: IDictionary<number>, transactionTime: string): IWarehouseProposedSalesAddAction {
    return {
        destinationWarehouseAmounts,
        sourceWarehouseLoanUuid,
        transactionTime,
        type: WarehousesActionsEnum.WarehouseProposedSalesAdd,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWarehouseProposedSalesPendingListAction extends Action {}

export function warehouseProposedSalesPendingListAction(): IWarehouseProposedSalesPendingListAction {
    return {
        type: WarehousesActionsEnum.WarehouseProposedSalesPendingList,
    };
}

export interface IWarehouseProposedSalesPendingSetAction extends Action {
    warehouseProposedSales: IWarehouseProposedSale[];
}

export function warehouseProposedSalesPendingSetAction(warehouseProposedSales: IWarehouseProposedSale[]): IWarehouseProposedSalesPendingSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseProposedSalesPendingSet,
        warehouseProposedSales,
    };
}

export interface IWarehouseProposedSaleProcessAction extends Action {
    warehouseProposedSaleUuid: string;
}

export function warehouseProposedSaleProcessAction(warehouseProposedSaleUuid: string): IWarehouseProposedSaleProcessAction {
    return {
        type: WarehousesActionsEnum.WarehouseProposedSaleProcess,
        warehouseProposedSaleUuid,
    };
}

export interface IWarehouseProposedSaleCancelAction extends Action {
    warehouseProposedSaleUuid: string;
}

export function warehouseProposedSaleCancelAction(warehouseProposedSaleUuid: string): IWarehouseProposedSaleCancelAction {
    return {
        type: WarehousesActionsEnum.WarehouseProposedSaleCancel,
        warehouseProposedSaleUuid,
    };
}

export interface IWarehouseTransactionsListAction extends Action {
    warehouseUuid: string;
}

export function warehouseTransactionsListAction(warehouseUuid: string): IWarehouseTransactionsListAction {
    return {
        type: WarehousesActionsEnum.WarehouseTransactionsList,
        warehouseUuid,
    };
}

export interface IWarehouseTransactionsSetAction extends Action {
    warehouseTransactions: IWarehouseTransaction[];
    warehouseUuid: string;
}

export function warehouseTransactionsSetAction(warehouseUuid: string, warehouseTransactions: IWarehouseTransaction[]): IWarehouseTransactionsSetAction {
    return {
        type: WarehousesActionsEnum.WarehouseTransactionsSet,
        warehouseTransactions,
        warehouseUuid,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWarehousesListAction extends Action { }

export function warehousesListAction(): IWarehousesListAction {
    return {
        type: WarehousesActionsEnum.WarehousesList,
    };
}

export interface IWarehousesSetAction extends Action {
    warehouses: IWarehouse[];
}

export function warehousesSetAction(warehouses: IWarehouse[]): IWarehousesSetAction {
    return {
        type: WarehousesActionsEnum.WarehousesSet,
        warehouses,
    };
}

export type IWarehousesLoanBookForecastListAction = Action;

export function warehousesLoanBookForecastListAction(): IWarehousesLoanBookForecastListAction {
    return {
        type: WarehousesActionsEnum.WarehousesLoanBookForecastList,
    };
}

export interface IWarehousesLoanBookForecastSetAction extends Action {
    forecastDays: ILoanBookForecastDay[];
}

export function warehousesLoanBookForecastSetAction(forecastDays: ILoanBookForecastDay[]): IWarehousesLoanBookForecastSetAction {
    return {
        forecastDays,
        type: WarehousesActionsEnum.WarehousesLoanBookForecastSet,
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWarehousesPortfolioDashboardListAction extends Action { }

export function warehousesPortfolioDashboardListAction(): IWarehousesPortfolioDashboardListAction {
    return {
        type: WarehousesActionsEnum.WarehousesPortfolioDashboardList,
    };
}

export interface IWarehousesPortfolioDashboardSetAction extends Action {
    warehousesPortfolioDashboard: IWarehousesPortfolioMetrics[];
}

export function warehousesPortfolioDashboardSetAction(warehousesPortfolioDashboard: IWarehousesPortfolioMetrics[]): IWarehousesPortfolioDashboardSetAction {
    return {
        type: WarehousesActionsEnum.WarehousesPortfolioDashboardSet,
        warehousesPortfolioDashboard: warehousesPortfolioDashboard,
    };
}
