import { Form, Select, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { match as routerMatch } from 'react-router-dom';
import { Dispatch } from 'redux';
import DischargeMethodEnum from '~Api/Loan/DischargeMethodEnum';
import ILoan from '~Api/Loan/ILoan';
import { loanGetAction, loanValueSetAction } from '~Loans/actions';
import { loanSelector } from '~Loans/selectors';
import { IGlobalState } from '~reducer';
import Layout from './Layout';

interface IMatch {
    uuid: string;
}

interface IProps {
    match: routerMatch<IMatch>;
}

interface IPropsSelector {
    loan: ILoan;
}

interface IPropsDispatch {
    loanGet: () => void;
    loanValueSet: (key: keyof ILoan, value: boolean|number|string) => void;
}

type Props = IProps & IPropsSelector & IPropsDispatch;

class Edit extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.onChangeDischargeMethod = this.onChangeDischargeMethod.bind(this);
    }

    public componentDidMount(): void {
        this.props.loanGet();
    }

    public render(): JSX.Element {
        const { loan, match } = this.props;

        if (!loan) {
            return (
                <Layout uuid={match.params.uuid} section='edit'>
                    <Spin/>
                </Layout>
            );
        }

        return (
            <Layout uuid={match.params.uuid} section='edit'>
                <Typography.Title level={2}>Edit</Typography.Title>
                <Form.Item label='Discharge Method' className='discharge-method'>
                    <Select onChange={this.onChangeDischargeMethod} value={loan.dischargeMethod}>
                        <Select.Option value={DischargeMethodEnum.DirectTransfer}>Direct Transfer</Select.Option>
                        <Select.Option value={DischargeMethodEnum.DuffelBagOfCash}>Duffel Bag of Cash</Select.Option>
                        <Select.Option value={DischargeMethodEnum.GracePeriod}>Grace Period</Select.Option>
                        <Select.Option value={DischargeMethodEnum.MortgageeSale}>Mortgagee Sale</Select.Option>
                        <Select.Option value={DischargeMethodEnum.Refinance}>Refinance</Select.Option>
                        <Select.Option value={DischargeMethodEnum.RefinanceInternal}>Refinance (Internal)</Select.Option>
                        <Select.Option value={DischargeMethodEnum.SaleOfProperty}>Sale of Property</Select.Option>
                        <Select.Option value={DischargeMethodEnum.WrittenOff}>Written Off</Select.Option>
                    </Select>
                </Form.Item>
            </Layout>
        );
    }

    private onChangeDischargeMethod(value: DischargeMethodEnum): void {
        this.props.loanValueSet('dischargeMethod', value);
    }
}

function mapStateToProps(state: IGlobalState, ownProps: IProps): IPropsSelector {
    return {
        loan: loanSelector(state, ownProps.match.params.uuid),
    };
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IPropsDispatch {
    return {
        loanGet: () => dispatch(loanGetAction(ownProps.match.params.uuid)),
        loanValueSet: (key: keyof ILoan, value: boolean|number|string) => dispatch(loanValueSetAction(ownProps.match.params.uuid, key, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Edit);
