enum RepaymentStrategyEnum {
    BusinessCashFlow = 'BUSINESS_CASH_FLOW',
    BusinessSaleNotSold = 'BUSINESS_SALE_NOT_SOLD',
    BusinessSaleSold = 'BUSINESS_SALE_SOLD',
    CourtSettlement = 'COURT_SETTLEMENT',
    Inheritance = 'INHERITANCE',
    Other = 'OTHER',
    PropertySaleNotSold = 'PROPERTY_SALE_NOT_SOLD',
    PropertySaleSold = 'PROPERTY_SALE_SOLD',
    RefinanceApproved = 'REFINANCE_APPROVED',
    RefinanceNotApproved = 'REFINANCE_NOT_APPROVED',
}

export default RepaymentStrategyEnum;
