import { Breadcrumb, Button, Card, Form, Layout, Modal, Space, Spin, Tree, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ILoan from '~Api/Loan/ILoan';
import { IDictionary } from '~utilities/IDictionary';
import { loansDashboardSelector } from './selectors';
import { Dispatch } from 'redux';
import { loansDashboardListAction } from './actions';
import LoanCard from './Loan/Card';
import _ from 'lodash';
import IAdministrator from '~Api/Administrator/IAdministrator';
import { administratorsListAction } from '~Administrators/actions';
import { administratorsSelector, currentAdministratorSelector } from '~Administrators/selectors';
import { FilterFilled, FilterTwoTone } from '@ant-design/icons';
import Search from './Search';
import { Link } from 'react-router-dom';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import { currencyFormatter } from '~utilities/formatters';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import CheckedKeys from '~utilities/CheckedKeys';
import ITreeStructureGroup from '~utilities/ITreeStructureGroup';
import ModalStateEnum from '~UI/ModalStateEnum';

export enum BoardColumnEnum {
    DueInNinetyToSixtyDays = 'DUE_IN_90_TO_60_DAYS',
    DueInSixtyToThirtyDays = 'DUE_IN_60_TO_30_DAYS',
    DueInThirtyToZeroDays = 'DUE_IN_30_TO_0_DAYS',
    PastDueNinetyPlusDays = 'PAST_DUE_90_PLUS_DAYS',
    PastDueSixtyToNinetyDays = 'PAST_DUE_60_TO_90_DAYS',
    PastDueThirtyToSixtyDays = 'PAST_DUE_30_TO_60_DAYS',
    PastDueZeroToThirtyDays = 'PAST_DUE_0_TO_30_DAYS'
}

const boardColumnLabels: IDictionary<string> = {
    [BoardColumnEnum.DueInNinetyToSixtyDays]: 'Due in 90 - 60 Days',
    [BoardColumnEnum.DueInSixtyToThirtyDays]: 'Due in 60 - 30 Days',
    [BoardColumnEnum.DueInThirtyToZeroDays]: 'Due in 30 - 0 Days',
    [BoardColumnEnum.PastDueZeroToThirtyDays]: '0 - 30 Days Past Due',
    [BoardColumnEnum.PastDueThirtyToSixtyDays]: '30 - 60 Days Past Due',
    [BoardColumnEnum.PastDueSixtyToNinetyDays]: '60 - 90 Days Past Due',
    [BoardColumnEnum.PastDueNinetyPlusDays]: '90+ Days Past Due',
};

const workflowStatusLabels: IDictionary<string> = {
    [WorkflowStatusEnum.Active]: 'Active',
    [WorkflowStatusEnum.Discharge]: 'Discharge',
    [WorkflowStatusEnum.Drawdown]: 'Drawdown',
    [WorkflowStatusEnum.Extension]: 'Extension',
    [WorkflowStatusEnum.GracePeriod]: 'Grace Period',
    [WorkflowStatusEnum.Recovery]: 'Recovery',
};

const unassignedKey: string = 'UNASSIGNED';

const workflowStatusTreeData: ITreeStructureGroup[] = _.values(WorkflowStatusEnum).map((workflowStatus: WorkflowStatusEnum) => (
    {
        children: [],
        key: workflowStatus,
        title: workflowStatusLabels[workflowStatus],
        value: workflowStatus,
    }
));

export default function Dashboard(): ReactElement {
    const loans: IDictionary<ILoan> = useSelector(loansDashboardSelector);
    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentAdministrator: IAdministrator = useSelector(currentAdministratorSelector);

    const [loanManagerFilter, setLoanManagerFilter] = useState<string[]>([]);
    const [workflowStatusFilter, setWorkflowStatusFilter] = useState<string[]>([]);
    const [isChangeFiltersModalOpen, setIsChangeFiltersModalOpen] = useState<ModalStateEnum>(ModalStateEnum.Off);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (!loans) {
            dispatch(loansDashboardListAction());
        }
    }, [
        dispatch,
        loans,
    ]);

    useEffect(() => {
        const refreshInterval: NodeJS.Timeout = setInterval(() => {
            dispatch(loansDashboardListAction());
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(refreshInterval);
        };
    }, [dispatch]);

    useEffect(() => {
        if (!administrators || !currentAdministrator) {
            dispatch(administratorsListAction());
        }
    }, [
        administrators,
        currentAdministrator,
        dispatch,
    ]);

    const onChangeWorkflowStatusFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys): void => {
        setWorkflowStatusFilter(checkedKeys as string[]);
    }, []);

    const onChangeLoanManagerFilter: (checkedKeys: CheckedKeys) => void = useCallback((checkedKeys: CheckedKeys): void => {
        setLoanManagerFilter(checkedKeys as string[]);
    }, []);

    const onClickChangeFilters: () => void = useCallback((): void => {
        setIsChangeFiltersModalOpen(ModalStateEnum.Open);
    }, []);

    const onCancelChangeFilters: () => void = useCallback((): void => {
        setIsChangeFiltersModalOpen(ModalStateEnum.Closed);
    }, []);

    if (!loans || !administrators || ! currentAdministrator) {
        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Loans</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search />
                            <Button disabled={true} icon={<FilterFilled/>} />
                            <Link to='/loans/list'><Button>List View</Button></Link>
                            <Link to='/loans/classic'><Button>Classic View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Loans</Typography.Title>
                        <Spin />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    const columns: IDictionary<ILoan[]> = {};
    const statusAmounts: IDictionary<number> = {};

    const todayDayjs: Dayjs = dayjs();
    const maturing90: Dayjs = todayDayjs.add(90, 'days');
    const maturing60: Dayjs = todayDayjs.add(60, 'days');
    const maturing30: Dayjs = todayDayjs.add(30, 'days');

    const visibleLoanManagers: IDictionary<IAdministrator> = {};

    const filtered: boolean = workflowStatusFilter.length > 0 || loanManagerFilter.length > 0;

    _.sortBy(loans, ['endDate']).forEach((loan: ILoan) => {
        if ([LoanStatusEnum.Cancelled, LoanStatusEnum.ClosedObligationsMet, LoanStatusEnum.ClosedWrittenOff, LoanStatusEnum.PartialApplication].includes(loan.status)) {
            return;
        }

        const loanManager: IAdministrator = administrators[loan.administratorUuid];

        if (loanManager && !visibleLoanManagers[loan.administratorUuid]) {
            visibleLoanManagers[loan.administratorUuid] = loanManager;
        }

        if (loanManagerFilter.length > 0) {
            let shouldShow: boolean = false;
            if (!loan.administratorUuid && loanManagerFilter.includes(unassignedKey)) {
                shouldShow = true;
            }
            if (loan.administratorUuid && loanManagerFilter.includes(loan.administratorUuid)) {
                shouldShow = true;
            }
            if (!shouldShow) {
                return;
            }
        }

        if (workflowStatusFilter.length > 0 && !workflowStatusFilter.includes(loan.workflowStatus)) {
            return;
        }

        let boardColumn: BoardColumnEnum = null;
        const endDateDayjs: Dayjs = dayjs(loan.endDate);

        const pastDue90: Dayjs = endDateDayjs.add(90, 'days');
        const pastDue60: Dayjs = endDateDayjs.add(60, 'days');
        const pastDue30: Dayjs = endDateDayjs.add(30, 'days');

        if (todayDayjs > pastDue90) {
            boardColumn = BoardColumnEnum.PastDueNinetyPlusDays;
        } else if (todayDayjs <= pastDue90 && todayDayjs > pastDue60) {
            boardColumn = BoardColumnEnum.PastDueSixtyToNinetyDays;
        } else if (todayDayjs <= pastDue60 && todayDayjs > pastDue30) {
            boardColumn = BoardColumnEnum.PastDueThirtyToSixtyDays;
        } else if (todayDayjs <= pastDue30 && todayDayjs > endDateDayjs) {
            boardColumn = BoardColumnEnum.PastDueZeroToThirtyDays;
        } else if (endDateDayjs <= maturing30 && endDateDayjs > todayDayjs) {
            boardColumn = BoardColumnEnum.DueInThirtyToZeroDays;
        } else if (endDateDayjs <= maturing60 && endDateDayjs > maturing30) {
            boardColumn = BoardColumnEnum.DueInSixtyToThirtyDays;
        } else if (endDateDayjs <= maturing90 && endDateDayjs > maturing60) {
            boardColumn = BoardColumnEnum.DueInNinetyToSixtyDays;
        }

        if (!columns[boardColumn]) {
            columns[boardColumn] = [];
        }

        columns[boardColumn].push(loan);

        if (!statusAmounts[boardColumn]) {
            statusAmounts[boardColumn] = 0;
        }

        statusAmounts[boardColumn] += loan.amount;
    });

    const loanManagerTreeData: ITreeStructureGroup[] = [
        {
            children: [],
            key: unassignedKey,
            title: 'Unassigned',
            value: unassignedKey,
        },
        ...(_.sortBy(_.values(visibleLoanManagers), ['name']).map((loanManager: IAdministrator) => ({
            children: [],
            key: loanManager.uuid,
            title: loanManager.name,
            value: loanManager.uuid,
        }))),
    ];

    const columnsBlock: JSX.Element[] = _.keys(boardColumnLabels).map((boardColumnName: string) => {
        const boardColumn: BoardColumnEnum = boardColumnName as BoardColumnEnum;

        const amountBlock: JSX.Element = (
            <div className='amount'>
                {columns[boardColumn] ? columns[boardColumn].length : 0} - {currencyFormatter.format(statusAmounts[boardColumn] || 0)}
            </div>
        );

        const cardsBlock: JSX.Element[] = _.orderBy(columns[boardColumn], ['endDate'], ['asc']).map((loan: ILoan) => (
            <LoanCard key={loan.uuid} loan={loan} />
        ));

        const cardTitleBlock: JSX.Element = (
            <>
                {boardColumnLabels[boardColumn]}
                {amountBlock}
            </>
        );

        return (
            <Card key={boardColumn} title={cardTitleBlock}>
                <Space direction='vertical'>
                    {cardsBlock}
                </Space>
            </Card>
        );
    });

    const changeFiltersModal: JSX.Element = ModalStateEnum.Off !== isChangeFiltersModalOpen && (
        <Modal
            footer={<Button onClick={onCancelChangeFilters}>Close</Button>}
            open={ModalStateEnum.Open === isChangeFiltersModalOpen}
            onCancel={onCancelChangeFilters}
            title='Change Filters'
            wrapClassName='loans-dashboard-change-filters-modal'
        >
            <Form.Item label='Workflow Status'>
                <Tree
                    checkable={true}
                    checkedKeys={workflowStatusFilter}
                    defaultExpandAll={true}
                    onCheck={onChangeWorkflowStatusFilter}
                    selectable={false}
                    treeData={workflowStatusTreeData}
                />
            </Form.Item>
            <Form.Item label='Loan Manager'>
                <Tree
                    checkable={true}
                    checkedKeys={loanManagerFilter}
                    defaultExpandAll={true}
                    onCheck={onChangeLoanManagerFilter}
                    selectable={false}
                    treeData={loanManagerTreeData}
                />
            </Form.Item>
        </Modal>
    );

    return (
        <Layout className='loans'>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Loans</Breadcrumb.Item>
            </Breadcrumb>
            <Layout className='content-wrapper'>
                <Layout.Content className='content'>
                    <Space className='actions'>
                        <Search />
                        <Button onClick={onClickChangeFilters} icon={filtered ? <FilterTwoTone /> : <FilterFilled />} />
                        <Link to='/loans/list'><Button>List View</Button></Link>
                        <Link to='/loans/classic'><Button>Classic View</Button></Link>
                    </Space>
                    <Typography.Title level={2}>Loans</Typography.Title>
                    <Space align='start' className='columns'>
                        {columnsBlock}
                    </Space>
                </Layout.Content>
            </Layout>
            {changeFiltersModal}
        </Layout>
    );
}
