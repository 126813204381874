import { ArrowRightOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Descriptions, Dropdown, Layout as LayoutAnt, Menu, MenuProps, Modal, PageHeader, Popover, Space, Spin, Tag } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dispatch } from 'redux';
import { administratorsSelector } from '~Administrators/selectors';
import IAdministrator from '~Api/Administrator/IAdministrator';
import PermissionsEnum from '~Api/Administrator/PermissionsEnum';
import ApprovalStatusEnum from '~Api/Deal/ApprovalStatusEnum';
import CloseReasonEnum from '~Api/Deal/CloseReasonEnum';
import IDeal from '~Api/Deal/IDeal';
import ReferredToEnum from '~Api/Deal/ReferredToEnum';
import RejectReasonEnum from '~Api/Deal/RejectReasonEnum';
import IReferralPartner from '~Api/ReferralPartner/IReferralPartner';
import IAuthUser from '~Auth/IAuthUser';
import { authCurrentUserSelector } from '~Auth/selectors';
import {
    dealFollowUpAction,
    dealReopenAction,
    dealUncontactableFollowUpAction,
} from '~Deals/actions';
import AssigneeSelector from '~Deals/AssigneeSelector';
import FollowUpTime from '~Deals/FollowUpTime';
import { leadInitialCallAction } from '~Leads/actions';
import { leadSelector } from '~Leads/selectors';
import { IGlobalState } from '~reducer';
import { referralPartnersListAction } from '~ReferralPartners/actions';
import { referralPartnersSelector } from '~ReferralPartners/selectors';
import { IDictionary } from '~utilities/IDictionary';
import CloseModal from './CloseModal';
import './lead.less';
import ReferModal from './ReferModal';
import RejectModal from './RejectModal';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { currencyFormatter } from '~utilities/formatters';
import menuDivider from '~UI/menuDivider';
import { Helmet } from 'react-helmet';

const closeReasonLabels: IDictionary<string> = {
    [CloseReasonEnum.LostToOther]: 'Lost to Other',
    [CloseReasonEnum.Stale]: 'Stale',
};

const approvalStatusLabels: IDictionary<string> = {
    [ApprovalStatusEnum.Application]: 'Application',
    [ApprovalStatusEnum.Draft]: 'Draft',
    [ApprovalStatusEnum.FollowUp1]: 'Follow Up 1',
    [ApprovalStatusEnum.FollowUp2]: 'Follow Up 2',
    [ApprovalStatusEnum.FollowUp3]: 'Follow Up 3',
    [ApprovalStatusEnum.InitialCall]: 'Initial Call',
    [ApprovalStatusEnum.UncontactableFollowUp1]: 'Uncontactable Follow Up 1',
    [ApprovalStatusEnum.UncontactableFollowUp2]: 'Uncontactable Follow Up 2',
    [ApprovalStatusEnum.UncontactableFollowUp3]: 'Uncontactable Follow Up 3',
    [ApprovalStatusEnum.Pending]: 'New',
    [ApprovalStatusEnum.Referred]: 'Referred',
    [ApprovalStatusEnum.Rejected]: 'Rejected',
    [ApprovalStatusEnum.Quote]: 'Quoted',
};

const rejectReasonLabels: IDictionary<string> = {
    [RejectReasonEnum.AssetClass]: 'Asset Class',
    [RejectReasonEnum.Location]: 'Location',
    [RejectReasonEnum.LostToOther]: 'Lost to Other',
    [RejectReasonEnum.Lvr]: 'LVR',
    [RejectReasonEnum.Product]: 'Product Mismatch',
};

const referredToLabels: IDictionary<string> = {
    [ReferredToEnum.DirectCredit]: 'Direct Credit',
};

export default function Layout(props: {
    dealUuid: string;
    breadcrumb?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    section: string;
}): ReactElement {
    const { breadcrumb, children, dealUuid, section } = props;

    const [ isCloseModalOpen, setIsCloseModalOpen ] = useState<boolean>(false);
    const [ isReferModalOpen, setIsReferModalOpen ] = useState<boolean>(false);
    const [ isRejectModalOpen, setIsRejectModalOpen ] = useState<boolean>(false);

    const administrators: IDictionary<IAdministrator> = useSelector(administratorsSelector);
    const currentUser: IAuthUser = useSelector(authCurrentUserSelector);
    const deal: IDeal = useSelector((state: IGlobalState) => leadSelector(state, dealUuid));
    const referralPartners: IDictionary<IReferralPartner> = useSelector(referralPartnersSelector);

    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        dispatch(referralPartnersListAction());
    }, [dispatch]);

    const onClickClose: () => void = useCallback(() => {
        setIsCloseModalOpen(true);
    }, []);

    const onCloseCancel: () => void = useCallback(() => {
        setIsCloseModalOpen(false);
    }, []);

    const onClickRefer: () => void = useCallback(() => {
        setIsReferModalOpen(true);
    }, []);

    const onReferCancel: () => void = useCallback(() => {
        setIsReferModalOpen(false);
    }, []);

    const onClickReject: () => void = useCallback(() => {
        setIsRejectModalOpen(true);
    }, []);

    const onRejectCancel: () => void = useCallback(() => {
        setIsRejectModalOpen(false);
    }, []);

    const onClickInitialCall: () => void = useCallback(() => {
        Modal.confirm({
            content: 'Are you sure you want to record an initial call for this lead?',
            onOk: () => {
                dispatch(leadInitialCallAction(dealUuid));
            },
            title: 'Initial Call',
        });
    }, [
        dealUuid,
        dispatch,
    ]);

    const onClickFollowUp: (sendEmail: boolean) => void = useCallback((sendEmail: boolean) => {
        Modal.confirm({
            content: `Are you sure you want to ${ sendEmail ? 'send a follow-up email' : 'record a follow-up'} for this lead?`,
            onOk: () => {
                dispatch(dealFollowUpAction(dealUuid, sendEmail));
            },
            title: `${sendEmail ? 'Send' : 'Record'} Follow Up`,
        });
    }, [
        dealUuid,
        dispatch,
    ]);

    const onClickUncontactableFollowUp: (sendEmail: boolean) => void = useCallback((sendEmail: boolean) => {
        Modal.confirm({
            content: `Are you sure you want to ${sendEmail ? 'send an uncontactable follow-up email' : 'record an uncontactable follow-up'} for this lead?`,
            onOk: () => {
                dispatch(dealUncontactableFollowUpAction(dealUuid, sendEmail));
            },
            title: `${sendEmail ? 'Send' : 'Record'} UncontactableFollow Up`,
        });
    }, [
        dealUuid,
        dispatch,
    ]);

    const onClickReopen: () => void = useCallback(() => {
        Modal.confirm({
            content: 'Are you sure you want to reopen this lead?',
            okText: 'Reopen',
            onOk: () => {
                dispatch(dealReopenAction(dealUuid));
            },
            title: 'Reopen Lead',
        });
    }, [
        dealUuid,
        dispatch,
    ]);

    const onClickSendUncontactableFollowUpEmail: () => void = useCallback(() => onClickUncontactableFollowUp(true), [onClickUncontactableFollowUp]);
    const onClickSendFollowUpEmail: () => void = useCallback(() => onClickFollowUp(true), [onClickFollowUp]);
    const onClickRecordFollowUp: () => void = useCallback(() => onClickFollowUp(false), [onClickFollowUp]);

    if (!administrators || !deal) {
        return (
            <LayoutAnt className='lead'>
                <Spin/>
            </LayoutAnt>
        );
    }

    const breadcrumbBlock: JSX.Element = breadcrumb ? (
        <>
            <Breadcrumb.Item><Link to={`/leads/${deal.uuid}`}>{deal.formattedName || '-'}</Link></Breadcrumb.Item>
            {breadcrumb}
        </>
    ) : (
        <Breadcrumb.Item>{deal.formattedName || '-'}</Breadcrumb.Item>
    );

    const actionsMenu: MenuProps = {
        items: [
            ...(ApprovalStatusEnum.Quote === deal.approvalStatus ? [
                {
                    key: 'send-follow-up',
                    label: 'Send Follow Up',
                    onClick: onClickSendFollowUpEmail,
                },
            ] : []),
            ...([ApprovalStatusEnum.FollowUp1, ApprovalStatusEnum.FollowUp2].includes(deal.approvalStatus) ? [
                {
                    key: 'record-follow-up',
                    label: 'Record Follow Up',
                    onClick: onClickRecordFollowUp,
                },
            ] : []),
            ...([ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2].includes(deal.approvalStatus) ? [
                {
                    key: 'record-uncontactable-follow-up',
                    label: 'Record Uncontactable Follow Up',
                    onClick: () => onClickUncontactableFollowUp(false),
                },
            ] : []),
            ...(![ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending, ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2, ApprovalStatusEnum.UncontactableFollowUp3, ApprovalStatusEnum.Rejected].includes(deal.approvalStatus) ? [
                {
                    key: 'quote',
                    label: <Link to={`/leads/${deal.uuid}/calculator`}>Quote</Link>,
                },
            ] : []),
            ...(![ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending, ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2, ApprovalStatusEnum.UncontactableFollowUp3, ApprovalStatusEnum.Rejected].includes(deal.approvalStatus) ? [
                {
                    key: 'refer',
                    label: 'Refer',
                    onClick: onClickRefer,
                },
            ] : []),
            ...(currentUser.permissions.includes(PermissionsEnum.LeadsReject) && ![ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending, ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2, ApprovalStatusEnum.UncontactableFollowUp3, ApprovalStatusEnum.Rejected].includes(deal.approvalStatus) ? [
                {
                    danger: true,
                    key: 'reject',
                    label: 'Reject',
                    onClick: onClickReject,
                },
            ] : []),
            ...(!deal.closedTime && ![ApprovalStatusEnum.FollowUp1, ApprovalStatusEnum.FollowUp2, ApprovalStatusEnum.Quote].includes(deal.approvalStatus) ? [
                {
                    danger: true,
                    key: 'close',
                    label: 'Close',
                    onClick: onClickClose,
                },
            ] : []),
        ],
    };

    const referBlock: JSX.Element = [ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending, ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2, ApprovalStatusEnum.UncontactableFollowUp3].includes(deal.approvalStatus) && (
        <Button onClick={onClickRefer}>Refer</Button>
    );

    const rejectBlock: JSX.Element = currentUser.permissions.includes(PermissionsEnum.LeadsReject) && [ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending, ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2, ApprovalStatusEnum.UncontactableFollowUp3].includes(deal.approvalStatus) && (
        <Button danger={true} onClick={onClickReject}>Reject</Button>
    );

    const reopenBlock: JSX.Element = deal.closedTime && !deal.applicationUuid && deal.approvalStatus !== ApprovalStatusEnum.Rejected && deal.approvalStatus !== ApprovalStatusEnum.Referred && (
        <Button onClick={onClickReopen}>Reopen</Button>
    );

    const followUpBlock: JSX.Element = [ApprovalStatusEnum.FollowUp1, ApprovalStatusEnum.FollowUp2].includes(deal.approvalStatus) && (
        <Space>
            <Dropdown.Button onClick={onClickSendFollowUpEmail} menu={actionsMenu} type='primary'>
                Send {ApprovalStatusEnum.FollowUp2 === deal.approvalStatus ? ' Final ' : ' '} Follow Up
            </Dropdown.Button>
        </Space>
    );

    const firstFollowUpBlock: JSX.Element = deal.approvalStatus === ApprovalStatusEnum.Quote && (
        <Space>
            <Dropdown.Button onClick={onClickRecordFollowUp} menu={actionsMenu} type='primary'>
                Record Follow Up
            </Dropdown.Button>
        </Space>
    );

    const uncontactableFollowUpBlock: JSX.Element = [ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2].includes(deal.approvalStatus) && <Dropdown.Button onClick={onClickSendUncontactableFollowUpEmail} menu={actionsMenu} type='primary'>Send Uncontactable Follow Up</Dropdown.Button>;

    const quoteBlock: JSX.Element = [ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending, ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2, ApprovalStatusEnum.UncontactableFollowUp3].includes(deal.approvalStatus) && (
        <Dropdown.Button menu={actionsMenu} type='primary'>
            <Link to={`/leads/${deal.uuid}/calculator`}>Quote</Link>
        </Dropdown.Button>
    );

    const extra: JSX.Element = (
        <Space>
            <Tag>{approvalStatusLabels[deal.approvalStatus]}</Tag>
            {[ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending].includes(deal.approvalStatus) && <Button onClick={onClickInitialCall}>Initial Call</Button>}
            {!deal.closedTime && ![ApprovalStatusEnum.Draft, ApprovalStatusEnum.Pending, ApprovalStatusEnum.InitialCall, ApprovalStatusEnum.UncontactableFollowUp1, ApprovalStatusEnum.UncontactableFollowUp2, ApprovalStatusEnum.UncontactableFollowUp3, ApprovalStatusEnum.Rejected, ApprovalStatusEnum.Application, ApprovalStatusEnum.Referred].includes(deal.approvalStatus) && <Button danger={true} onClick={onClickClose}>Close</Button>}
            {reopenBlock}
            {referBlock}
            {rejectBlock}
            {uncontactableFollowUpBlock}
            {quoteBlock}
            {firstFollowUpBlock}
            {followUpBlock}
        </Space>
    );

    const bdm: IAdministrator = deal.bdmUuid && administrators[deal.bdmUuid];

    const brokerContentBlock: JSX.Element = deal.broker && (
        <React.Fragment>
            <p>Phone: <a href={`tel:${deal.broker.phone}`}>{deal.broker.phone}</a></p>
            <p>Email: <a href={`mailto:${deal.broker.email}`}>{deal.broker.email}</a></p>
        </React.Fragment>
    );

    const brokerBlock: JSX.Element | string = deal.broker ? (
        <Popover content={brokerContentBlock} title={`${deal.broker.firstName} ${deal.broker.lastName}`}>
            <a>{deal.broker.firstName} {deal.broker.lastName}</a>
        </Popover>
    ) : 'None';

    const referralPartner: IReferralPartner = deal.referralPartnerUuid && referralPartners[deal.referralPartnerUuid];

    const referralPartnerName: string = referralPartner ? `${referralPartner.firstName || '-'} ${referralPartner.lastName || '-'}` : (deal.referralPartnerFirstName || deal.referralPartnerLastName) ? `${deal.referralPartnerFirstName || '-'} ${deal.referralPartnerLastName || '-'}` : null;

    const referralPartnerContentBlock: JSX.Element = referralPartner ? (
        <React.Fragment>
            <p>Phone: <a href={`tel:${referralPartner.phone}`}>{referralPartner.phone}</a></p>
            <p>Email: <a href={`mailto:${referralPartner.email}`}>{referralPartner.email}</a></p>
            {referralPartner.companyName && <p>Company: {referralPartner.companyName}</p>}
        </React.Fragment>
    ) : (
        <React.Fragment>
            {deal.referralPartnerPhone && <p>Phone: <a href={`tel:${deal.referralPartnerPhone}`}>{deal.referralPartnerPhone}</a></p>}
            {deal.referralPartnerEmail && <p>Email: <a href={`mailto:${deal.referralPartnerEmail}`}>{deal.referralPartnerEmail}</a></p>}
            {deal.referralPartnerCompanyName && <p>Company: {deal.referralPartnerCompanyName}</p>}
        </React.Fragment>
    );

    const referralPartnerBlock: JSX.Element = referralPartnerName && (
        <Popover content={referralPartnerContentBlock} title={referralPartnerName}>
            <a>{referralPartnerName}</a>
        </Popover>
    );

    const rejectReasonBlock: JSX.Element = deal.approvalStatus === ApprovalStatusEnum.Rejected && (
        <Descriptions.Item label='Reject Reason'>{!!deal.rejectReason ? rejectReasonLabels[deal.rejectReason] : 'None'}</Descriptions.Item>
    );

    const closeReasonBlock: JSX.Element = deal.closedTime && deal.closeReason && (
        <Descriptions.Item label='Close Reason'>{deal.closeReason ? closeReasonLabels[deal.closeReason] : 'None'}</Descriptions.Item>
    );

    const referredToBlock: JSX.Element = deal.approvalStatus === ApprovalStatusEnum.Referred && (
        <Descriptions.Item label='Referred To'>{referredToLabels[deal.referredTo]}</Descriptions.Item>
    );

    const menuItems: ItemType[] = [
        {
            key: 'overview',
            label: <NavLink to={`/leads/${deal.uuid}`}>Overview</NavLink>,
        },
        {
            key: 'edit',
            label: <NavLink to={`/leads/${deal.uuid}/edit`}>Edit</NavLink>,
        },
        ...(![ApprovalStatusEnum.Application, ApprovalStatusEnum.Rejected, ApprovalStatusEnum.Referred].includes(deal.approvalStatus) ? [
            {
                key: 'calculator',
                label: <NavLink to={`/leads/${deal.uuid}/calculator`}>Calculator</NavLink>,
            },
        ] : []),
        {
            key: 'quotes',
            label: <NavLink to={`/leads/${deal.uuid}/quotes`}>Quotes</NavLink>,
        },
        menuDivider,
        {
            key: 'properties',
            label: <NavLink to={`/leads/${deal.uuid}/properties`}>Properties</NavLink>,
        },
        {
            key: 'documents',
            label: <NavLink to={`/leads/${deal.uuid}/documents`}>Documents</NavLink>,
        },
        {
            key: 'notes',
            label: <NavLink to={`/leads/${deal.uuid}/notes`}>Notes</NavLink>,
        },
        {
            key: 'history',
            label: <NavLink to={`/leads/${deal.uuid}/history`}>History</NavLink>,
        },
        ...(deal.approvalStatus === ApprovalStatusEnum.Application ? [
            menuDivider,
            {
                key: 'application',
                label: <NavLink to={`/applications/${deal.applicationUuid}`}><ArrowRightOutlined/> Application</NavLink>,
            },
        ] : []),
    ];

    return (
        <LayoutAnt className='lead'>
            <Helmet>
                <title>{deal.code} - {deal.formattedName || '-'}</title>
            </Helmet>
            <Breadcrumb className='breadcrumb'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item><Link to='/leads'>Leads</Link></Breadcrumb.Item>
                {breadcrumbBlock}
            </Breadcrumb>
            <PageHeader
                className='header'
                extra={extra}
                ghost={false}
                subTitle={deal.code}
                title={deal.formattedName || '-'}
            >
                <Descriptions size='small' column={4}>
                    <Descriptions.Item label='Amount'>{currencyFormatter.format(deal.loanAmount)}</Descriptions.Item>
                    <Descriptions.Item label='Phone'>{deal.phone ? <a href={`tel:${deal.phone}`}>{deal.phone}</a> : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Email'>{deal.email ? <a href={`mailto:${deal.email}`}>{deal.email}</a> : '-'}</Descriptions.Item>
                    <Descriptions.Item label='Broker'>{brokerBlock}</Descriptions.Item>
                    {referralPartnerBlock && <Descriptions.Item label='Referral Partner'>{referralPartnerBlock}</Descriptions.Item>}
                    <Descriptions.Item label='BDM'>{bdm ? bdm.name : 'None'}</Descriptions.Item>
                    <Descriptions.Item label='Assignee'>
                        <AssigneeSelector deal={deal} />
                    </Descriptions.Item>
                    {closeReasonBlock}
                    {rejectReasonBlock}
                    {referredToBlock}
                    <Descriptions.Item label='Follow Up'><FollowUpTime dealUuid={deal.uuid} /></Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <LayoutAnt className='content-wrapper'>
                <LayoutAnt.Sider className='menu' theme='light' width={240}>
                    <Menu
                        defaultSelectedKeys={[section]}
                        items={menuItems}
                        mode='inline'
                    />
                </LayoutAnt.Sider>
                <LayoutAnt.Content className={`content ${section}`}>
                    {children}
                </LayoutAnt.Content>
            </LayoutAnt>
            <CloseModal
                isOpen={isCloseModalOpen}
                deal={deal}
                onCancel={onCloseCancel}
            />
            <ReferModal
                isOpen={isReferModalOpen}
                deal={deal}
                onCancel={onReferCancel}
            />
            <RejectModal
                isOpen={isRejectModalOpen}
                deal={deal}
                onCancel={onRejectCancel}
            />
        </LayoutAnt>
    );
}
