import { Breadcrumb, Button, Card, Dropdown, Layout, Menu, Space, Spin, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { MenuInfo } from 'rc-menu/lib/interface';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import WorkflowStatusEnum from '~Api/Loan/WorkflowStatusEnum';
import { IGlobalState } from '~reducer';
import { IDictionary } from '~utilities/IDictionary';
import { loansDashboardListAction } from './actions';
import LoanCard from './LegacyCard';
import './loans.less';
import Search from './Search';
import { loansDashboardSelector } from './selectors';
import { currencyFormatter } from '~utilities/formatters';
import IAdministrator from '~Api/Administrator/IAdministrator';
import RoleEnum from '~Api/Administrator/RoleEnum';
import { DownOutlined } from '@ant-design/icons';
import { administratorsSelector, currentAdministratorSelector } from '~Administrators/selectors';
import { administratorsListAction } from '~Administrators/actions';

export enum BoardColumnEnum {
    Active = 'ACTIVE',
    Discharge = 'DISCHARGE',
    Drawdown = 'DRAWDOWN',
    Due = 'DUE',
    Extension = 'EXTENSION',
    GracePeriod = 'GRACE_PERIOD',
    Maturing = 'MATURING',
    Overdue = 'OVERDUE',
}

const workflowStatusNames: IDictionary<string> = {
    [BoardColumnEnum.Drawdown]: 'Drawdown',
    [BoardColumnEnum.Active]: 'Active',
    [BoardColumnEnum.Due]: 'Due',
    [BoardColumnEnum.Maturing]: 'Maturing',
    [BoardColumnEnum.GracePeriod]: 'Grace Period',
    [BoardColumnEnum.Extension]: 'Extension',
    [BoardColumnEnum.Discharge]: 'Discharge',
};

interface IPropsSelector {
    administrators: IDictionary<IAdministrator>;
    currentAdministrator: IAdministrator;
    loans: IDictionary<ILoan>;
}

interface IPropsDispatch {
    administratorsList: () => void;
    loansList: () => void;
}

interface IState {
    activeLoanAssigneeFilter: string;
}

type Props = IPropsSelector & IPropsDispatch;

class LegacyDashboard extends React.Component<Props, IState> {
    private refreshInterval: NodeJS.Timeout;

    public state: IState = {
        activeLoanAssigneeFilter: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickLoanAssignee = this.onClickLoanAssignee.bind(this);
    }

    public componentDidMount() {
        const { administrators, currentAdministrator } = this.props;

        if (!administrators || !currentAdministrator) {
            this.props.administratorsList();
        }

        this.props.loansList();

        this.refreshInterval = setInterval(() => {
            this.props.loansList();
        }, 5 * 60 * 1000);
    }

    public componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    public render(): JSX.Element {
        const { administrators, currentAdministrator, loans } = this.props;
        const { activeLoanAssigneeFilter } = this.state;

        let loanAssigneeButton: JSX.Element = null;
        if (RoleEnum.LoanManager !== currentAdministrator.role) {
            loanAssigneeButton = <Button>All Loan Managers <DownOutlined/></Button>;
        }

        if (!loans || !administrators) {
            return (
                <Layout className='loans'>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Loans</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Space className='actions'>
                                <Search/>
                                {loanAssigneeButton}
                                <Link to='/loans/list'><Button>List View</Button></Link>
                                <Link to='/loans'><Button>New View</Button></Link>
                            </Space>
                            <Typography.Title level={2}>Loans</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const columns: IDictionary<ILoan[]> = {};
        const statusAmounts: IDictionary<number> = {};

        const maturingDate: Dayjs = dayjs().add(90, 'days');
        const dueDate: Dayjs = dayjs().subtract(5, 'days');
        const todayDayjs: Dayjs = dayjs();
        const visibleAssignees: IDictionary<IAdministrator> = {};

        _.sortBy(loans, ['endDate']).forEach((loan: ILoan) => {
            if ([LoanStatusEnum.Cancelled, LoanStatusEnum.ClosedObligationsMet, LoanStatusEnum.ClosedWrittenOff, LoanStatusEnum.PartialApplication].includes(loan.status)) {
                return;
            }

            if (loan.administratorUuid && administrators[loan.administratorUuid] && !visibleAssignees[loan.administratorUuid]) {
                visibleAssignees[loan.administratorUuid] = administrators[loan.administratorUuid];
            }

            if (RoleEnum.LoanManager === currentAdministrator.role && loan.administratorUuid !== currentAdministrator.uuid) {
                return;
            }

            if (activeLoanAssigneeFilter) {
                if (activeLoanAssigneeFilter === 'unassigned' && loan.administratorUuid && administrators[loan.administratorUuid] && [RoleEnum.LoanManager, RoleEnum.SeniorLoanManager].includes(administrators[loan.administratorUuid].role)) {
                    return;
                }

                if (!['unassigned', loan.administratorUuid].includes(activeLoanAssigneeFilter)) {
                    return;
                }
            }

            const endDateDayjs: Dayjs = dayjs(loan.endDate);

            let boardColumn: BoardColumnEnum = BoardColumnEnum.Active;

            switch (loan.workflowStatus) {
                case WorkflowStatusEnum.Discharge:
                    boardColumn = BoardColumnEnum.Discharge;
                    break;
                case WorkflowStatusEnum.Drawdown:
                    boardColumn = BoardColumnEnum.Drawdown;
                    break;
                case WorkflowStatusEnum.Extension:
                    boardColumn = BoardColumnEnum.Extension;
                    break;
                case WorkflowStatusEnum.GracePeriod:
                    boardColumn = BoardColumnEnum.GracePeriod;
                    break;
                default:
                    if ((loan.status === LoanStatusEnum.ActiveBadStanding) || (dayjs(loan.paymentDateNext).isSameOrAfter(dueDate) && dayjs(loan.paymentDateNext).isSameOrBefore(todayDayjs))) {
                        boardColumn = BoardColumnEnum.Due;
                    } else if (endDateDayjs.isBefore(maturingDate)) {
                        boardColumn = BoardColumnEnum.Maturing;
                    } else if (loan.extensionApplicationUuid) {
                        boardColumn = BoardColumnEnum.Extension;
                    }
                    break;
            }

            if (!columns[boardColumn]) {
                columns[boardColumn] = [];
            }

            columns[boardColumn].push(loan);

            if (!statusAmounts[boardColumn]) {
                statusAmounts[boardColumn] = 0;
            }

            statusAmounts[boardColumn] += loan.amount;
        });

        const columnsBlock: JSX.Element[] = _.keys(workflowStatusNames).map((workflowStatus: string) => {
            const boardColumn: BoardColumnEnum = workflowStatus as BoardColumnEnum;

            const amountBlock: JSX.Element = (
                <div className='amount'>
                    {columns[boardColumn] ? columns[boardColumn].length : 0} - {currencyFormatter.format(statusAmounts[boardColumn] || 0)}
                </div>
            );

            const cards: JSX.Element[] = _.orderBy(columns[boardColumn], [boardColumn === BoardColumnEnum.Discharge ? 'dischargeDate' : 'endDate'], ['asc']).map((loan: ILoan) => <LoanCard boardColumn={boardColumn} key={loan.uuid} loan={loan} />);

            return (
                <Card key={boardColumn} title={<React.Fragment>{workflowStatusNames[boardColumn]}{amountBlock}</React.Fragment>}>
                    <Space direction='vertical'>
                        {cards}
                    </Space>
                </Card>
            );
        });

        if (RoleEnum.LoanManager !== currentAdministrator.role) {
            let loanAssigneeName: string = 'All Loan Managers';

            if (activeLoanAssigneeFilter === 'unassigned') {
                loanAssigneeName = 'Unassigned';
            }

            const loanAssigneeItems: JSX.Element[] = _.sortBy(_.values(visibleAssignees), ['name']).map((administrator: IAdministrator) => {
                if (activeLoanAssigneeFilter === administrator.uuid) {
                    loanAssigneeName = administrator.name;
                }

                return (
                    <Menu.Item key={administrator.uuid}>{administrator.name}</Menu.Item>
                );
            });

            const loanAssigneeMenu: JSX.Element = (
                <Menu selectedKeys={[activeLoanAssigneeFilter || 'all']} onClick={this.onClickLoanAssignee}>
                    <Menu.Item key='all'>All Loan Managers</Menu.Item>
                    <Menu.Item key='unassigned'>Unassigned</Menu.Item>
                    {loanAssigneeItems.length > 0 && <Menu.Divider/>}
                    {loanAssigneeItems}
                </Menu>
            );

            loanAssigneeButton = (
                <Dropdown overlay={loanAssigneeMenu}>
                    <Button>{loanAssigneeName} <DownOutlined/></Button>
                </Dropdown>
            );
        }

        return (
            <Layout className='loans'>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Loans</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Space className='actions'>
                            <Search/>
                            {loanAssigneeButton}
                            <Link to='/loans/list'><Button>List View</Button></Link>
                            <Link to='/loans'><Button>New View</Button></Link>
                        </Space>
                        <Typography.Title level={2}>Loans</Typography.Title>
                        <Space align='start' className='columns'>
                            {columnsBlock}
                        </Space>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onClickLoanAssignee(e: MenuInfo) {
        this.setState({
            activeLoanAssigneeFilter: e.key !== 'all' ? e.key : null,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        administrators: administratorsSelector(state),
        currentAdministrator: currentAdministratorSelector(state),
        loans: loansDashboardSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        administratorsList: () => dispatch(administratorsListAction()),
        loansList: () => dispatch(loansDashboardListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LegacyDashboard);
