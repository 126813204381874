import { Breadcrumb, Layout, Spin, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import IDeal from '~Api/Deal/IDeal';
import IProperty from '~Api/Deal/IProperty';
import ILoan from '~Api/Loan/ILoan';
import LoanStatusEnum from '~Api/Loan/LoanStatusEnum';
import DealPropertyEditModal from '~Deals/PropertyEditModal';
import { propertyRequiresInsurance } from '~Deals/utilities';
import { IGlobalState } from '~reducer';
import { loansDashboardListAction } from './actions';
import { loansDashboardSelector } from './selectors';
import { ColumnType } from 'antd/lib/table';
import { IDictionary } from '~utilities/IDictionary';
import { Helmet } from 'react-helmet';

interface IState {
    editDealUuid: string;
    editDealPropertyUuid: string;
}

interface ILoanPropertyPair {
    dealProperty?: IProperty;
    deal?: IDeal;
    loan: ILoan;
    uuid: string;
}

interface IPropsSelector {
    loans: IDictionary<ILoan>;
}

interface IPropsDispatch {
    loansList: () => void;
}

type Props = IPropsSelector & IPropsDispatch;

class ExpiringInsurance extends React.Component<Props, IState> {
    public state: IState = {
        editDealPropertyUuid: null,
        editDealUuid: null,
    };

    constructor(props: Props) {
        super(props);

        this.onClickEditDealProperty = this.onClickEditDealProperty.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);
    }

    public componentDidMount() {
        this.props.loansList();
    }

    public render(): JSX.Element {
        const { loans } = this.props;
        const { editDealUuid, editDealPropertyUuid } = this.state;

        if (!loans) {
            return (
                <Layout className='loans'>
                    <Helmet>
                        <title>Expiring Insurance</title>
                    </Helmet>
                    <Breadcrumb className='breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Expiring Insurance</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='content-wrapper'>
                        <Layout.Content className='content'>
                            <Typography.Title level={2}>Expiring Insurance</Typography.Title>
                            <Spin/>
                        </Layout.Content>
                    </Layout>
                </Layout>
            );
        }

        const ageOutDate: dayjs.Dayjs = dayjs().add(30, 'days');

        const properties: ILoanPropertyPair[] = [];

        _.each(loans, (loan: ILoan) => {
            if (![LoanStatusEnum.ActiveBadStanding, LoanStatusEnum.ActiveGoodStanding, LoanStatusEnum.ActiveMatured].includes(loan.status)) {
                return;
            }

            const loanEndDate: dayjs.Dayjs = dayjs(loan.endDate);

            _.each(loan.application.deal.properties, (dealProperty: IProperty) => {
                if (!propertyRequiresInsurance(dealProperty)) {
                    return;
                }

                if (!!dealProperty.insuranceExpiryDate && (dayjs(dealProperty.insuranceExpiryDate).isAfter(loanEndDate) || dayjs(dealProperty.insuranceExpiryDate).isAfter(ageOutDate))) {
                    return;
                }

                properties.push({
                    deal: loan.application.deal,
                    dealProperty,
                    loan,
                    uuid: dealProperty.uuid,
                });
            });
        });

        const columns: ColumnType<ILoanPropertyPair>[] = [
            {
                dataIndex: 'loan',
                render: (loan: ILoan) => <Link to={`/loans/${loan.uuid}`}>{loan.code}</Link>,
                title: 'Loan',
                width: '15%',
            },
            {
                render: (loanPropertyPair: ILoanPropertyPair) => {
                    const { deal, dealProperty } = loanPropertyPair;
                    const onClickEdit: (event: React.MouseEvent) => void = (event: React.MouseEvent) => {
                        event.stopPropagation();
                        this.onClickEditDealProperty(deal.uuid, dealProperty.uuid);
                    };

                    return (
                        <a onClick={onClickEdit}>{dealProperty.streetAddress || ''} {dealProperty.suburb || ''} {dealProperty.state || ''} {dealProperty.postcode || ''}</a>
                    );
                },
                title: 'Address',
            },
            {
                render: (loanPropertyPair: ILoanPropertyPair) => {
                    if (!loanPropertyPair.dealProperty.insuranceExpiryDate) {
                        return 'Unknown';
                    }

                    return dayjs(loanPropertyPair.dealProperty.insuranceExpiryDate).format('Do MMMM YYYY');
                },
                title: 'Insurance Expiry',
                width: '15%',
            },
        ];

        return (
            <Layout className='loans'>
                <Helmet>
                    <title>Expiring Insurance</title>
                </Helmet>
                <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to='/loans'>Loans</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>Expiring Insurance</Breadcrumb.Item>
                </Breadcrumb>
                <Layout className='content-wrapper'>
                    <Layout.Content className='content'>
                        <Typography.Title level={2}>Expiring Insurance</Typography.Title>
                        <Table
                            columns={columns}
                            dataSource={_.sortBy(properties, (loanPropertyPair: ILoanPropertyPair) => loanPropertyPair.dealProperty.insuranceExpiryDate)}
                            rowKey='uuid'
                            pagination={false}
                            size='middle'
                        />
                        <DealPropertyEditModal
                            propertyUuid={editDealPropertyUuid}
                            dealUuid={editDealUuid}
                            isOpen={!!editDealPropertyUuid}
                            onCancel={this.onCloseEditModal}
                        />
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }

    private onClickEditDealProperty(dealUuid: string, propertyUuid: string) {
        this.setState({
            editDealPropertyUuid: propertyUuid,
            editDealUuid: dealUuid,
        });
    }

    private onCloseEditModal() {
        this.setState({
            editDealPropertyUuid: null,
            editDealUuid: null,
        });
    }
}

function mapStateToProps(state: IGlobalState): IPropsSelector {
    return {
        loans: loansDashboardSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsDispatch {
    return {
        loansList: () => dispatch(loansDashboardListAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ExpiringInsurance);
